<template>
    <router-view></router-view>
</template>



<script>
export default {
    name: 'SerialNumber',
    data() {
        return {
        };
    },
    watch: {
    },
    methods: {
    },
};
</script>

