<template>
    <aside>
        <h1>法規文件</h1>
        <nav>
            <ul>
                <li><router-link to="/legal/privacy" active-class="active">隱私權政策</router-link></li>
                <li><a href="https://www.htc.com/tw/terms/cookies/" target="_blank">Cookie政策</a></li>
                <li><router-link to="/legal/user-policy" active-class="active">使用條款</router-link></li>
                <li><a href="https://www.htc.com/tw/terms/product-security/" target="_blank">產品安全</a></li>
            </ul>
        </nav>
    </aside>
</template>



<script>
export default {
    name: 'NavAside',
    data() {
        return {
        };
    },
    methods: {

    }
};
</script>

<style lang="scss"></style>
