<template>
  <section class="">
    <router-view></router-view>
  </section>
</template>
  
<script>


export default {
  name: 'EventIllusionary',
  components: {
  },
  props: {

  },
  data() {
    return {

    };
  },
  methods: {

  }
}
</script>
  

<style lang="scss">
@import "../assets/styles/pages/event.scss";
</style>
  