
<template>
    <div ref="myModal" @click="checkClose($event)" id="myModal" class="modal">
        <div class="mask"></div>
        <div class="modal-content" @click.stop>
            <h1>注意事項</h1>
            <div class="flexcon">
                <div class="left_flex">
                    <ul>
                        <li>於開演前一天安裝 BEATDAY，登入後至「 節目選單 」下載資源包 ( 依網速約 10 ~ 50 分鐘 )</li>
                        <li>安裝與下載資源包流程請參考: <a href="https://beatday.com/faq/" target="_blank"
                                class="link">https://beatday.com/faq/</a></li>
                        <li>開演前30分鐘開放入場，請登入 BEATDAY 後，點選畫面上方演唱會的「進入現場」</li>
                        <li>演唱會為直播形式不提供回放，若超過開演時間進場，可能會錯過部份演出</li>
                        <li>若對於票務上有任何問題，請聯繫 BEATDAY LINE 官方客服 ID: <a href="" class="link">@beatday</a></li>
                    </ul>
                </div>
                <div class="right_flex">
                    <img src="../../assets/img/public/Line_QRcode.svg" alt="">
                    <p>ID：<a href="" class="link">@beatday</a></p>
                </div>
            </div>
            <div class="close">
                <h2 class="close_icon" @click="closeAlert">關閉 &times;</h2>
            </div>
        </div>
    </div>
</template>



<script>
export default {
    name: 'NoticeBox',
    data() {
        return {
        };
    },
    methods: {
        checkClose(event) {
            // 獲取 modal-content 元素的參考
            const modalContent = this.$refs.myModal.querySelector('.modal-content');

            // 檢查點擊的元素是否是 modal-content
            if (!modalContent.contains(event.target)) {
                this.closeAlert();
            }
        },
        closeAlert() {
            document.body.style.overflow = '';

            this.$emit('close-alert');
        }
    }
};
</script>

