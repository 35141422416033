import { createApp } from 'vue';
import App from './App.vue';
import router from '@/router';
import './assets/styles/reset.scss';
import './assets/styles/global.scss';



// 創建 vue 應用實例
const app = createApp(App);
const globalData = {
    // 全局變量
    Version: 'release',
};
app.config.globalProperties.$globalData = globalData;
// 使用路由
app.use(router);
// 掛載應用
app.mount('#app');