<template>
    <form @submit.prevent="verifyCode">
        <div class="verify">
            <i class="bi bi-exclamation-circle"></i>
            <input v-model="verificationCode" type="verify" id="verify" name="verify" placeholder="請輸入驗證碼">
        </div>

        <div class="alert">{{ alertV }}</div>
        <button type="submit" class="login_btn">確認</button>
    </form>

    <div class="link">
        <router-link to="/member-login/login">返回登入</router-link>
    </div>
    <Loading v-show="loading"></Loading>
</template>
  
<script>
import { verifyCode } from '@/api/apiService';
import Loading from '@/components/Loading.vue';

export default {
    name: 'VerifyNumber',
    emits: ['verificationSuccess'],
    props: {
        username: {
            type: String,
            default: ''
        },
        reason: {
            type: String,
            default: ''
        }
    },
    components: {
        Loading,
    },
    data() {
        return {
            alertV: '',
            verificationCode: '',
            loading: false,
            jwt: ''
        };
    },
    methods: {
        async verifyCode() {
            const data = {
                request: 'verifyCode',
                account: this.username,
                code: this.verificationCode,
                reason: this.reason
            }
            try {
                this.loading = true;
                const response = await verifyCode(data);
                if (response.status === 200) {
                    if (this.reason === 'changePasswordVerify') {
                        this.jwt = await response.data;
                        this.alertV = "驗證成功";
                        this.loading = false;
                        this.$emit('verification-success', true);
                        this.$emit('resetJwt', this.jwt)
                    }
                    else {
                        this.loading = false;
                        this.alertV = "註冊成功";
                        this.$emit('verification-success', true);
                    }
                }
                else {
                    this.loading = false;
                    this.alertV = "驗證失敗"
                    this.$emit('verification-success', false);
                }
            }
            catch (error) {
                this.loading = false;
                this.alertV = "驗證失敗"
                this.$emit('verification-success', false);
            }
        },

    }
}
</script>
  

<style lang="scss"></style>
  