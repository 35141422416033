<template>
  <app-header></app-header>
  <section class="wrapper index_page">
    <div class="banner">
      <div class="carousel-container">
        <div v-for="(item, index) in images" :key="index">
          <a v-if="item.link" :href="item.link">
            <picture>
              <source media="(max-width: 990px)" :srcset="item.srcsetMobile" />
              <img :src="item.src" :alt="item.alt" />
            </picture>
          </a>
          <router-link v-else :to="item.routerLink">
            <picture>
              <source media="(max-width: 990px)" :srcset="item.srcsetMobile" />
              <img :src="item.src" :alt="item.alt" />
            </picture>
          </router-link>
        </div>
      </div>

      <div class="scroll_down">
        <div class="scroll">Scroll</div>
        <div class="divider">
          <div class="line"></div>
          <div class="scroll_line"></div>
        </div>
      </div>
    </div>


  </section>
  <section class="info01_box">
    <div class="title">
      <h1>解放你的音樂</h1>
      <h1 class="EN">MUSIC UNCHAINED</h1>
    </div>
    <p>BEATDAY，全球第一個全息元宇宙娛樂平台。<br>
      BEYOND EVERYDAY，突破音樂體驗，解放你的音樂現場。</p>
    <div class="info_box">
      <div class="item">
        <img src="../assets/img/Index/MusicUnchained/Icon_Control_Perspective.png" alt="">
        <h3>真實全息影像</h3>
      </div>
      <div class="item">
        <img src="../assets/img/Index/MusicUnchained/Icon_Traverse_Dreamscapes.png" alt="">
        <h3>穿越虛實幻境</h3>
      </div>
      <div class="item">
        <img src="../assets/img/Index/MusicUnchained/Icon_Realistic_Holograms.png" alt="">
        <h3>自由視角觀看</h3>
      </div>
      <div class="item">
        <img src="../assets/img/Index/MusicUnchained/Icon_Highly_Interactive.png" alt="">
        <h3>高互動體驗</h3>
      </div>
    </div>
  </section>
  <section class="info02_box">
    <dotlottie-player id="wave" src="https://lottie.host/d4b8b24c-7a01-407b-b92b-a3b4960cef97/h1uds58UUy.json"
      background="transparent" speed="1" loop autoplay></dotlottie-player>
    <div class="text">
      <h1>突破音樂體驗</h1><br>
      <h1>全息演唱會</h1>
      <p>
        BEATDAY 打造獨一無二的音樂體驗！<br>
        在 BEATDAY 上，化身虛擬替身，穿梭於絢麗的場景中，<br>
        參與線上演唱會，與世界各地的音樂愛好者一同共襄盛舉。<br>
        無論是探索新世界還是與朋友互動，<br>
        BEATDAY 都能帶來音樂的無限樂趣！<br>
      </p>
      <router-link to="/download" class="download">
        立即下載
        <img src="../assets/img/Index/arrow-up.svg" alt="">
      </router-link>
    </div>
    <div class="img_slide">
      <div class="slide_mask"></div>
      <div class="slide01"></div>
      <div class="slide02"></div>
    </div>
  </section>
  <app-footer ref="footerRef"></app-footer>
</template>
<script>
import { getSomething } from '@/api/apiService';
import AppHeader from "@/components/header.vue";
import AppFooter from "@/components/footer.vue";

export default {
  name: 'IndexPage',
  components: {
    "app-header": AppHeader,
    "app-footer": AppFooter,
  },
  mounted() {
    this.slick();
    setTimeout(() => {
      $("#wave")[0].play();
    }, 1000);
  },
  data() {
    return {
      test: 1,
      images: [
        {
          routerLink: "/event/VDay",
          srcsetMobile:
            "https://beatdayweb.s3.amazonaws.com/image/index_banner/img_moblie_03.jpg",
          src: "https://beatdayweb.s3.amazonaws.com/image/index_banner/img03.jpg",
          alt: "VDay誕生日",
        },
        {
          link: "https://beatday.com/MizukiLin/",
          srcsetMobile:
            "https://beatdayweb.s3.amazonaws.com/image/index_banner/img_moblie_01.png",
          src: "https://beatdayweb.s3.amazonaws.com/image/index_banner/img01.png",
          alt: "林襄沈浸式寫真",
        },
      ],

    };
  },
  props: {},
  computed: {},
  methods: {
    async fetchData() {
      try {
        const response = await getSomething();
        this.test = response;
      } catch (error) {
        // Handle error
      }
    },
    // aniWave() {
    //     $("#wave")[0].play();
    // },
    slick() {
      $('.carousel-container').slick({
        autoplay: true,
        autoplaySpeed: 3000, // Set the speed of the carousel
        dots: true, // Show navigation dots
        arrows: true, // Show navigation arrows
        prevArrow: '<button type="button" class="slick-prev"><i class="bi bi-caret-left-fill"></i></button>',
        nextArrow: '<button type="button" class="slick-next"><i class="bi bi-caret-right-fill"></i></button>',
      });
    },
  },
  watch: {},
}
</script>

<style lang="scss">
@import "../assets/styles/pages/index.scss";
</style>