<template>
    <section class="wrapper member-login">
    </section>
</template>
    
<script>


export default {
    name: 'oldIllusionary',
    props: {

    },
    mounted() {
        this.$router.push('/downloads-tutorials/download');
    },
    data() {
        return {

        };
    },
    methods: {

    }
}
</script>
    