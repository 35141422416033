<template>
    <section class="download_page">
        <h1>BEATDAY 電腦版下載</h1>
        <h1>支援 Windows & Mac 系統</h1>
        <div class="into">
            <div class="PCorMac">
                <div class="item">
                    <h3>Windows</h3>
                    <img src="../assets/img/faq/PC@150.webp" alt="">
                    <a href="https://d2uz35uzla1hiz.cloudfront.net/Version/BEATDAY_0.1.53_1129.exe"
                        class="button_style_linear" :class="{ 'disabled': !agreed }">{{ button_text }}</a>
                </div>
                <div class="item">
                    <h3>MAC</h3>
                    <img src="../assets/img/faq/MAC@150.webp" alt="">
                    <a href="https://d2uz35uzla1hiz.cloudfront.net/Version/BeatdayApp_v0.1.4_Release.dmg"
                        class="button_style_linear" :class="{ 'disabled': !agreed }">{{ button_text }}</a>
                </div>
            </div>
            <div class="agreement_box">
                <p>
                    <input type="checkbox" id="agreement" required v-model="agreed" @click="checkbox">
                    <label for="agreement">已詳閱並同意
                        <a href="https://beatday.com/#/legal/user-policy" target="_blank">使用者協議</a>
                        與
                        <a href="https://beatday.com/#/legal/privacy" target="_blank">隱私權條款</a>
                        ，以及以下免責聲明：
                    </label>
                </p>
                <ol>
                    <li>1. 觀看全息演唱會需符合下方電腦建議規格與網速，若因為個人設備問題導致無法觀看，可聯繫客服處理退票事宜，不可利用該問題，公開或私下散播不實評論。</li>
                    <li>2. 未經本公司授權，請勿私自側錄散播演唱會內容，嚴重者將追訴法律責任。</li>
                </ol>
            </div>
        </div>
        <h1>建議規格</h1>
        <div class="into ">
            <div class="norm">
                <div class="item">
                    <h3>Windows</h3>
                    <ul>
                        <li>CPU：Intel Core i7 以上</li>
                        <li>RAM：8GB 以上</li>
                        <li>顯卡：
                            <ul>
                                <li>畫質優先→NVIDIA GeForce GTX1060以上</li>
                                <li>效能優先→NVIDIA GeForce GT1030 以上</li>
                            </ul>
                        </li>
                        <li>硬碟空間: 20GB 以上</li>
                        <li>建議網速: 50 Mbps</li>
                    </ul>
                </div>
                <div class="item">
                    <h3>MAC</h3>
                    <ul>
                        <li>任何機種（2018年後）</li>
                        <li>軟體: OS 10.14 以上</li>
                        <li>硬碟空間: 20G 以上</li>
                        <li>建議網速: 50 Mbps</li>
                    </ul>
                </div>
            </div>
            <ul class="ul">
                <li>若電腦低於建議規格，或網速低於50Mbps，BEATDAY將無法保證下載安裝及觀看順暢。</li>
                <li>使用BEATDAY時，請關閉其他軟體、遊戲、影音等，以免卡頓。</li>
            </ul>
        </div>
    </section>
</template>
<script>
import { getSomething } from '@/api/apiService';
export default {
    name: 'IndexPage',
    components: {
    },
    mounted() {

    },
    data() {
        return {
            agreed: false,
            button_text: '請先同意下方條款',
        };
    },
    props: {},
    computed: {},
    methods: {
        async fetchData() {
            try {
                const response = await getSomething();
                this.test = response;
            } catch (error) {
                // Handle error
            }
        },
        checkbox() {
            if (!this.agreed) {
                this.button_text = '程式下載';
            } else {
                this.button_text = '請先同意下方條款';
            }
        }

    },
}
</script>
    
<style lang="scss"></style>
    