<template>
  <app-header></app-header>
  <div class="wrapper buy-tickets">
    <div class="loadingMask">
      <div class="spinner-border text-primary" role="status">
        <span class="sr-only"></span>
      </div>
      <br />
      <span>Loading...</span>
    </div>

    <div id="Store_menu"></div>
    <div class="line"></div>
    <form>
      <label for="ticketType" id="lang_1" style="display: none">
        <span class="title">選擇票種類型</span>
      </label>
      <div class="select_box">
        <select id="ticketType" name="ticketType" class="form-control">
          <!-- <option value="" disabled selected hidden>請選擇</option> -->
        </select>
      </div>

      <br />
      <label for="ticketDate" id="lang_2" style="display: none">
        <span class="title">選擇日期</span>
      </label>
      <div class="select_box">
        <select id="ticketDate" name="ticketDate" class="form-control">
          <!-- <option value="" disabled selected hidden>請選擇</option> -->
        </select>
      </div>
      <ul class="list-unstyled" id="ticketId">
        <li class="init" id="lang_2">請選擇場次</li>
      </ul>
      <div id="ticket_item_detail"></div>
      <div class="line"></div>
      <br />
      <label id="lang_3" for="email">輸入取票 Email:</label>
      <input type="email" id="email" name="email" value="" placeholder="請輸入信箱" />
      <br />
      <span class="warning" id="lang_5" style="margin-top: 20px"></span>
      <br />
      <div id="submit_conditions_des">
        <span id="lang_7"></span>
        <br />
        <span id="lang_8"></span>
      </div>
      <div id="checkboxWrapper" style="display: none">
        <input type="checkbox" id="agreeCheckbox" />
        <label for="agreeCheckbox">
          同意參加「7/25(四)
          <router-link :to="{ path: '/event/VDay', hash: '#Lutralutra_V2V' }" target="_blank">
            露恰露恰V2V見面會
          </router-link>
          資格」抽獎活動。相關活動辦法請見
          <router-link to="/event/VDay" target="_blank">活動官網</router-link>
          與
          <a href="https://www.facebook.com/beatday.music" target="_blank">
            BEATDAY官方社群
          </a>
          公告。
        </label>
      </div>
      <br />
      <button id="submit" disabled type="submit">前往付款</button>
    </form>
    <label for="info1" id="lang_6">購買內容</label>
    <br />
    <span id="ticket_warning2" class="warning info">
      購買票券即綁定該BEATDAY帳號，無法轉移或贈與至其他帳號，請注意不同第三方帳號登入(EX:Facebook,
      Google…)視為不同帳號，購買前請務必確認。
    </span>
    <br />
    <br />
    <span id="ticket_warning1" class="warning">
      若電腦規格低於規格，則會出現卡頓、延遲，甚至無法觀看表演的情況
    </span>
    <br />
    <br />
  </div>
  <app-footer></app-footer>
</template>

<script>
import AppHeader from "@/components/header.vue";
import AppFooter from "@/components/footer.vue";
export default {
  name: "BuyTickets",
  components: {
    "app-header": AppHeader,
    "app-footer": AppFooter,
  },
  mounted() {
    let qtyData = {};
    let currentTId = "S_COMIC";
    var G_lang = {};
    var G_events = [];
    var G_eventId_des = {};
    var G_Selected_Stores = {};
    var G_TargetTicketID = null;
    var G_EmailPass = false;
    var G_LANGUAGE = "ZHTW"; //- 設定使用哪個 語系表
    const G_LANG_LIST = ["ZHTW", "EN"];
    const G_LANG_LIST_CHANGE = [
      {
        ele: "#lang_1", //- Jquery selector for element
        lang: {
          //- Multi lang compare word for element
          ZHTW: "選擇商品類型",
          EN: "Choose",
        },
      },
      {
        ele: "#lang_2",
        lang: {
          ZHTW: "請選擇場次",
          EN: "Select concert session",
        },
      },
      {
        ele: "#lang_3",
        lang: {
          ZHTW: "輸入取票 Email:",
          EN: "Enter Email:",
        },
      },
      {
        ele: "#email",
        attr: "placeholder",
        lang: {
          ZHTW: "請輸入信箱",
          EN: "Email",
        },
      },
      {
        ele: "#lang_5",
        lang: {
          ZHTW: "＊ 購買成功後將寄送〈 取票序號 〉及〈 電子發票 〉至 Email",
          EN:
            "* After successful purchase, the ticket voucher redemption code and electronic invoicing will be sent to this email.",
        },
      },
      {
        ele: "#lang_6",
        lang: {
          ZHTW: "購買內容",
          EN: "Purchase details",
        },
      },
      {
        ele: "#lang_7",
        lang: {
          ZHTW: "請選擇商品",
          EN: "Concert session can't be empty.",
        },
      },
      {
        ele: "#lang_8",
        lang: {
          ZHTW: "Email 信箱格式錯誤",
          EN: "Email format invalid.",
        },
      },
      {
        ele: "#submit",
        lang: {
          ZHTW: "前往付款",
          EN: "Pay Now",
        },
      },
    ];
    var __btoa =
      "a2V5PVpOd096bTB4NHgzOVJHZ0NwUFhrVjJSVlRkY2hBWmFVMTRFZ1NXanEmdXJsPWh0dHBzOi8vY3V4bzVxaGtxMi5leGVjdXRlLWFwaS5hcC1ub3J0aGVhc3QtMS5hbWF6b25hd3MuY29tL3Byb2Q=";
    const G_LANG_LIST_SCRIPT = {
      //- Ticket item descript 多語
      i_name: {
        ZHTW: "票名",
        EN: "Ticket name",
      },
      i_price: {
        ZHTW: "價格",
        EN: "Price",
      },
      i_des: {
        ZHTW: "此票卷包含以下內容",
        EN: "Ticket includes",
      },
    };

    var G__KEY = new URLSearchParams(atob(__btoa)).get("key");
    var G__URL = new URLSearchParams(atob(__btoa)).get("url");

    function checkDateAndHideCheckbox() {
      const currentDate = new Date();
      const targetDate = new Date('2024-07-09T00:00:00');

      if (currentDate >= targetDate) {
        $("#checkboxWrapper").hide();
      }
    }
    function SwapMultiLangUI() {
      const paramLang = new URLSearchParams(window.location.search).get("lang");
      if (paramLang && G_LANG_LIST.includes(paramLang)) {
        G_LANGUAGE = paramLang;
      }
      G_LANG_LIST_CHANGE.forEach((_obj) => {
        let Q_Ele = $(_obj.ele);
        if (Q_Ele.length == 0) return; //- Can not find html element
        if (!_obj?.lang?.[G_LANGUAGE]) return; //- No Language data compared
        if (Object.prototype.hasOwnProperty.call(_obj, "attr"))
          Q_Ele.attr(_obj.attr, _obj.lang[G_LANGUAGE]);
        else {
          Q_Ele.text(_obj.lang[G_LANGUAGE]);
        }
      });
    }

    function CreateTicketOrder(email, ticketId, agree) {
      //- Ajax 建立購買Order:訂單
      const targetData = {
        UserId: email,
        Email: email,
        _itemsId: [ticketId],
        _itemsQty: [1],
        Result: "",
        Timestamp2: "",
        Status: "I",
        Lottery: agree,
      };
      return new Promise((resolve, reject) => {
        $.ajax({
          url: G__URL + "/create_order", //- Dev
          type: "PUT",
          data: JSON.stringify(targetData),
          headers: {
            "Content-Type": "application/json",
            "x-api-key": G__KEY,
          },
          success: function (data) {
            return resolve(data);
          },
          error: function (xhr, textStatus, errorThrown) {
            console.log("Error:", errorThrown);
            return reject(errorThrown);
          },
        });
      });
    }
    function handleTicketSelection() {
      const ticketType = $("#ticketType").val();
      const ticketDate = $("#ticketDate").val();
      if (ticketType && ticketDate) {
        // 根据ticketType和ticketDate找到对应的ticketId
        const selectedTicketId = G_Selected_Stores._optValues.find(
          (value) =>
            value.includes(ticketType) &&
            value.includes(ticketDate.slice(1, 3)) &&
            value.includes(ticketDate.slice(-1))
        );
        if (selectedTicketId) {
          G_TargetTicketID = selectedTicketId;

          // 获取对应的名字
          const optionName = G_lang?.Event?.["S_COMICP#OptName"] || "";
          const optionList = optionName.split("\n");
          let day = ticketDate.slice(1, 3);
          let timeOfDay =
            ticketDate.slice(-1) === "A" ? "上午場11:00 – 14:00" : "下午場14:00 – 17:00";
          let formattedDate = `7/${day}`;

          const currentGName = optionList.find(
            (option) => option.includes(formattedDate) && option.includes(timeOfDay)
          );
          let displayType = "";
          switch (ticketType) {
            case "E":
              displayType = "厄倫蒂兒";
              break;
            case "N":
              displayType = "涅默";
              break;
            case "R":
              displayType = "露恰露恰";
              break;
          }

          let finalGName = displayType + "-" + currentGName;
          update_submitBtn_status();
          RenderTicket_goods_detail(G_eventId_des[selectedTicketId], finalGName);
          $("#ticket_item_detail").addClass("show"); // 确保显示 detail 部分
        }
      }
    }

    function LoadStoresProduct() {
      //- 載入售票資訊
      return new Promise((resolve, reject) => {
        $.ajax({
          url: G__URL + "/getconcertevents", //- Dev
          type: "GET",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": G__KEY,
          },
          success: function (data) {
            return resolve(data);
          },
          error: function (xhr, textStatus, errorThrown) {
            console.log("Error:", errorThrown);
            return reject(errorThrown);
          },
        });
      });
    }
    function LoadMLangData() {
      //- 載入售票資訊-多語
      return new Promise((resolve, reject) => {
        $.ajax({
          url: G__URL + "/multi_lang" + `?Lang=${G_LANGUAGE}`, //- Dev
          type: "GET",
          headers: {
            "Content-Type": "application/json",
            "x-api-key": G__KEY,
          },
          success: function (data) {
            return resolve(data);
          },
          error: function (xhr, textStatus, errorThrown) {
            console.log("Error:", errorThrown);
            return reject(errorThrown);
          },
        });
      });
    }

    function RenderTicketStore() {
      $("#Store_menu").empty(); // 清空#Store_menu中的内容
      G_events.forEach((e, nb) => {
        if (!e?.Id) return;
        const _img = G_lang?.Event?.[`${e.Id}#MainPicUrl`];
        const _Name = G_lang?.Event?.[`${e.Id}#Name`];
        const newOption = $(
          // `<div class="ConcertType ${nb == 0 ? "choose" : ""}" tId=${e.Id} key=${nb}>`
          `<div class="ConcertType" tId=${e.Id} key=${nb}>`
        );

        const imgEle = $(`<img class="des_img" src="">`);
        if (_img) {
          imgEle.attr("src", _img);
        } else {
          imgEle.attr(
            "src",
            "https://beatday.com/wp-content/uploads/2021/10/Beatday-Neon-Logo-02-300x300.png"
          );
        }
        newOption.append(imgEle);

        const txtEle = $('<div class="describe">').html(`${_Name || e?.Id}`);
        newOption.append(txtEle);

        $("#Store_menu").append(newOption);
      });

      // 调用LoadQtyData并传递数量数据
      LoadQtyData()
        .then((data) => {
          qtyData = data;
          // RenderTicketOptions(0, qtyData);
        })
        .catch((error) => {
          console.error("Failed to load quantity data:", error);
        });
    }

    function RenderTicketOptions(_key, qtyData) {
      G_Selected_Stores = G_events[_key];
      const ML_Options =
        G_lang?.Event?.[`${G_Selected_Stores.Id}#OptName`]?.split("\n") || [];
      const ticketIdElement = $("#ticketId");
      ticketIdElement.empty();

      if (G_Selected_Stores.Id.startsWith("S_COMICP")) {
        $("#ticketType").empty();
        $("#ticketDate").empty();

        // 添加默认的“請選擇”选项
        $("#ticketType").prepend('<option value="" disabled selected hidden>請選擇</option>');
        $("#ticketDate").prepend('<option value="" disabled selected hidden>請選擇</option>');

        // 分解_optValues为票种类型和时间
        const ticketTypes = new Set();
        const ticketDates = new Set();
        G_Selected_Stores._optValues.forEach((value) => {
          const type = value.split("_")[1].charAt(5); // 票种类型
          const date = value.match(/\d+/)[0];
          const time = value.slice(-1); // 取时间 (P)
          const combinedString = `P${date}${time}`;
          const showDate = `${date}${time}`;

          // 处理票种类型选项
          if (!$('#ticketType option[value="' + type + '"]').length) {
            if (qtyData[type] > 0) {
              ticketTypes.add(type);
            } else {
              let displayType = "";
              switch (type) {
                case "E":
                  displayType = "厄倫蒂兒";
                  break;
                case "N":
                  displayType = "涅默";
                  break;
                case "R":
                  displayType = "露恰露恰";
                  break;
              }
              const option = new Option(displayType + "（已售空）", type);
              option.disabled = true;
              $("#ticketType").append(option);
            }
          }

          // 处理日期选项
          if (!ticketDates.has(combinedString)) {
            let dayOfWeek = "";
            switch (showDate.slice(0, 2)) {
              case "25":
                dayOfWeek = "四";
                break;
              case "26":
                dayOfWeek = "五";
                break;
              case "27":
                dayOfWeek = "六";
                break;
              case "28":
                dayOfWeek = "日";
                break;
              case "29":
                dayOfWeek = "一";
                break;
            }
            const formattedDate = `7/${showDate.slice(0, 2)} (${dayOfWeek}) ${showDate.slice(-1) === "A" ? "上午場11:00 – 14:00" : "下午場14:00 – 17:00"
              }`;
            if (qtyData[combinedString] > 0) {
              ticketDates.add(combinedString);
              $("#ticketDate").append(new Option(formattedDate, combinedString));
            } else {
              const option = new Option(formattedDate + "（已售空）", combinedString);
              option.disabled = true;
              $("#ticketDate").append(option);
              ticketDates.add(combinedString);
            }
          }
        });

        ticketTypes.forEach((type) => {
          let displayType = "";
          switch (type) {
            case "E":
              displayType = "厄倫蒂兒";
              break;
            case "N":
              displayType = "涅默";
              break;
            case "R":
              displayType = "露恰露恰";
              break;
          }
          $("#ticketType").append(new Option(displayType, type));
        });

        // 动态添加日期选项
        $("#ticketType")
          .off("change")
          .change(function () {
            $("#ticketDate").empty(); // 清空之前的选项
            ticketDates.forEach((combinedString) => {
              let dayOfWeek = "";
              switch (combinedString.slice(1, 3)) {
                case "25":
                  dayOfWeek = "四";
                  break;
                case "26":
                  dayOfWeek = "五";
                  break;
                case "27":
                  dayOfWeek = "六";
                  break;
                case "28":
                  dayOfWeek = "日";
                  break;
                case "29":
                  dayOfWeek = "一";
                  break;
              }
              const formattedDate = `7/${combinedString.slice(1, 3)} (${dayOfWeek}) ${combinedString.slice(-1) === "A"
                ? "上午場11:00 – 14:00"
                : "下午場14:00 – 17:00"
                }`;
              if (qtyData[combinedString] > 0) {
                $("#ticketDate").append(new Option(formattedDate, combinedString));
              } else {
                const option = new Option(formattedDate + "（已售空）", combinedString);
                option.disabled = true;
                $("#ticketDate").append(option);
              }
            });
            handleTicketSelection(); // 确保在票种类型更改时触发选择处理逻辑
          });

        $("#ticketDate").off("change").change(handleTicketSelection);

        $("#ticketDate").off("change").change(handleTicketSelection);
        // $("#checkboxWrapper").show();
        $("#ticketType").show();
        $("#ticketDate").show();
        $("#lang_1").text("選擇獨家周邊商品款式").show();
        $("#lang_2").show(); // 显示 '選擇日期'
        ticketIdElement.hide();
        $("#lang_6").hide();
        $("#ticket_warning2").hide();
      } else {
        $("#ticketType").hide();
        $("#ticketDate").hide();
        $("#lang_2").hide(); // 隐藏 '選擇日期'
        ticketIdElement.show();

        if (G_Selected_Stores.Id.startsWith("S_COMIC")) {
          $(".select_box").hide();
          // $("#checkboxWrapper").show();
          checkDateAndHideCheckbox();
          $("#lang_6").hide();
          $("#ticket_warning2").hide();
          $("#lang_1").text("請選擇場次").show();
        } else {
          $("#lang_1").text("選擇商品類型").show();
          $("#lang_6").show();
          $("#ticket_warning2").show();
          $(".select_box").hide();
          $("#checkboxWrapper").hide();
        }

        if (ML_Options.length > 0) {
          $("#lang_1").show(); // 显示 '選擇商品類型'
          let totalTickets = [];
          if (G_Selected_Stores?._optValues) {
            G_Selected_Stores._optValues.forEach((e) => {
              if (e.includes(",")) {
                totalTickets = totalTickets.concat(e.split(","));
              } else {
                totalTickets.push(e);
              }
            });
          }
          ML_Options.forEach((e, nb) => {
            if (nb >= totalTickets.length) return;
            const _eventId = totalTickets[nb];
            if (
              !G_eventId_des?.[_eventId] ||
              G_eventId_des?.[_eventId]?.IsFriendOnly == true
            )
              return;

            if (currentTId === "S_COMIC") {
              const ticketDate = _eventId.match(/[0-9]+[A-Za-z]+/)[0];
              const newOption = $("<li>")
                .attr({
                  "data-value": _eventId,
                  "G-Name": e,
                  class: qtyData[ticketDate] > 0 ? "" : "sold-out",
                })
                .text(e + (qtyData[ticketDate] > 0 ? "" : "（已售空）"));

              ticketIdElement.append(newOption);
            } else {
              const newOption = $("<li>")
                .attr({
                  "data-value": _eventId,
                  "G-Name": e,
                })
                .text(e);
              ticketIdElement.append(newOption);
            }
          });
        } else {
          $("#lang_2").hide(); // 隐藏 '請選擇場次'
        }
      }

      if (G_lang?.Event?.[`${G_Selected_Stores.Id}#WebNotice`]) {
        //- 更換購買注意事項Title
        $("#ticket_warning1").html(
          G_lang?.Event?.[`${G_Selected_Stores.Id}#WebNotice`].replace(
            /(?:\r\n|\r|\n)/g,
            "<br>"
          )
        );
      } else {
        $("#ticket_warning1").text("購買注意事項");
      }
      if (G_lang?.Event?.[`${G_Selected_Stores.Id}#WebContent`]) {
        //- 更換購買注意事項 Des
        $("#ticket_warning2").html(
          G_lang?.Event?.[`${G_Selected_Stores.Id}#WebContent`].replace(
            /(?:\r\n|\r|\n)/g,
            "<br>"
          )
        );
      } else {
        $("#ticket_warning2").text(
          "購買票券即綁定該BEATDAY帳號，無法轉移或贈與至其他帳號，請注意不同第三方帳號登入(EX:Facebook, Google…)視為不同帳號，購買前請務必確認。"
        );
      }
    }

    function LoadQtyData() {
      return new Promise((resolve, reject) => {
        $.ajax({
          url: G__URL + "/checkqty?check",
          type: "GET",
          success: function (data) {
            resolve(data);
          },
          error: function (xhr, textStatus, errorThrown) {
            reject(errorThrown);
          },
        });
      });
    }

    function RenderTicket_goods_detail(tObj, GName) {
      //- 渲染 ticket - 所包含道具
      $("#ticket_item_detail").empty();
      if (tObj?.Name) {
        if (currentTId === "S_COMICP") {
          $("#ticket_item_detail").append(
            `<p style="padding-top:15px"><span class="des">款式&場次 :</span> ${GName}</p>`
          );
        } else if (currentTId === "S_COMIC") {
          $("#ticket_item_detail").append(
            `<p style="padding-top:15px"><span class="des">場次 :</span> ${GName}</p>`
          );
        } else {
          $("#ticket_item_detail").append(
            `<p style="padding-top:15px"><span class="des">${G_LANG_LIST_SCRIPT.i_name[G_LANGUAGE]} :</span> ${GName}</p>`
          );
        }
      }
      if (tObj?.PriceNt) {
        $("#ticket_item_detail").append(
          `<p><span class="des">${G_LANG_LIST_SCRIPT.i_price[G_LANGUAGE]} :</span> NT ${tObj?.PriceNt}</p>`
        );
      }
      if (G_lang?.Prop?.[`${tObj.Id}#Description`]) {
        $("#ticket_item_detail").append(
          `<p class="des2">${G_lang?.Prop?.[`${tObj.Id}#Description`]}</p>`
        );
      } else {
        $("#ticket_item_detail").append(
          `<p class="des2">${G_LANG_LIST_SCRIPT.i_des[G_LANGUAGE]} :</p>`
        );
      }
      const wrapEle = $('<div class="itemsDetail">');

      const wrapEle_row1 = $('<div class="row1">');
      const wrapEle_row2 = $('<div class="row1">');
      tObj?.ItemsId.forEach((e) => {
        if (!e?.Id) return;
        let imgUrl = `https://d2uz35uzla1hiz.cloudfront.net/media/itempic/${e?.Id}.png`;

        if (e?.Id.startsWith("T")) {
          //- 票卷 > 放第一排
          if (G_lang?.Prop?.[`${e.Id}#ThumbUrl`])
            imgUrl = G_lang?.Prop?.[`${e.Id}#ThumbUrl`];
          if (currentTId === "S_COMICP") {
            let figureType = e.Id[7];
            let figureUrl = `https://d2uz35uzla1hiz.cloudfront.net/media/itempic/${e?.Id}.png`;
            if (G_lang?.Prop?.[`T_FAKE_${figureType}#ThumbUrl`])
              figureUrl = G_lang?.Prop?.[`T_FAKE_${figureType}#ThumbUrl`];
            wrapEle_row1.append(`
                            <div class="des_Item" style="display: flex; align-items: flex-start; gap: 20px;width:auto;padding-left:9px">
                                <div class="img-wrapper" style="display: flex;width:200px; flex-direction: column; align-items: center;">
                                    <img src="${imgUrl}" style="max-width: 100%; height: auto;">
                                    <p style="margin-top: 10px; text-align: center;">${G_lang?.Prop?.[`${e.Id}#Name`] || e?.Name
              }</p>
                                </div>
                                <div class="img-wrapper" style="display: flex;width:200px; flex-direction: column; align-items: center;">
                                    <img src="${figureUrl}" style="max-width: 100%; height: auto;">
                                    <p style="margin-top: 10px; text-align: center;">${G_lang?.Prop?.[`T_FAKE_${figureType}#Name`] ||
              e?.Name
              }</p>
                                </div>
                            </div>
                        `);
          } else {
            wrapEle_row1.append(`
                        <div class="des_Item">
                        <img src="${imgUrl}">
                        <p>${G_lang?.Prop?.[`${e.Id}#Name`] || e?.Name}</p>
                        </div>
                        `);
          }
        } else {
          //- 道具 > 放第二排
          wrapEle_row2.append(`
                        <div class="des_Item">
                        <img src="${imgUrl}">
                        <p>${e?.Name}</p>
                        </div>
                    `);
        }
      });
      wrapEle.append(wrapEle_row1);
      wrapEle.append(wrapEle_row2);
      $("#ticket_item_detail").append(wrapEle);
    }

    function isValidEmail(email) {
      var emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(email);
    }

    function update_submitBtn_status() {
      //- Submit Button狀態通行判斷
      $("#lang_7").css("display", "none");
      $("#lang_8").css("display", "none");
      if (G_TargetTicketID != null && G_EmailPass) {
        //- pass
        $("#submit").prop("disabled", false);
      } else {
        //- one or more conditions not met
        if (G_TargetTicketID == null) $("#lang_7").css("display", "inline-block");
        if (!G_EmailPass) $("#lang_8").css("display", "inline-block");
        $("#submit").prop("disabled", true);
      }
    }

    SwapMultiLangUI();
    Promise.allSettled([LoadMLangData(), LoadStoresProduct()]).then((datas) => {
      let allPass = true;
      datas.forEach((o, nb) => {
        if (o.status == "rejected") {
          allPass = false;
          return;
        }
        if (nb == 0) {
          G_lang = o.value;
        } else if (nb == 1) {
          const d = o.value;
          G_events =
            d?.events?.filter(
              (e) => !Object.prototype.hasOwnProperty.call(e, "Hide") || e.Hide == false
            ) || []; //- hide "Hide" attr item
          G_eventId_des = d?.eventId_des || {};
        }
      });
      if (!allPass) {
        alert(
          "資料載入錯誤! 請重新整理或聯絡客服人員 \n Data load error, please refresh page or contact customer service."
        );
        return;
      }
      $(".loadingMask").addClass("hide");
      RenderTicketStore();
    });

    $("#ticketId").on("click", "li:not(.init)", function () {
      //- 選擇演唱會票卷li click監聽
      const buy_ticket_id = $(this).attr("data-value");
      let GName = $(this).attr("G-Name");
      G_TargetTicketID = buy_ticket_id;
      update_submitBtn_status();
      RenderTicket_goods_detail(G_eventId_des?.[buy_ticket_id], GName);
      $(this).addClass("selected").siblings("li:not(.init)").removeClass("selected");
      $("#ticket_item_detail").addClass("show");
    });

    $("#email").on("input", function () {
      G_EmailPass = isValidEmail($(this).val());
      update_submitBtn_status();
    });

    $("form").submit(async function (event) {
      event.preventDefault(); // Prevent the default form submission

      var formData = $(this).serializeArray(); // Get form data as an array of objects
      var jsonData = { ticketId: G_TargetTicketID };
      var agree = $("#agreeCheckbox").is(":checked");
      $.each(formData, function (index, field) {
        jsonData[field.name] = field.value;
      });

      let canPass = true;
      let errMsg = "";
      if (!jsonData?.ticketId || jsonData?.ticketId == null) {
        canPass = false;
        errMsg += $("#lang_7").text() + "\n";
      }
      if (!jsonData?.email || !isValidEmail(jsonData?.email || "")) {
        canPass = false;
        errMsg += $("#lang_8").text() + "\n";
      }
      if (!canPass) {
        alert(errMsg);
        return;
      }

      try {
        $(".loadingMask").removeClass("hide");
        const resOrderObj = await CreateTicketOrder(
          jsonData.email,
          jsonData.ticketId,
          agree
        );
        if (resOrderObj?.OrderId) {
          location.href =
            G__URL +
            `/payment?o=${resOrderObj?.OrderId}&jwt=${jsonData.email}&from=https://beatday.com/`;
        } else {
          throw new Error("訂單錯誤, Missing OrderId");
        }
      } catch (error) {
        console.error(error);
        alert(error.message);
      }
      $(".loadingMask").addClass("hide");
    });
    $(document).on("click", ".ConcertType", function () {
      //- 選演唱會分類
      $(this).addClass("choose").siblings(".ConcertType").removeClass("choose");
      $("#ticket_item_detail").empty();
      const _key = parseInt($(this).attr("key"));
      RenderTicketOptions(_key, qtyData);
      G_TargetTicketID = null;
      update_submitBtn_status();
      $("#ticket_item_detail").removeClass("show");
      $("#agreeCheckbox").prop("checked", false); // 清空复选框勾选状态
    });

    // 判断商品类型并修改选项模式
    $("#Store_menu").on("click", ".ConcertType", function () {
      checkDateAndHideCheckbox();
      $(this).addClass("selected").siblings(".ConcertType").removeClass("selected");
      const tId = $(this).attr("tId");
      currentTId = tId;
      if (tId === "S_COMICP") {
        $("#ticketType").show();
        $("#ticketDate").show();
        $("#ticketId").hide();
        $(".select_box").show();
        RenderTicketOptions(1, qtyData);
      } else {
        $(".select_box").hide();
        $("#ticketType").hide();
        $("#ticketDate").hide();
        $("#ticketId").show();
      }
    });
    $(document).ready(function () {
      $(".select_box").hide();
      // alert("Loading");
      LoadQtyData()
        .then((data) => {
          qtyData = data;

          const tId = $(".ConcertType.selected").attr("tId");
          // alert("Loading Finish" + tId);
          currentTId = tId;
          if (tId === "S_COMICP") {
            RenderTicketOptions(1, qtyData);
          } else if (tId === "S_COMIC") {
            RenderTicketOptions(0, qtyData);
          }
          // qtyData = data;
          // RenderTicketOptions(0, qtyData);
        })
        .catch((error) => {
          console.error("Failed to load quantity data:", error);
        });

      // 初次添加事件监听器
      $("#ticketType").change(handleTicketSelection);
      $("#ticketDate").change(handleTicketSelection);
    });
  },
};
</script>

<style lang="scss">
@import "../assets/styles/pages/BuyTickets.scss";
</style>
