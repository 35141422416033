<template>
    <section class="member-information account">
        <h1>帳號管理</h1>

        <div class="filter-section">
            <button class="filter-button active">會員資料</button>
            <button class="filter-button"><router-link to="/member/reset-password">重設密碼</router-link></button>
        </div>

        <div class="member-info">
            <div v-if="!editing">
                <!-- 顯示會員資料 -->
                <div class="member-list"><span class="title">使用者名稱</span>
                    <p :class="{ 'empty': userData.Nickname === '未填寫' }">{{ userData.Nickname }}</p>
                </div>
                <div class="member-list"><span class="title">電子郵件</span>
                    <p :class="{ 'empty': userData.UserEmail === '未填寫' }">{{ userData.UserEmail }}</p>
                </div>
                <div class="member-list"><span class="title">性別</span>
                    <p :class="{ 'empty': userData.Gender === '未填寫' }">{{ userData.Gender }}</p>
                </div>
                <div class="member-list"><span class="title">生日</span>
                    <p :class="{ 'empty': userData.birthday === '未填寫' }">{{ userData.birthday }}</p>
                </div>
                <!-- <div class="member-list"><span class="title">聯絡電話</span>
                    <p :class="{ 'empty': userData.phoneNumber === '未填寫' }">{{ userData.phoneNumber }}</p>
                </div> -->
                <div class="member-list"><span class="title">地區</span>
                    <p :class="{ 'empty': userData.region === '未填寫' }">{{ userData.region }}</p>
                </div>
                <div class="member-list"><span class="title">個人簡介</span>
                    <p :class="{ 'empty': userData.bio === '未填寫' }">{{ userData.bio }}</p>
                </div>

                <!-- 編輯按鈕 -->
                <button @click="startEditing" class="submit-button">修改</button>
            </div>
            <form v-if="editing" @submit.prevent="saveChanges">
                <!-- 編輯表單 -->
                <label>
                    <span class="title">使用者名稱</span>
                    <div class="alert" v-if="usernameAlert">＊ 請勿輸入特殊字元</div>
                    <input v-model="userData.Nickname" @input="handleInput" @blur="validateInput('Nickname')" />
                    <span class="charLimit">字數限制 {{ UserCharCount }}/10</span>
                </label>
                <label class="mail">
                    <span class="title">電子郵件</span>
                    <!-- 顯示特殊字元警告 -->
                    <div class="alert" v-if="emailAlert">＊ 請勿輸入特殊字元</div>
                    <!-- 電子郵件輸入框 -->
                    <input v-model="userData.UserEmail" @input="handleInput" @blur="validateInput('UserEmail')"
                        :disabled="emailDisable || emailDisableVerification" />
                    <!-- 顯示驗證成功提示 -->
                    <span v-if="verificationCodeCorrect">
                        <i class="bi bi-check-circle"></i>
                        驗證成功！</span>
                    <!-- 發送驗證碼按鈕 -->
                    <button v-if="!emailDisable && !verificationCodeCorrect" @click="sendVerificationCode"
                        :disabled="resendDisabled" class="submit-button">
                        {{ resendButtonText }}
                    </button>
                    <!-- 驗證碼輸入框 -->
                    <div class="flex">
                        <input v-model="verificationCode" @input="verifyCode" v-if="verificationCodeSent"
                            placeholder="輸入驗證碼" class="verificationCode" />
                        <p class="verificationCodeAlert" v-if="verificationCodeSent">{{ verificationAlert }}</p>
                    </div>
                </label>
                <label>
                    <span class="title">性別</span>
                    <div class="genderRadio">
                        <input type="radio" id="male" v-model="userData.Gender" value="男" />
                        <label for="male">男</label>

                        <input type="radio" id="female" v-model="userData.Gender" value="女" />
                        <label for="female">女</label>

                        <input type="radio" id="reserve" v-model="userData.Gender" value="保留" />
                        <label for="reserve">保密</label>
                    </div>
                </label>
                <label><span class="title">生日</span>
                    <input type="date" v-model="userData.birthday" class="birth" @keydown.enter.prevent />
                </label>
                <!-- <div class="phone_box">
                    <span class="title">聯絡電話</span>
                    <div class="alert" v-if="phoneNumberAlert">＊ 請填寫正確電話號碼格式</div>
                    <vue-tel-input v-model="userData.phoneNumber" :dropdownOptions="dropdownOptions"
                        :inputOptions="inputOptions" @input="handlePhoneInput" @validate="phoneObject" />
                </div> -->
                <label><span class="title">地區</span>
                    <div class="select_box">
                        <select v-model="userData.region">
                            <option value="" disabled selected hidden>請選擇</option>
                            <option value="臺北市">臺北市</option>
                            <option value="新北市">新北市</option>
                            <option value="基隆市">基隆市</option>
                            <option value="新竹市">新竹市</option>
                            <option value="桃園市">桃園市</option>
                            <option value="新竹縣">新竹縣</option>
                            <option value="宜蘭縣">宜蘭縣</option>
                            <option value="高雄市">高雄市</option>
                            <option value="臺南市">臺南市</option>
                            <option value="嘉義市">嘉義市</option>
                            <option value="嘉義縣">嘉義縣</option>
                            <option value="屏東縣">屏東縣</option>
                            <option value="澎湖縣">澎湖縣</option>
                            <option value="花蓮縣">花蓮縣</option>
                            <option value="臺東縣">臺東縣</option>
                            <option value="金門縣">金門縣</option>
                            <option value="連江縣">連江縣</option>
                            <option value="海外">海外</option>
                        </select>
                    </div>
                </label>
                <label><span class="title">個人簡介</span>
                    <input v-model="userData.bio" @input="checkBioLength">
                    <span class="charLimit">字數限制 {{ BioCharCount }}/10</span>
                </label>
                <div class="alert fix">{{ alert }}</div>
                <!-- 儲存和取消按鈕 -->
                <button @click="cancelEditing" class="submit-button">取消</button>
                <button type="submit" class="submit-button">儲存</button>
            </form>
        </div>

    </section>
    <Loading v-show="loading"></Loading>
</template>


<script>
// import { VueTelInput } from 'vue-tel-input';
// import 'vue-tel-input/vue-tel-input.css';
// import { getAccountInfo, updateUserInfo, sendVerificationCode } from '@/api/apiService';
import { getAccountInfo, updateUserInfo } from '@/api/apiService';
import Cookies from 'js-cookie';
import Loading from '@/components/Loading.vue';

export default {
    name: 'MemberInformation',
    components: {
        // VueTelInput,
        Loading,
    },
    data() {
        return {
            dropdownOptions: {
                disabled: false,
                showDialCodeInList: true,
                showDialCodeInSelection: true,
                showFlags: true,
                width: '100%'
            },
            inputOptions: {
                placeholder: "請輸入電話號碼"
            },
            userData: {
                Nickname: '',
                UserEmail: '',
                Gender: '',
                birthday: '', //1990-01-01 格式
                // phoneNumber: '',
                region: '',
                bio: '',
            },
            jwt: '',
            loading: false,
            phoneInput: '',
            disallowedCharsRegex: /['"<>\\;=()\s]/, // 禁止的特殊字元
            editing: false,
            usernameAlert: false,
            usernameCheck: false,
            UserCharCount: 0,
            // mail相關參數
            emailAlert: false, // 用於檢查特殊字元的警告
            emailCheck: false, // 用於檢查格式是否正確
            emailPass: false,
            emailDisable: false,
            emailDisableVerification: false,
            verificationCode: '',
            verificationCodeSent: false,
            resendDisabled: true, // 控制重新發送按鈕是否禁用
            resendButtonText: '發送驗證碼', // 重新發送按鈕的文字
            verificationCodeCorrect: false, // 驗證碼是否正確
            verificationAlert: '＊ 請至信箱確認驗證碼，並檢查信箱是否填寫正確', //驗證碼的錯誤訊息
            countdown: 60, // 倒數計時初始值
            //
            // phoneNumberAlert: false,
            BioCharCount: 0,
            alert: '',
        };
    },
    mounted() {
        this.processUserData();
        this.emailDisable = this.userData.UserEmail !== '';
        this.emailPass = this.userData.UserEmail !== '';
    },
    methods: {
        // phoneObject(object) {
        //     if (!object.valid) {
        //         this.phoneNumberAlert = true;
        //     } else {
        //         this.phoneNumberAlert = false;
        //     }
        // },
        // handlePhoneInput() {
        //     // 移除非數字字符
        //     this.userData.phoneNumber = this.userData.phoneNumber.replace(/[^0-9]/g, '');
        // },
        // 驗證輸入是否包含特殊字元
        validateInput(field) {
            const inputValue = this.userData[field];

            if (this.disallowedCharsRegex.test(inputValue)) {
                // 不允許的特殊字元存在，顯示警告
                this[field + 'Alert'] = true;
            } else {
                // 處理合法的輸入
                this[field + 'Check'] = true;
                this[field + 'Alert'] = false;
            }
        },
        // 禁止輸入空白鍵
        handleInput() {
            const inputEmail = this.userData.UserEmail;
            const inputUsername = this.userData.Nickname;

            // 移除空白鍵
            this.userData.UserEmail = inputEmail.trim();
            this.userData.Nickname = inputUsername.trim();

            // 正規表達式檢查電子郵件格式
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

            if (emailRegex.test(this.userData.UserEmail)) {
                this.resendDisabled = false;
            } else {
                // 不符合電子郵件格式
                this.resendDisabled = true;
            }
            this.UserCharCount = this.userData.Nickname === '未填寫' ? 0 : this.userData.Nickname.length;
            if (this.UserCharCount > 10) {
                this.userData.Nickname = this.userData.Nickname.slice(0, 10);
                this.UserCharCount = 10;
            }
        },

        // 發送驗證碼
        sendVerificationCode() {
            // 假設這裡執行發送驗證碼的邏輯，這裡只是示例
            this.verificationCodeSent = true;
            this.emailDisableVerification = true;

            // 啟動倒數計時器
            this.startCountdown();
        },

        // 倒數計時
        startCountdown() {
            this.resendDisabled = true; // 禁用重新發送按鈕
            this.resendButtonText = '重新發送 ' + this.countdown + 's'; // 更新按鈕文字

            const timer = setInterval(() => {
                if (this.countdown > 0) {
                    this.countdown--;
                    this.resendButtonText = '重新發送 ' + this.countdown + 's'; // 更新按鈕文字
                } else {
                    clearInterval(timer);
                    this.resendDisabled = false; // 倒數結束後啟用重新發送
                    this.resendButtonText = '發送驗證碼'; // 還原按鈕文字
                    this.emailDisableVerification = false;
                }
            }, 1000); // 每秒更新一次
        },

        // 驗證輸入的驗證碼是否正確
        verifyCode() {
            // 假設驗證碼為 '1234'
            if (this.verificationCode === '1234') {
                this.verificationCodeCorrect = true;
                this.verificationCodeSent = false;
                this.emailDisable = true;
                this.emailPass = true;
            } else {
                this.verificationAlert = '＊ 驗證碼錯誤'
            }
        },
        startEditing() {
            this.UserCharCount = this.userData.Nickname === '未填寫' ? 0 : this.userData.Nickname.length;
            this.BioCharCount = this.userData.bio === '未填寫' ? 0 : this.userData.bio.length;
            this.editing = true;
            this.clearUserData();
        },
        async saveChanges() {
            if (!this.userData.Nickname.trim() && !this.usernameAlert) {
                this.alert = '＊請填寫使用者名稱';
                return;
            }

            if (!this.userData.UserEmail.trim() || !this.emailPass) {
                this.alert = '＊請填寫並驗證電子郵件';
                return;
            }

            if (!this.userData.Gender.trim()) {
                this.alert = '＊請選擇性別';
                return;
            }

            if (!this.userData.birthday.trim()) {
                this.alert = '＊請選擇生日';
                return;
            }

            // if (!this.userData.phoneNumber.trim()) {
            //     this.alert = '＊請填寫聯絡電話';
            //     return;
            // }

            if (!this.userData.region.trim()) {
                this.alert = '＊請選擇地區';
                return;
            }
            const uid = Cookies.get('Id')
            const data = {
                Id: uid,
                Nickname: this.userData.Nickname,
                Email: this.userData.UserEmail,
                Gender: this.userData.Gender,
                Birthday: this.userData.birthday,
                Area: this.userData.region,
                Intro: this.userData.bio
            };
            this.loading = true;
            const response = await updateUserInfo(data)
            this.loading = false;
            const responseData = response.data;
            this.userData = responseData;
            this.editing = false;
        },
        cancelEditing() {
            // 取消編輯，重新整理頁面
            location.reload();
        },
        checkBioLength() {
            this.BioCharCount = this.userData.bio === '未填寫' ? 0 : this.userData.bio.length;
            console.log('check', this.BioCharCount)
            if (this.BioCharCount > 10) {
                this.userData.bio = this.userData.bio.slice(0, 10);
                this.BioCharCount = 10;
            }
        },
        async processUserData() {
            this.jwt = await Cookies.get('jwt');
            // if (!this.jwt) {
            //     this.$router.push('/member-login/login');
            // }
            const data = {
                jwt: this.jwt
            };
            this.loading = true;
            const response = await getAccountInfo(data);
            this.loading = false;
            const responseData = response.data;
            this.userData.Nickname = responseData.Nickname;
            this.userData.UserEmail = responseData.UserEmail;
            this.userData.birthday = responseData.birthday;
            this.userData.Gender = responseData.Gender === 1 ? '男' : '女';
            // 空欄位带入 "未填寫" 的字段
            for (const key in this.userData) {
                if (Object.prototype.hasOwnProperty.call(this.userData, key)) {
                    if (this.userData[key] === null || this.userData[key] === '' || this.userData[key] === 0) {
                        this.userData[key] = '未填寫';
                        this.userData[`${key}IsEmpty`] = true;
                    }
                }
            }
        },
        clearUserData() {
            // 清空已经带入 "未填寫" 的字段
            for (const key in this.userData) {
                if (Object.prototype.hasOwnProperty.call(this.userData, key)) {
                    if (this.userData[key] === '未填寫') {
                        this.userData[key] = '';
                    }
                }
            }
        },
    },
}
</script>

<style lang="scss"></style>
