<template>
    <section class="ticket-section">
        <h1>票券專區<img src="../../assets/img/public/question-circle.svg" @click="showAlert" draggable="false"></h1>

        <div class="filter-section">
            <button v-for="category in categories" :key="category"
                :class="{ 'filter-button': true, 'active': currentCategory === category }"
                @click="changeCategory(category)">
                {{ category === 'all' ? '所有票券' : category === 'unused' ? '尚未使用' : '已過期' }}
            </button>
        </div>

        <div class="ticket-list" v-if="!empty">
            <div v-for="ticket in filteredTickets" :key="ticket.Id" class="ticket-item">
                <div class="item-info">
                    <img :src="ticket.Pic1" alt="">
                    <div class="text">
                        <p class="ticket" v-if="ticket.Qty > 1"><img src="../../assets/img/member/tickets.svg" alt=""> {{
                            ticket.Qty }} </p>
                        <h2 class="title">{{ ticket.ShowName }}</h2>
                        <h3>{{ ticket.Name }}</h3>
                    </div>
                </div>
                <!-- <button class="go-to" :class="{ 'close': ticket.Valid }" @click="goToUsage(ticket.Id)"> -->
                <button class="go-to" :class="{ 'close': !ticket.Valid || ticket.isRedeemed }"
                    @click="goToUsage(ticket.Id)">

                    {{ ticket.Valid === false || ticket.isRedeemed === true ? '已過期' : '前往使用' }}
                </button>
            </div>
        </div>
        <div v-if="empty" class="empty">
            <h2>尚無票券請前往<router-link to="/member/serial-number"><span>序號兌換</span></router-link></h2>
        </div>
        <notice-box v-show="displayAlert" @close-alert="hideAlert"></notice-box>
        <info-popup v-if="selectedTicket" :ticket="selectedTicket" @close-info-popup="closeInfoPopup"></info-popup>

    </section>
    <Loading v-show="loading"></Loading>
</template>

<script>
import InfoPopup from '@/components/MemberCenter/InfoPopup.vue';
import NoticeBox from '@/components/MemberCenter/Notice.vue';
import Cookies from 'js-cookie';
import { getTicketInfo, getAccountInfo } from '@/api/apiService';
import Loading from '@/components/Loading.vue';


export default {
    name: 'TicketSection',
    components: {
        'info-popup': InfoPopup,
        'notice-box': NoticeBox,
        Loading
    },
    computed: {
        // 篩選「所有票券、尚未使用、已過期」
        filteredTickets() {
            return this.tickets.filter(ticket => {
                if (this.currentCategory === 'all') {
                    return true;
                } else if (this.currentCategory === 'unused') {
                    return ticket.Valid === true && ticket.isRedeemed === false; //尚未使用
                } else if (this.currentCategory === 'expired') {
                    return ticket.Valid === false || ticket.isRedeemed === true; //已過期
                }
            });
        }
    },
    mounted() {
    },
    data() {
        return {
            jwt: "",
            loading: false,
            categories: ['all', 'unused', 'expired'],
            currentCategory: 'all',
            tickets: [], // 資料庫取得的票券資料
            empty: false,
            displayAlert: false,
            selectedTicket: null,
            lang: 'ZHTW',
            bpAccountLogin: false
        };
    },
    created() {
        this.getData();
    },
    methods: {
        async getData() {
            const currentUrl = window.location.hash;
            const urlParamsString = currentUrl.split('?')[1];
            const urlParams = new URLSearchParams(urlParamsString);
            if (urlParams.get("JWT")) {
                this.jwt = urlParams.get("JWT");
                Cookies.set('jwt', this.jwt, { expires: 7 });
            }
            else {
                this.jwt = Cookies.get('jwt');
                if (!this.jwt) {
                    this.$router.push('/member-login/login');
                }
            }
            const data = {
                jwt: this.jwt
            };
            this.loading = true;
            const response = await getAccountInfo(data);
            this.loading = false;
            const responseData = response.data;
            const Id = responseData.Uid;
            Cookies.set('Id', Id, { expires: 7 });
            this.bpAccountLogin = Cookies.get('bpAccountLogin');
            this.fetchTickets();
            this.$emit('loginSuccess');
        },
        async fetchTickets() {
            if (this.jwt) {
                const data = {
                    jwt: this.jwt,
                    // lang: this.lang
                }
                this.loading = true;
                const response = await getTicketInfo(data);
                this.loading = false;
                const responseData = response.data;
                const parsedResponse = JSON.parse(responseData);
                const proxyResponse = Object.values(parsedResponse);
                this.tickets = [...proxyResponse];

                if (this.tickets.length === 0) {
                    this.empty = true;
                }
                this.tickets = proxyResponse.map(ticket => ({
                    ...ticket,
                    isRedeemed: ticket.Used >= ticket.Qty
                }));
            }
            else {
                // 模擬資料庫回傳的票券資料
                const mockData = [
                    {
                        Id: 1,
                        ShowName: '林襄沉浸式寫真',
                        Name: 'Mobile 完整版',
                        Detail: 'Mobile 版系列請至 App Store、Google Play 下載；VR 版用戶請至 VIVEPORT、VIVE Business、Meta Store 下載。',
                        Pic1: 'http://d2uz35uzla1hiz.cloudfront.net/media/LTN/icon/Concert202204Test.jpg',
                        Valid: true,
                        Type: 0,
                        Qty: 2,
                        used_qty: 0
                    },
                    {
                        Id: 2,
                        ShowName: '林襄沉浸式寫真',
                        Name: 'VR 版',
                        Detail: `使用說明：<br>
                                  ・ Mobile 版系列請至 App Store、Google Play 下載；<br>
                                  ・ VR 版用戶請至 VIVEPORT、VIVE Business、Meta Store 下載。<br><br>
                                  注意事項：<br>
                                  ・ 請先確認手機、VR 規格是否<br>支援「沉浸式寫真」內容<br>
                                  ・ 需連線至網際網路<br>（ 行動數據或 Wi-Fi 皆可 ）<br>
                                  ・ 觀看期限為產品上架起算三年<br>
                                  ・ 如有任何產品問題，請洽 BEATDAY <br>客服信箱  <a href="contact@beatday.com" class="link">contact@beatday.com </a>
                                  或是<br>「沉浸式寫真」專屬客服信箱<a href="immersive_ptb@beatday.com" class="link"> immersive_ptb@beatday.com</a><br>`,
                        Pic1: 'http://d2uz35uzla1hiz.cloudfront.net/media/LTN/icon/Concert202204Test.jpg',
                        Valid: true,
                        Type: 0,
                        Qty: 5,
                        used_qty: 0
                    },
                    { Id: 3, ShowName: '林襄沉浸式寫真', Name: 'VR 觀影券', Detail: '說明', Pic1: 'http://d2uz35uzla1hiz.cloudfront.net/media/LTN/icon/Concert202204Test.jpg', Valid: true, Type: 1, Qty: 1, used_qty: 0 },
                ];
                this.tickets = mockData
            }

        },
        // updateTicketStatus(ticketId, status) {
        //     const ticket = this.tickets.find(ticket => ticket.Id === ticketId);
        //     console.log('found', ticket, ticketId, status);
        //     if (ticket) {
        //         ticket.isRedeemed = status;
        //     }
        // },
        changeCategory(category) {
            this.currentCategory = category;
        },
        goToUsage(ticketId) {
            document.body.style.overflow = 'hidden';
            this.selectedTicket = this.filteredTickets.find(ticket => ticket.Id === ticketId);
            this.$emit('show-info-popup');
        },
        closeInfoPopup() {
            document.body.style.overflow = '';
            this.selectedTicket = null;
        },
        showAlert() {
            document.body.style.overflow = 'hidden';
            this.displayAlert = true;
        },
        hideAlert() {
            document.body.style.overflow = '';
            this.displayAlert = false;
        },
    }
};
</script>
