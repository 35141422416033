
<template>
    <section class="registration-binding account-setting">
        <h1>註冊 BEATDAY 帳號</h1>
        <div class="filter-section">
            <router-link to="/member/serial-number/account-binding"><button class="filter-button ">綁定
                    BEATDAY</button></router-link>
            <router-link to=""><button class="filter-button active ">創建 BEATDAY</button></router-link>
        </div>

        <form class="login_box" v-if="showInputForm" @submit.prevent="login">
            <label class="">
                <span class="title">帳號</span>
                <input type="text" v-model="username" placeholder="請輸入電子信箱" name="username" @input="handleInput" />
            </label>
            <label class="">
                <span class="title">密碼</span>
                <div class="eye_fixed">
                    <input :type="passwordVisible ? 'text' : 'password'" v-model="password" placeholder="請輸入密碼"
                        name="passwordNew" />
                    <div class="eye_btn" @click="togglePasswordVisibility('new')"><i class="bi"
                            :class="passwordVisible ? 'bi-eye-fill' : 'bi-eye'"></i></div>
                </div>
            </label>
            <label class="">
                <span class="title">確認密碼</span>
                <div class="eye_fixed">
                    <input :type="passwordVisible ? 'text' : 'password'" v-model="confirmPassword" placeholder="再次輸入密碼"
                        name="confirmPassword" />
                    <div class="eye_btn" @click="togglePasswordVisibility('new')"><i class="bi"
                            :class="passwordVisible ? 'bi-eye-fill' : 'bi-eye'"></i></div>
                </div>
            </label>

            <div class="alert">{{ alert }}</div>
            <button type="submit" class="submit-button">下一步</button>

        </form>

        <form v-if="showVerifyNumber" @submit.prevent="verifyCode">
            <div class="verify">
                <label for="">
                    <input v-model="verificationCode" type="verify" id="verify" name="verify" placeholder="請輸入驗證碼">
                </label>
            </div>

            <div class="alert">{{ alert }}</div>
            <button type="submit" class="submit-button prev" @click="prev">上一步</button>
            <button type="submit" class="submit-button">確認</button>
        </form>

        <div v-if="registrationSuccess" class="registration-success">
            <div class="flex">
                <h2 class="title">綁定 BEATDAY 成功</h2>
                <dotlottie-player id="check-box"
                    src="https://lottie.host/d525671a-5c4b-4694-a264-2d8554f45e93/mSj3CZexHR.json" background="transparent"
                    speed="1" autoplay=""></dotlottie-player>
            </div>
            <p>將在 {{ countdownToHome }} 秒後自動跳轉至 <router-link to="/member/serial-number">序號兌換</router-link></p>
        </div>
    </section>
    <Loading v-show="loading"></Loading>
</template>



<script>
import { signUp, verifyCode, bindAccount } from '@/api/apiService';
import Loading from '@/components/Loading.vue';
import Cookies from 'js-cookie';


export default {
    name: 'AccountBinding',
    components: {
        Loading
    },
    data() {
        return {
            username: '',
            password: '',
            confirmPassword: '',
            alert: '',
            showInputForm: true,
            showVerifyNumber: false,
            registrationSuccess: false,
            passwordVisible: false,
            // mail相關參數
            emailCheck: false, // 用於檢查格式是否正確
            countdownToHome: 5, // 回到序號兌換頁面倒數
            // 驗證
            verificationCode: '',
            loading: false,
            jwt: ''
        };
    },
    methods: {
        // 禁止輸入空白鍵
        handleInput() {
            const inputEmail = this.username;
            // 移除空白鍵
            this.username = inputEmail.trim();

            // 正規表達式檢查電子郵件格式
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (emailRegex.test(this.username)) {
                // 格式正確

                this.showVerifyNumber = false;
                this.emailCheck = true;
            } else {
                // 格式錯誤
                this.emailCheck = false;
            }
        },
        async login() {
            // 1. 帳號不能空白，且通過驗證
            if (!this.emailCheck) {
                this.alert = '＊ 請輸入帳號及密碼';
                return;
            }

            // 2. 檢查密碼是否符合長度8-16位
            const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z]).{8,16}$/;
            if (!passwordRegex.test(this.password)) {
                this.alert = "＊ 請輸入 8 - 16 位數密碼，且包含大小寫";
                return;
            }

            // Check if passwords match
            if (this.password !== this.confirmPassword) {
                this.alert = '＊ 密碼和確認密碼不一致。請重新輸入';
                return;
            }

            else {
                try {
                    this.jwt = Cookies.get('jwt');
                    const data = {
                        username: this.username,
                        password: this.password
                    }

                    this.loading = true;
                    await signUp(data);
                    this.showInputForm = false;
                    this.showVerifyNumber = true;
                    this.alert = '';
                    this.loading = false;
                } catch (error) {
                    this.alert = "此帳號已被註冊。";
                    this.loading = false;
                }

            }
        },
        togglePasswordVisibility() {
            // Toggle the visibility of the password
            this.passwordVisible = !this.passwordVisible;
        },
        async verifyCode() {
            const data = {
                request: 'verifyCode',
                account: this.username,
                code: this.verificationCode,
                reason: 'verifyAcc'
            }
            const bindInput = {
                jwt: this.jwt,
                email: this.username
            }
            try {
                this.loading = true;
                if (this.showVerifyNumber === true) {
                    const response = await verifyCode(data);
                    if (response.status === 200) {
                        this.alert = '';
                        this.playAnimation();
                        this.showVerifyNumber = false;
                        this.registrationSuccess = true;
                        const resBind = await bindAccount(bindInput);
                        if (resBind.status === 200) {
                            Cookies.set('bpAccountLogin', true, { expires: 7 });
                            const countdownInterval = setInterval(() => {
                                if (this.countdownToHome > 0) {
                                    this.countdownToHome--;
                                } else {
                                    clearInterval(countdownInterval);
                                    this.$router.push('/member/serial-number');
                                }
                            }, 1000);
                        }
                    }
                    this.loading = false;
                }

            }
            catch (error) {
                this.loading = false;
                this.alert = '＊ 驗證碼錯誤，請重新輸入';
            }

        },
        prev() {
            this.showInputForm = true;
            this.showVerifyNumber = false;
            this.alert = '';
        },
        playAnimation() {
            // 使用 $nextTick 以確保元素已經渲染到DOM中
            this.$nextTick(() => {
                // 使用 document.querySelector 來選擇元素，而不是直接使用 jQuery
                const checkBox = document.querySelector("#check-box");
                if (checkBox) {
                    // 檢查元素是否存在，然後播放動畫
                    setInterval(() => {
                        checkBox.play();
                    }, 0);
                }
            });
        },
    }
};
</script>

<style lang="scss"></style>
