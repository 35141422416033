
<template>
    <div class="modal binding-popup">
        <div class="mask"></div>
        <div class="modal-content">
            <div class="flex">
                <h1>為求體驗最佳化，再請您先綁定 BEATDAY 帳號</h1>
                <router-link to="/member/serial-number/account-binding"><button class="btn">已有 BEATDAY
                        帳號綁定</button></router-link>
                <router-link to="/member/serial-number/registration-binding"><button class="btn">創建 BEATDAY
                        帳號</button></router-link>
            </div>
        </div>
    </div>
</template>


<script>
export default {
    name: 'BindingPopup',
    data() {
        return {
        };
    },
    methods: {

    }
};
</script>

