<template>
    <aside>
        <h1>會員中心</h1>
        <nav>
            <ul>
                <li><router-link to="/member/ticket-section" exact active-class="active">票券專區</router-link></li>
                <li><router-link to="/member/serial-number" active-class="active">序號兌換</router-link></li>
                <!-- <li><router-link to="/member/my-props" active-class="active" class="close">我的道具</router-link></li> -->
                <!-- <li class="accountSetting"><h3>帳號設定</h3>
                    <ul>
                        <li><router-link to="/member/member-information" active-class="active" class="close">會員資料</router-link></li>
                        <li><router-link to="/member/reset-password" active-class="active" class="close">重設密碼</router-link></li>
                    </ul>
                </li> -->
                <!-- <li><router-link to="/" active-class="active" class="close">歷史訂單</router-link></li> -->
            </ul>
        </nav>
    </aside>
</template>



<script>
export default {
    name: 'NavAside',
    data() {
        return {
        };
    },
    methods: {

    }
};
</script>

<style lang="scss"></style>
