<template>
    <app-header></app-header>
    <section class="wrapper legal">
        <nav-aside></nav-aside>
        <router-view></router-view>
    </section>
    <app-footer></app-footer>
</template>
<script>
import NavAside from '@/components/Legal/Nav.vue';
import AppHeader from '@/components/header.vue';
import AppFooter from '@/components/footer.vue';
export default {
    name: 'LegalPage',
    components: {
        'app-header': AppHeader,
        'app-footer': AppFooter,
        'nav-aside': NavAside,
    },
    mounted() {

    },
    data() {
        return {

        };
    },
    props: {},
    computed: {},
    methods: {
    },
}
</script>
    
<style lang="scss">
@import "../assets/styles/pages/legal.scss";
</style>