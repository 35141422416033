<template>
    <section class="privacy_page">
        <div class="wpb_wrapper">
            <div class="wpb_text_column wpb_content_element  qt-the-content">
                <div class="wpb_wrapper">
                    <h1><strong>隱私權政策</strong></h1>
                    <p>最近更新：2024年01月26日</p>
                    <p>本隱私權政策（「政策」）說明維特人股份有限公司及其關係企業（「維特人」、「本公司」、「我們」）如何處理及保護我們透過BEATDAY網站、應用程式、產品及服務（合稱「服務」）所蒐集的您的個人資料。</p>
                    <p>我們的服務不直接對未成年人提供。如您是未成年人，我們建議您與您的父母或監護人仔細閱讀本政策，並在他們的同意下使用本服務或提供您的個人資料給我們。</p>
                    <p>注意：您的無線電信業者、行動作業系統提供者及第三方之應用程式、工具列、服務、「外掛程式」、社交媒體平台和網站也可能蒐集、使用及分享與您、您的裝置及使用情況有關的資訊。本政策並不適用於任何第三方的網站、應用程式、產品及服務。我們無法控制第三方如何蒐用或保護您的資料。有關第三方隱私權施行原則的詳細資訊，請參閱其隱私權政策。
                    </p>
                    <p><strong>1. 資料蒐集</strong></p>
                    <p><strong>A. 您提供給我們的資料</strong></p>
                    <p>當您使用我們的服務時，我們可能會蒐集您向我們提供的資料，包括您的全名、電子郵件地址、電話號碼、帳號、密碼、暱稱、出生年、性別、居住地、位置資料、照片、通訊內容、容積攝影檔案與付款資訊。</p>
                    <p>我們可能持有並將您提供給我們的任何資料，與我們從其他來源所蒐集的資料，包括您選擇連結本服務使用的社群媒體帳戶，或我們從其他公司收到的資料，進行連結。</p>
                    <p>請注意：若您無法提供完整且確實的個人資料，您將無法使用本服務之全部或部分功能。建議您應提供完整的個人資料及隨時更新。若因您未提供完整且確實個人資料，造成對您及第三人發生損害時，您應負擔一切責任，如造成本公司的損失時，本公司有權向您請求損害賠償。
                    </p>
                    <p><strong>B. 我們自動蒐集的資料</strong></p>
                    <p>當您使用我們的服務時，我們可能會自動蒐集某些資料。我們可能會蒐集有關您使用服務以及在服務中從事活動的資料。</p>
                    <p>當您造訪我們的網站時，我們可能會自動記錄的資料包括您的作業系統、IP位址、造訪時間，瀏覽器類型與語言，以及造訪我們網站前所造訪過的網站。</p>
                    <p>當您使用BEATDAY PC版時，我們可能會記錄您的IP位址與登入及登出時間。</p>
                    <p>我們也可能會使用網路信標（web beacon）、cookies、本機共用物件（Local Shared
                        Object）及類似方法，記錄您的相關資料。我們可能會自動將我們使用這些方法所蒐集到有關您的任何資料，與您提供給我們的資料及我們依本政策所蒐集到有關您的任何其他資料，進行連結。</p>
                    <p><strong>C. 從其他來源獲得的資料</strong></p>
                    <p>我們可能會使用第三方廣告服務供應商在我們的服務中投放廣告。我們也可能透過第三方提供或協助我們在第三方網站上對您顯示我們的廣告。為提供此類廣告，第三方可能會自動蒐集有關您、您的裝置及您使用服務的某些資料，這些資料可能包括您對第三方及我們的網站和應用程式的造訪、您的
                        IP 位址、您的網際網路服務供應商，以及您瀏覽我們網站所使用的瀏覽器。第三方可能會使用 cookies
                        蒐集資料。我們還可能使用您的電子郵件地址，在社群媒體平台上對您進行針對性的行銷（根據您在這些平台上的行銷偏好）。</p>
                    <p>我們可能會在我們的服務或電子郵件中使用透過第三方 cookies
                        或信標蒐集的資料，以便在第三方網站上向您提供更好的廣告。在您瀏覽此類第三方網站後，我們可能會知道您何時造訪我們的網站，並使用所蒐集到有關您的資料，透過我們或第三方的服務針對您的興趣提供量身定做的廣告。
                    </p>
                    <p>我們可能會從向我們提供付款、餘額資料或該等資料的更新的支付服務商獲得相關資料（根據他們與您的關係）。</p>
                    <p>我們使用第三方服務供應商Volograms
                        Limited，向您提供Volu容積攝影人像生成服務，您必須在Volu註冊帳號後才能使用該服務，當您使用Volu服務後，Volu將向我們傳送您的容積攝影檔案，您可至此連結<a
                            href="https://www.volograms.com/volu">https://www.volograms.com/volu</a>詳閱Volu使用條款及隱私權聲明。</p>
                    <p>我們同時使用BEATDAY API，自BEATDAY伺服器抓取您提供的資料，包括您的位置資料、您所拍攝的容積攝影檔案、您的個人檔案
                        (出生年、性別、居住地)、及您與其他使用者於本服務中之通訊內容，這些資料將儲存在我們向第三方租用的雲端伺服器。</p>
                    <p><strong><u>ARKit</u></strong></p>
                    <p>有關：(a)BEATDAY iOS版，我們使用Apple的TrueDepth APIs，以便您在使用BEATDAY iOS版時可以使用iPhone的前鏡頭進行自拍；(b)沉浸式寫真Mobile iOS
                        版，我們亦使用Apple的TrueDepth APIs，以便您在使用沉浸式寫真Mobile iOS版時可以使用裝置鏡頭進行互動或攝影。這個功能會抓取您的臉部數據（facial
                        data），但這些數據不會傳送給我們，也不會與任何第三方分享。</p>
                    <p><strong>2. 個人資料的使用</strong></p>
                    <p>我們只會在有法律依據的情況下使用您的個人資料。在不同的情況下，我們可能會基於下列原因使用您的個人資料：(a) 您的同意；(b) 履行您和我們之間的合約所必要；(c) 保護您或他人的重要利益；(d)
                        履行法定義務。</p>
                    <p>我們可能會在下列情況下使用您的資料（包括個人資料）：</p>
                    <ul>
                        <li>提供您本服務或您所要求的服務，包括設立或登入使用者帳號、地圖定位、儲存及處理您透過第三方服務提供商服務所生成之容積攝影檔案、將您提供之資料進行備份等；</li>
                        <li>向您發送與您的請求、查詢及購買有關的資料，包括確認、發票、技術通知、更新、安全警示與支援及訊息管理；</li>
                        <li>向您發送行銷資訊，包括有關我們及我們所選合作夥伴針對產品與服務所進行的比賽、宣傳、獎勵、即將舉辦的活動與新聞，並處理及交付參賽作品與獎勵；</li>
                        <li>了解您及您的喜好，以提升您使用我們服務的體驗和樂趣；</li>
                        <li>經營並改進我們的網站、產品、服務及行銷方式；</li>
                        <li>回應您的意見與問題，並提供客戶服務；</li>
                        <li>編製資料以便在我們所有的服務中為您提供個性化與客製化的體驗、內容、行銷及推薦（此將取決於您對接收任何直接行銷通訊的選擇）；</li>
                        <li>為了本政策所揭露的目的，包括幫助了解您及您的需求與向您提供建議、更優質的服務及客製化行銷內容，而將您的個人資料與我們從其他來源取得的其他資料進行連結；</li>
                        <li>篩選服務所顯示之內容；</li>
                        <li>編纂使用情形及與服務相關的其他統計資料和深入資訊；以及</li>
                        <li>防範、調查及遏制詐欺、未經授權或非法活動，確保網路與資訊安全。</li>
                    </ul>
                    <p>我們可能會與我們的合作夥伴，例如廣告商或開發者，分享某些非屬個人資料的資訊，例如，我們可能會分享分析報告，內容包含客戶使用情形或其他統計數據的匯總，以顯示我們產品或服務的一般使用趨勢。</p>
                    <p>如果您的個人資料對我們提供您所要求的服務而言是必要者，我們會在蒐集時明確說明。如果您拒絕提供此類資訊或反對我們繼續處理此類資訊，我們將無法向您提供此類服務。</p>
                    <p>如果您反對我們將您的個人資料用於進行行銷通訊或您撤回同意，我們可能只會在我們的內部電子郵件系統中處理某些必要的資料（例如姓名和電子郵件地址），以確保我們遵守您的反對或撤回同意。</p>
                    <p><strong>3. 個人資料的分享</strong></p>
                    <p>在合法之前提下，我們得按照下列方式分享您的個人資料：</p>
                    <p><strong>在您同意下。</strong>例如，在您同意接受行銷的情況下，我們得將您的個人資料分享給廣告網路供應商。在您同意的情形下，我們亦得將您的個人資料分享給聯合品牌合作夥伴。我們的合作夥伴對您個人資料的使用，將受其隱私權政策的拘束。
                    </p>
                    <p><strong>為了商業交易的目的。</strong>例如，我們可能會基於涉及銷售或轉讓我們全部或部分業務或資產之商業交易（或商業交易的談判）之目的而分享您的個人資料。<strong>&nbsp;</strong>商業交易可能包括任何合併、融資、收購或破產之交易或訴訟程序。
                    </p>
                    <p><strong>基於法律、保護、擔保及安全等目的。</strong>我們可能基於以下目的分享您的個人資料：</p>
                    <ul>
                        <li>基於遵守法律或法規要求，以及回應合法要求及法律程序；</li>
                        <li>基於保護我們、我們的代理商、客戶及其他人之權利與財產，包括強制履行我們之合約、政策及使用條款，以及保護我們之網路與實體資產；及/或</li>
                        <li>基於緊急狀況所需，包括保護我們的員工、代理商或客戶或任何其他人的安全。</li>
                    </ul>
                    <p><strong>與我們的關係企業分享。</strong>我們可能會視需要與我們的關係企業分享您的個人資料，以提供我們的產品或服務，以及經營我們的業務。</p>
                    <p><strong>與我們的服務供應商分享。</strong>我們可能會與我們的服務供應商分享您的個人資料，這些服務供應商提供的服務即是為我們依本政策所列目的處理個人資料，並應遵守適當的契約義務。這些第三方服務供應商包括但不限於：Amazon
                        Web Services, Inc. (即AWS，位於美國)，我們使用AWS的雲端服務，您可透過此連結閱讀AWS之使用者條款及隱私權政策<a
                            href="https://aws.amazon.com/legal/?nc1=f_cc">https://aws.amazon.com/legal/?nc1=f_cc</a>。</p>
                    <p><strong>與無線網路營運商分享。</strong>我們可能與無線網路營運商分享您的個人資料，以便他們向您提供服務。他們對您資料的使用，將受其隱私權政策之拘束。</p>
                    <p>我們可能基於任何目的與他人分享已彙總並去識別化的資料。</p>
                    <p><strong>4. 您的資料的儲存及傳輸</strong></p>
                    <p>我們蒐集的資料可能儲存在您的裝置或我們的伺服器上（包括我們向第三方租用的雲端伺服器，儲存地點包含日本東京）。如果我們將您的資料傳輸到您居住國家或地區以外的第三方，我們將採取措施以確保符合適用法律，包括但不限於執行標準合約條款或取得您的同意。
                    </p>
                    <p><strong>5. 資料保存</strong></p>
                    <p>我們所蒐集的資料的保存期間將不超過處理個人資料的目的所需要的期間，或適用法律所要求的期間。</p>
                    <p>在決定相關保留期間時，我們將考慮下列因素：</p>
                    <ul>
                        <li>我們對該個人資料所涉及的契約義務與權利。</li>
                        <li>適用法律所規定須於特定期間保留資料的法定義務。</li>
                        <li>適用法律所規定的時效。</li>
                        <li>（潛在）爭議。</li>
                        <li>相關資料保護主管機關頒布的指導原則。</li>
                    </ul>
                    <p>一旦不再需要，我們將會以適當方式安全清除您的資料。</p>
                    <p><strong>6. 您的權利</strong></p>
                    <p>依據適用的資料保護法，您可能就您的個人資料享有一些權利（受某些特定條件限制）。有關您權利的更多資訊與建議，您可向您所在國家的資料保護主管機關索取。您可透過下列聯絡資訊與我們聯絡，以請求行使這些權利。
                    </p>
                    <ul>
                        <li><strong>反對處理之權利</strong>。您可能有權反對某些特定類型的處理，包括直接行銷的處理（即接收我們的電子郵件，通知您我們認為您會感興趣的其他服務或聯絡不同的潛在機會）。
                        </li>
                        <li><strong>受到告知之權利</strong>。您可能有權獲得有關我們如何使用您的個人資料和您的權利，並且該等資訊應以清楚、透明及易於理解的方式提供給您。此即我們為何向您提供本政策之理由。
                        </li>
                        <li><strong>查閱之權利</strong>。您可能有權要求查閱我們所蒐集的您的特定個人資料和某些其他資料（類似於本政策提供的資訊）。</li>
                        <li><strong>更正之權利</strong>。如果您的資訊不正確或不完整，您可能有權要求更正。您可要求我們更正我們所持有您個人資料中的任何錯誤。</li>
                        <li><strong>刪除之權利</strong>。除某些例外情況外，您可以請求刪除或移除我們所持有您的資料。</li>
                        <li><strong>限制處理之權利</strong>。您可能有權「阻止」或禁止進一步使用您的資料。當處理根據這些權利而受到限制時，我們仍可儲存您的資訊，但不會進一步使用。我們保留限制進一步使用其個人資料的人員名單，以確保此限制處理在未來得到尊重。
                        </li>
                        <li><strong>資料可攜之權利</strong>。您可能有權基於自身目的，在不同的服務中取得及重複使用您的資料。例如，如果您決定轉換到新的供應商。然而，這不是一般性權利，有例外情況。</li>
                        <li><strong>申訴之權利</strong>。您可能有權就我們處理您資料的方式向您所在國家資料保護主管機關提出申訴。</li>
                        <li><strong>撤回同意之權利</strong>。如果處理您個人資料的基礎是您的同意，您有權隨時撤回同意（儘管若您這樣做，並不表示本公司我們之前基於您的同意對您的資料所為的任何行為是不合法的）。請注意，在某些情況下，您撤回對我們處理您個人資料的同意後，您可能無法繼續使用我們的服務。
                        </li>
                    </ul>
                    <p><strong>7. 資訊之選擇與變更</strong></p>
                    <p>我們的行銷電子郵件將會告知您如何「取消」接收日後的行銷電子郵件。若您取消接收行銷電子郵件，我們仍可向您發送非行銷電子郵件。非行銷電子郵件包括與您的帳號以及我們與您業務往來有關的電子郵件，並且可能包括請您參加問卷調查之要求，以瞭解您對服務的使用情形及滿意度。
                    </p>
                    <p>您得透過下方聯絡資訊，向我們發送有關您個人資料的請求。您可根據上述權利，要求變更您的聯絡方式或行銷選項，以及對您的個人資料或您在我們服務上發布的內容進行更新、存取、刪除或其他變更。除非法律要求，我們不一定會接受所有要求。
                    </p>
                    <p><strong>8. Cookies</strong></p>
                    <p>我們和第三方基於各種理由使用 cookies 和類似技術來蒐集數據。 Cookies 是小型文字檔案，對您的裝置或瀏覽器而言是獨特的，可以是 session-based
                        （自瀏覽器開始時起持續至瀏覽器關閉時自動刪除），也可以是 persistent （持續至刪除或到期）。</p>
                    <p>我們的 cookies 是第一方或第三方的 cookies ，這取決於放置cookies 的網站或網域而定。第一方 cookies 是我們所設置的並由我們接收透過 cookies
                        蒐集到的所有資料；第三方 cookies 是與我們有業務往來的第三方所設置。當您造訪我們的網站時，該第三方可能會在您的瀏覽器上放置獨特的 cookies
                        ，以蒐集有關您使用網站的數據，並可能蒐集其他您在不同時間段、不同網站及應用程式的線上活動。該第三方直接從您的網路瀏覽器蒐集數據，對此類數據的處理受其隱私權政策的拘束。</p>
                    <p>您可以造訪 Cookie Settings ，了解我們網站使用的 cookies 並管理這些 cookies 。</p>
                    <p><strong>如何控制</strong><strong> cookies</strong></p>
                    <p>啟用 cookies 並非網站運作所必要，但會為您帶給您更好的瀏覽體驗，並使我們及我們的第三方合作夥伴能確保我們顯示的內容、您在線上看到的廣告及行銷資訊更貼近您的興趣。您可以刪除或阻擋這些
                        cookies ，但如果您這樣做，您可能每次造訪我們網站時都必須手動調整某些偏好設定，而且網站的某些功能可能無法按預期方式運作。以下是您如何管理或刪除透過我們網站所提供的 cookies 的例子：
                    </p>
                    <ul>
                        <li>產業選擇停用網站。您可透過造訪下列網站，選擇停用我們許多第三方廣告合作夥伴基於廣告目的所提供的許多 cookies 使用：</li>
                    </ul>
                    <p><a href="https://optout.aboutads.info/?c=2#!/">https://optout.aboutads.info/?c=2#!/&nbsp;</a></p>
                    <p><a href="https://optout.networkadvertising.org/#!/">https://optout.networkadvertising.org/#!/</a></p>
                    <ul>
                        <li>瀏覽器設定。許多網路瀏覽器預設接受 cookies 。如您願意，您通常可以更改瀏覽器設定以拒絕或移除許多 cookies
                            。如果您使用不同的裝置查看及造訪我們的網站（例如您的電腦、智慧型手機，平板電腦等），您需要確保您在每台裝置上的每個瀏覽器都調整到您適合您的 cookies 偏好設定。<a
                                href="https://www.aboutcookies.org/how-to-control-cookies/">請點擊這裡以查看有關 cookies
                                的更多資訊及如何管理。</a></li>
                    </ul>
                    <p><strong>電子郵件追蹤</strong></p>
                    <p>我們也可能使用 cookies 或類似的電子郵件追蹤技術，以監測我們運營的電子郵件活動的成效（例如，記錄活動中所打開的電子郵件數量、閱讀該電子郵件的 IP
                        位址、瀏覽器或電子郵件收受的終端設備的類型）。如果您不希望我們追蹤我們發送給您的電子郵件，有些電子郵件服務可讓您更改您的顯示器以關閉 HTML
                        或禁用圖像下載，行使這些權利應可有效禁止我們的電子郵件追蹤。此外，您也可以按照這些電子郵件中的說明，取消訂閱我們的行銷電子郵件。</p>
                    <p><strong>請勿追蹤</strong></p>
                    <p>有些網路瀏覽器包括傳送「請勿追蹤」信號的功能。由於「請勿追蹤」信號的統一標準尚未被採用，因此我們目前不處理或回應「請勿追蹤」信號。如欲詳細瞭解「請勿追蹤」，請造訪「&nbsp;<a
                            href="https://allaboutdnt.com/">請勿追蹤所有相關介紹&nbsp;</a>」。除「請勿追蹤」外，網路瀏覽器信號與類似機制還有許多方式可指示您的追蹤選擇，而我們可能不清楚也不會遵循每一種機制。
                    </p>
                    <p><strong>9. 您個人資料之安全性</strong></p>
                    <p>我們努力透過採取適當的物理、技術及組織措施保護個人資料，以防止未經授權或非法存取、使用、處理或更改我們透過我們服務自您所取得的個人資料，並防止此類個人資料的洩露或丟失，如匿名化（anonymization）、擬匿名化（pseudonymization,）、加密（如在傳輸過程透過超文字安全傳輸通訊協定
                        (HTTPS) 加密）、限制存取等措施。儘管我們採取各種措施保護您的個人資料，但沒有任何系統或技術是完全安全。若您發現有影響我們服務的技術漏洞，請透過 <a
                            href="mailto:security@htc.com&nbsp;">security@htc.com </a>聯絡我們。若您對個人資料的保護或本政策有任何疑慮，請透過 <a
                            href="mailto:global-privacy@htc.com">global-privacy@htc.com</a> 聯絡我們。</p>
                    <p>對於任何獨立第三方服務的安全性及其蒐集您個人資料的行為，我們概不負責。請洽詢這些第三方以了解他們如何保護您的資料。</p>
                    <p><strong>10. 聯絡資訊</strong></p>
                    <p>我們歡迎您對本政策提供建議或問題。您可透過下列電子郵件地址或地址與我們聯絡：</p>
                    <p>電子郵件地址（隱私相關事項）：<a href="mailto:global-privacy@htc.com">global-privacy@htc.com</a></p>
                    <p>維特人股份有限公司&nbsp;UOMO VITRUVIANO CORPORATION</p>
                    <p>地址：中華民國台灣 231 新北市新店區中興路三段 88 號11樓</p>
                    <p>收件人:Privacy Administrator</p>
                    <p><strong>11. 本隱私權政策的變更</strong></p>
                    <p>我們將根據不斷變化的法律或操作要求，隨時更新本政策。如我們確實調整了內容，我們會在本網頁上公布本政策的最新版本，並指出最近更新日期。我們鼓勵您定期察看本政策，以了解我們隱私實踐的最新資訊。您在修正條款發布後繼續存取或使用我們的服務，即代表您接受並同意所有變更和本政策的最新版本。
                </p>

            </div>
        </div>
    </div>
</section></template>
<script>
import { getSomething } from '@/api/apiService';
export default {
    name: 'LegalPage',
    components: {
    },
    mounted() {

    },
    data() {
        return {

        };
    },
    props: {},
    computed: {},
    methods: {
        async fetchData() {
            try {
                const response = await getSomething();
                this.test = response;
            } catch (error) {
                // Handle error
            }
        },

    },
}
</script>
    
<style lang="scss"></style>
    