<template>
    <app-header></app-header>
    <div id="app">
        <div class="wrapper section member">
            <nav-aside></nav-aside>
            <router-view></router-view>
        </div>
    </div>
    <app-footer></app-footer>
</template>
<script>
import NavAside from '@/components/MemberCenter/Nav.vue';
import AppHeader from "@/components/header.vue";
import AppFooter from "@/components/footer.vue";


export default {
    name: 'MemberPage',
    components: {
        'nav-aside': NavAside,
        "app-header": AppHeader,
        "app-footer": AppFooter
    },
    mounted() {

    },
    data() {
        return {
            test: 1,
        };
    },
    props: {},
    computed: {},
    methods: {
    },
    watch: {},
}
</script>
    
<style lang="scss">
@import "../assets/styles/pages/MemberCenter/member.scss";
@import "../assets/styles/pages/MemberCenter/TicketSection.scss";
@import "../assets/styles/pages/MemberCenter/MyProps.scss";
@import "../assets/styles/pages/MemberCenter/SerialNumber.scss";
@import "../assets/styles/pages/MemberCenter/MemberInformation.scss";
@import "../assets/styles/pages/MemberCenter/AccountSetting.scss";
</style>
    