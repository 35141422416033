<template>
  <app-header></app-header>
  <div class="illusionary">
    <section class="section01 bg_setting">
      <div class="main_visual">
        <img
          src="../../assets/img/Event/illusionary/banner_character.png"
          alt=""
          class="banner_character"
        />
        <picture>
          <source
            media="(max-width: 576px)"
            srcset="../../assets/img/Event/illusionary/banner_logo_M.png"
          />
          <img
            src="../../assets/img/Event/illusionary/banner_logo.png"
            alt=""
            class="banner_logo"
          />
        </picture>
      </div>
      <div class="link_btn">
        <router-link to="/buy-tickets" target="_blank">
          <picture>
            <source
              media="(max-width: 576px)"
              srcset="../../assets/img/Event/illusionary/Btn_Ticket_M.png"
            />
            <img
              src="../../assets/img/Event/illusionary/Btn_Ticket.png"
              alt="BuyTicket"
              class="BuyTicket"
            />
          </picture>
        </router-link>
        <router-link to="/download" target="_blank">
          <picture>
            <source
              media="(max-width: 576px)"
              srcset="../../assets/img/Event/illusionary/Btn_Download_M.png"
            />
            <img
              src="../../assets/img/Event/illusionary/Btn_Download.png"
              alt="Download"
              class="Download"
            />
          </picture>
        </router-link>
      </div>
    </section>
    <section class="section02 bg_setting">
      <img
        src="../../assets/img/Event/illusionary/section02_Title.png"
        alt=""
        class="title"
        loading="lazy"
        data-aos="fade-up"
      />
      <div class="video">
        <iframe
          src="https://www.youtube.com/embed/wD1y_clgXc8?si=W7b57jjdxwxLLNQG"
          title="YouTube video player"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowfullscreen
        ></iframe>
      </div>
    </section>
    <section class="section03 bg_setting">
      <img
        src="../../assets/img/Event/illusionary/Ribbon_Left.png"
        alt=""
        class="Ribbon Ribbon_L"
        loading="lazy"
      />
      <img
        src="../../assets/img/Event/illusionary/Ribbon_Right.png"
        alt=""
        class="Ribbon Ribbon_R"
        loading="lazy"
      />
      <img
        src="../../assets/img/Event/illusionary/section03_Title.png"
        alt=""
        class="title"
        loading="lazy"
      />
      <p>
        わくわく!跟你心愛的推一起重啟《鏡花水月 - 升級版 最後的幻歌祭》，揮灑音樂魔法吧 !
        <br />
        15號、露恰露恰、歐貝爾、厄倫蒂兒、涅默,首次登陸
        BEATDAY·將成為真實的存在，近距離自有視角追星吧 !
        <br />
        一起跟你們的推，揮灑音樂魔法 ! 夥伴們準備好嗎 ?
        讓我們一起進入充滿萌力的二次元元宇宙。
      </p>
      <div
        class="carousel"
        @touchstart="touchStart"
        @touchmove="touchMove"
        @touchend="touchEnd"
      >
        <img
          src="../../assets/img/Event/illusionary/Stage_photo12.png"
          alt=""
          class="carousel_img unset_img"
          loading="lazy"
        />
        <img
          src="../../assets/img/Event/illusionary/Stage_photo12.png"
          alt=""
          class="bg_img01"
          loading="lazy"
        />
        <img
          src="../../assets/img/Event/illusionary/Stage_photo11.png"
          alt=""
          class="bg_img02"
          loading="lazy"
        />
        <img
          :src="currentImageSrc"
          alt="Carousel Image"
          class="carousel_img"
          loading="lazy"
        />
        <div class="controls">
          <button @click="prevImage01" class="prev">&#10094;</button>
          <span>{{ currentIndex + 1 }}/{{ stage_photo.length }}</span>
          <button @click="nextImage01" class="next">&#10095;</button>
        </div>
      </div>
    </section>
    <section class="section04 bg_setting">
      <img
        src="../../assets/img/Event/illusionary/section04_Title.png"
        alt=""
        class="title"
        loading="lazy"
        data-aos="fade-right"
      />
      <div class="character">
        <div
          v-for="(character, index) in characters"
          :key="index"
          :class="'character_item character_0' + (index + 1)"
          @click="showImage(index)"
        >
          <img
            :src="isMobile ? character.MBimageSrc : character.imageSrc"
            alt=""
            loading="lazy"
            data-aos="fade-left"
          />
        </div>
        <div v-if="showImageModal" class="character_card_box">
          <div class="character_card">
            <img
              :src="isMobile ? selectedCharacter.MBcardSrc : selectedCharacter.cardSrc"
              alt=""
              class="character_card"
              loading="lazy"
            />
            <button @click="prevImage" class="prev"></button>
            <button @click="nextImage" class="next"></button>
            <img
              src="../../assets/img/Event/illusionary/leaveIcon.png"
              alt="Leave"
              class="leave-icon"
              @click="closeImageModal"
            />
          </div>
        </div>
      </div>
    </section>
    <section class="section03-1">
      <picture>
        <source
          media="(max-width: 576px)"
          srcset="../../assets/img/Event/illusionary/main_Visual_MB.jpg"
        />
        <img src="../../assets/img/Event/illusionary/main_Visual.jpg" alt="" />
      </picture>
    </section>
    <section class="section05 bg_setting">
      <img
        src="../../assets/img/Event/illusionary/section05_Title.png"
        alt=""
        class="title"
        loading="lazy"
        data-aos="fade-up"
      />
      <p>新升級表演，如夢交織的春祭盛典，這次注定要萌爆你 !</p>
      <div class="PC_Street">
        <div class="street_box">
          <div class="street_line_01">
            <div
              class="street_item"
              @click="showVideo('streetVideo_01.mp4', 'street_text_01.png')"
            >
              <img
                src="../../assets/img/Event/illusionary/street_text_01.png"
                alt=""
                class="street_text"
                loading="lazy"
              />
              <div class="street_img">
                <img src="../../assets/img/Event/illusionary/street_01.png" alt="" />
              </div>
            </div>
            <div
              class="street_item"
              @click="showVideo('streetVideo_03.mp4', 'street_text_03.png')"
            >
              <img
                src="../../assets/img/Event/illusionary/street_text_03.png"
                alt=""
                class="street_text"
                loading="lazy"
              />
              <div class="street_img">
                <img
                  src="../../assets/img/Event/illusionary/street_03.png"
                  alt=""
                  loading="lazy"
                />
              </div>
            </div>
            <div
              class="street_item"
              @click="showVideo('streetVideo_05.mp4', 'street_text_05.png')"
            >
              <img
                src="../../assets/img/Event/illusionary/street_text_05.png"
                alt=""
                class="street_text"
                loading="lazy"
              />
              <div class="street_img">
                <img src="../../assets/img/Event/illusionary/street_05.png" alt="" />
              </div>
            </div>
          </div>
          <div class="street_line_02">
            <div
              class="street_item"
              @click="showVideo('streetVideo_02.mp4', 'street_text_02.png')"
            >
              <img
                src="../../assets/img/Event/illusionary/street_text_02.png"
                alt=""
                class="street_text"
                loading="lazy"
              />
              <div class="street_img">
                <img src="../../assets/img/Event/illusionary/street_02.png" alt="" />
              </div>
            </div>
            <div
              class="street_item"
              @click="showVideo('streetVideo_04.mp4', 'street_text_04.png')"
            >
              <img
                src="../../assets/img/Event/illusionary/street_text_04.png"
                alt=""
                class="street_text"
                loading="lazy"
              />
              <div class="street_img">
                <img src="../../assets/img/Event/illusionary/street_04.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div v-if="showStreetVideo" class="street_video">
          <div class="video_mask"></div>
          <div class="video">
            <img :src="videoTitle" alt="" class="street_video_title" loading="lazy" />
            <video controls autoplay loop>
              <source :src="videoSrc" type="video/mp4" />
              Your browser does not support the video tag.
              <!-- <source src="../../assets/img/Event/illusionary/streetVideo_01.mp4"> -->
            </video>
            <img
              src="../../assets/img/Event/illusionary/leaveIcon.png"
              alt="Leave"
              class="leave-icon"
              @click="closeVideo"
              loading="lazy"
            />
          </div>
        </div>
      </div>
      <div class="swiper-container MB_Street">
        <img
          src="../../assets/img/Event/illusionary/arrow_double.png"
          alt=""
          class="arrow arrow_L"
        />
        <img src="../../assets/img/Event/illusionary/swipe.png" alt="" class="swipe" />
        <img
          src="../../assets/img/Event/illusionary/arrow_double.png"
          alt=""
          class="arrow arrow_R"
        />
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(item, index) in carouselItems" :key="index">
            <div class="box">
              <img :src="item.title" alt="" />
              <video :src="item.videoSrc" controls autoplay loop></video>
            </div>
          </div>
        </div>
        <!-- 导航条 -->
        <div class="swiper-pagination"></div>
      </div>
    </section>
    <section class="section06 bg_setting" ref="section06">
      <img
        src="../../assets/img/Event/illusionary/section06_Title.png"
        alt=""
        class="title"
        loading="lazy"
        data-aos="fade-up"
      />
      <div class="att_box">
        <div class="att">
          <h3>電腦規格需求</h3>
          <div class="text">
            <table class="PC_table">
              <thead>
                <tr>
                  <th>
                    <h3>Windows 系統</h3>
                  </th>
                  <th>
                    <h3>MAC</h3>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <ul role="list">
                      <li>CPU：Intel core i7 以上</li>
                      <li>RAM：8GB 以上</li>
                      <li>
                        顯卡：畫質優先版本 NVIDIA GeForce GTX 1060以上，效能優先版本
                        NVIDIA GeForce GTX 1030 以上
                      </li>
                      <li>安裝空間: 硬碟空間需至少20GB以上</li>
                      <li>建議網速: 50 Mbps</li>
                    </ul>
                  </td>
                  <td>
                    <ul role="list">
                      <li>2018 年後之任何機種</li>
                      <li>軟體：OS.10.14以上</li>
                      <li>安裝空間:&nbsp;硬碟空間需至少20GB以上</li>
                      <li>建議網速: 50 Mbps</li>
                    </ul>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td colspan="3">
                    <h3>安裝注意事項</h3>
                    <ul role="list">
                      <li>
                        若電腦規格低於以上規格，則會出現卡頓、延遲，甚至無法觀看表演的情況
                      </li>
                      <li>
                        在體驗BEATDAY過程中請勿同時開啟其他影音軟體、遊戲、錄影等，以免影響體驗品質
                      </li>
                      <li>
                        BEATDAY程式需安裝於英文命名的資料夾下，若安裝於中文命名的資料夾將導致安裝不完整
                      </li>
                    </ul>
                  </td>
                </tr>
              </tfoot>
            </table>
            <div class="MB_table">
              <table>
                <thead>
                  <tr>
                    <th>
                      <h3>Windows 系統</h3>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <ul role="list">
                        <li>CPU：Intel core i7 以上</li>
                        <li>RAM：8GB 以上</li>
                        <li>
                          顯卡：畫質優先版本 NVIDIA GeForce GTX 1060以上，效能優先版本
                          NVIDIA GeForce GTX 1030 以上
                        </li>
                        <li>安裝空間: 硬碟空間需至少20GB以上</li>
                        <li>建議網速: 50 Mbps</li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table>
                <thead>
                  <tr>
                    <th>
                      <h3>MAC</h3>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>
                      <ul role="list">
                        <li>2018 年後之任何機種</li>
                        <li>軟體：OS.10.14以上</li>
                        <li>安裝空間:&nbsp;硬碟空間需至少20GB以上</li>
                        <li>建議網速: 50 Mbps</li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table>
                <tfoot>
                  <tr>
                    <td colspan="3">
                      <h3>安裝注意事項</h3>
                      <ul role="list">
                        <li>
                          若電腦規格低於以上規格，則會出現卡頓、延遲，甚至無法觀看表演的情況
                        </li>
                        <li>
                          在體驗BEATDAY過程中請勿同時開啟其他影音軟體、遊戲、錄影等，以免影響體驗品質
                        </li>
                        <li>
                          BEATDAY程式需安裝於英文命名的資料夾下，若安裝於中文命名的資料夾將導致安裝不完整
                        </li>
                      </ul>
                    </td>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
        <div class="att">
          <h3>觀看注意事項</h3>
          <div class="text">
            <ul role="list">
              <li>
                已購買2023年10月鏡花水月演唱會門票，且尚未辦理退票的粉絲，可沿用舊序號，自由觀看兩場演出，不需重新購票。
              </li>
              <li>
                為了讓您能盡情享受本次演唱會的精彩內容，購票前請務必前往官網，確認電腦硬體規格需求與注意事項。購票後您將會獲得一組取票序號，請務必於開演前進入
                BEATDAY 程式內兌換。
              </li>
              <li>
                4/8 (一) 中午 12:00 起，可於 BEATDAY
                平台內兌換，程式安裝與序號兌換流程請參考：
                <a href="https://beatday.com/#/instructions" target="_blank">
                  https://beatday.com/#/instructions
                </a>
              </li>

              <li>序號兌換後即綁定該 BEATDAY 帳號，無法轉移或贈與至其他帳號</li>
              <li>
                參與電腦規格與網速需高於建議規格，若低於規格觀影體驗將受影響，甚至無法觀看表演
              </li>
              <li>
                請至少於開演前一天下載平台程式與資源包，並登入熟悉操作與測試電腦規格
              </li>
              <li>
                開演前 30
                分鐘務必提早登入平台等候表演開始，請注意若晚於表演預定開演時間進入，則可能無法看到完整表演
              </li>
              <li>
                演唱會表演將根據整體連線狀況隨機配置伺服器。因此，一起購票、索票或登入的玩家，並不一定會同時出現在相同遊玩空間。
              </li>
              <li>途中觀眾有可能因為本身的網速、硬體錯誤…等因素影響觀影體驗，敬請見諒</li>
              <li>表演途中斷線可再次登入，但可能會錯過部分表演</li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  </div>
  <app-footer></app-footer>
</template>

<script>
import AppHeader from "@/components/header.vue";
import AppFooter from "@/components/footer.vue";
import Swiper from "swiper";
import "swiper/swiper-bundle.css";
import AOS from "aos";
import "aos/dist/aos.css";

export default {
  name: "EventIllusionary",
  components: {
    "app-header": AppHeader,
    "app-footer": AppFooter,
  },
  props: {},
  data() {
    return {
      isMobile: false,
      //劇照輪播
      stage_photo: [
        require("../../assets/img/Event/illusionary/Stage_photo1.png"),
        require("../../assets/img/Event/illusionary/Stage_photo2.png"),
        require("../../assets/img/Event/illusionary/Stage_photo3.png"),
        require("../../assets/img/Event/illusionary/Stage_photo4.png"),
        require("../../assets/img/Event/illusionary/Stage_photo5.png"),
        require("../../assets/img/Event/illusionary/Stage_photo6.png"),
        require("../../assets/img/Event/illusionary/Stage_photo7.png"),
        require("../../assets/img/Event/illusionary/Stage_photo8.png"),
        require("../../assets/img/Event/illusionary/Stage_photo9.png"),
        require("../../assets/img/Event/illusionary/Stage_photo10.png"),
      ],
      currentIndex: 0,
      //角色輪播
      characters: [
        {
          imageSrc: require("../../assets/img/Event/illusionary/character_01.png"),
          cardSrc: require("../../assets/img/Event/illusionary/character_card_01.png"),
          MBimageSrc: require("../../assets/img/Event/illusionary/character_card_M_01.png"),
          MBcardSrc: require("../../assets/img/Event/illusionary/character_card_M_01_Text.png"),
        },
        {
          imageSrc: require("../../assets/img/Event/illusionary/character_02.png"),
          cardSrc: require("../../assets/img/Event/illusionary/character_card_02.png"),
          MBimageSrc: require("../../assets/img/Event/illusionary/character_card_M_02.png"),
          MBcardSrc: require("../../assets/img/Event/illusionary/character_card_M_02_Text.png"),
        },
        {
          imageSrc: require("../../assets/img/Event/illusionary/character_03.png"),
          cardSrc: require("../../assets/img/Event/illusionary/character_card_03.png"),
          MBimageSrc: require("../../assets/img/Event/illusionary/character_card_M_03.png"),
          MBcardSrc: require("../../assets/img/Event/illusionary/character_card_M_03_Text.png"),
        },
        {
          imageSrc: require("../../assets/img/Event/illusionary/character_04.png"),
          cardSrc: require("../../assets/img/Event/illusionary/character_card_04.png"),
          MBimageSrc: require("../../assets/img/Event/illusionary/character_card_M_04.png"),
          MBcardSrc: require("../../assets/img/Event/illusionary/character_card_M_04_Text.png"),
        },
        {
          imageSrc: require("../../assets/img/Event/illusionary/character_05.png"),
          cardSrc: require("../../assets/img/Event/illusionary/character_card_05.png"),
          MBimageSrc: require("../../assets/img/Event/illusionary/character_card_M_05.png"),
          MBcardSrc: require("../../assets/img/Event/illusionary/character_card_M_05_Text.png"),
        },
      ],
      showImageModal: false,
      selectedCharacterIndex: 0,
      selectedCharacter: 1,
      leaveIconSrc: require("../../assets/img/Event/illusionary/leaveIcon.png"),
      //大街
      showStreetVideo: false,
      videoSrc: "",
      carouselItems: [
        {
          title: require("../../assets/img/Event/illusionary/street_text_01.png"),
          videoSrc: require("../../assets/img/Event/illusionary/streetVideo_01.mp4"),
        },
        {
          title: require("../../assets/img/Event/illusionary/street_text_02.png"),
          videoSrc: require("../../assets/img/Event/illusionary/streetVideo_02.mp4"),
        },
        {
          title: require("../../assets/img/Event/illusionary/street_text_03.png"),
          videoSrc: require("../../assets/img/Event/illusionary/streetVideo_03.mp4"),
        },
        {
          title: require("../../assets/img/Event/illusionary/street_text_04.png"),
          videoSrc: require("../../assets/img/Event/illusionary/streetVideo_04.mp4"),
        },
        {
          title: require("../../assets/img/Event/illusionary/street_text_05.png"),
          videoSrc: require("../../assets/img/Event/illusionary/streetVideo_05.mp4"),
        },
      ],
    };
  },
  computed: {
    currentImageSrc() {
      return this.stage_photo[this.currentIndex];
    },
  },
  mounted() {
    setInterval(this.startCarousel(), 2000);

    // 监听窗口大小变化
    window.addEventListener("resize", this.checkIsMobile);
    // 初始检查窗口大小
    this.checkIsMobile();
    this.swiper = new Swiper(".swiper-container", {
      direction: "horizontal",
      loop: true,
      pagination: {
        el: ".swiper-pagination",
        type: "bullets",
        clickable: true,
      },
    });
  },
  methods: {
    aos() {
      if (this.isMobile) {
        console.log("關閉AOS");
        AOS.init({
          disable: true,
        });
      } else {
        console.log("執行AOS");
        AOS.init();
      }
    },
    //視窗大小
    checkIsMobile() {
      this.isMobile = window.innerWidth <= 991;
      this.aos();
    },
    //劇照輪播
    startCarousel() {
      this.intervalId = setInterval(this.nextImage01, 3000);
    },
    stopCarousel() {
      clearInterval(this.intervalId);
    },
    nextImage01() {
      this.currentIndex = (this.currentIndex + 1) % this.stage_photo.length;
      this.restartCarousel();
    },
    prevImage01() {
      this.currentIndex =
        (this.currentIndex - 1 + this.stage_photo.length) % this.stage_photo.length;
      this.restartCarousel();
    },
    restartCarousel() {
      this.stopCarousel(); // 停止现有的定时器
      this.startCarousel(); // 重新启动定时器
    },
    //角色輪播
    showImage(index) {
      this.selectedCharacterIndex = index;
      this.selectedCharacter = this.characters[index];
      this.showImageModal = true;
    },
    closeImageModal() {
      this.showImageModal = false;
    },
    prevImage() {
      this.selectedCharacterIndex =
        (this.selectedCharacterIndex - 1 + this.characters.length) %
        this.characters.length;
      this.selectedCharacter = this.characters[this.selectedCharacterIndex];
    },
    nextImage() {
      this.selectedCharacterIndex =
        (this.selectedCharacterIndex + 1) % this.characters.length;
      this.selectedCharacter = this.characters[this.selectedCharacterIndex];
    },
    touchStart(event) {
      this.startX = event.touches[0].clientX;
      console.log(this.startX);
    },
    touchMove(event) {
      this.endX = event.touches[0].clientX;
      console.log(this.endX);
    },
    touchEnd() {
      const threshold = 100; // Minimum swipe distance to trigger navigation

      const deltaX = this.endX - this.startX;
      if (deltaX > threshold) {
        console.log("pre");
        this.prevImage();
      } else if (deltaX < -threshold) {
        console.log("next");
        this.nextImage();
      }
    },
    //大街
    showVideo(videoName, videoTitle) {
      this.videoTitle = require(`../../assets/img/Event/illusionary/${videoTitle}`);
      this.videoSrc = require(`../../assets/img/Event/illusionary/${videoName}`);
      this.showStreetVideo = true;
    },
    closeVideo() {
      this.videoSrc = "";
      this.showStreetVideo = false;
    },
  },
  // destroyed() {
  //     // 在组件销毁时销毁 Swiper 实例
  //     if (this.swiper) {
  //         this.swiper.destroy();
  //         this.swiper = null;
  //     }
  // }
};
</script>

<style lang="scss">
@import "../../assets/styles/pages/Event/illusionary.scss";
</style>
