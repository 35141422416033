<template>
    <div class="forgot-password">
        <h1 class="title" v-if="titleClose">忘記密碼</h1>
        <form @submit.prevent="forgot" v-if="showForgot">
            <div class="username">
                <i class="bi bi-person-fill"></i>
                <input v-model="usernameF" type="text" id="username" name="username" placeholder="電子信箱">
            </div>
            <div class="alert">{{ alertF }}</div>
            <button type="submit" class="login_btn">取得驗證碼</button>

            <div class="link">
                <router-link to="/member-login/login">返回登入</router-link>
            </div>
        </form>

        <verify-number :username="usernameF" :reason="reasonF" v-if="showVerifyNumber"
            @verification-success="handleVerificationSuccess" @resetJwt="resetJwtHandler"></verify-number>

        <form @submit.prevent="passwordReset" v-if="showPasswordReset">

            <div class="password">
                <i class="bi bi-key-fill"></i>
                <input v-model="passwordF" :type="passwordVisible ? 'text' : 'password'" id="password" name="password"
                    placeholder="密碼">
                <div class="eye_btn" @click="togglePasswordVisibility"><i class="bi"
                        :class="passwordVisible ? 'bi-eye-fill' : 'bi-eye'"></i></div>
            </div>

            <div class="password">
                <i class="bi bi-key-fill"></i>
                <input v-model="passwordCheck" :type="passwordVisible ? 'text' : 'password'" id="passwordCheck"
                    name="password" placeholder="再次輸入密碼">
                <div class="eye_btn" @click="togglePasswordVisibility"><i class="bi"
                        :class="passwordVisible ? 'bi-eye-fill' : 'bi-eye'"></i></div>
            </div>

            <div class="alert">{{ alertF }}</div>
            <button type="submit" class="login_btn">重設密碼</button>

        </form>

        <div v-if="resetSuccess" class="reset-success">
            <h1 class="title">重設成功</h1>
            <dotlottie-player id="check-box" src="https://lottie.host/d525671a-5c4b-4694-a264-2d8554f45e93/mSj3CZexHR.json"
                background="transparent" speed="1" autoplay=""></dotlottie-player>
            <p>將在 {{ countdown }} 秒後自動跳轉</p>
            <router-link to="/member-login/login">
                <button class="login_btn">返回登入</button>
            </router-link>
        </div>
    </div>
</template>
  
<script>
import VerifyNumber from '@/components/Login/Verify.vue';
import { sendVerificationCode, changePsw } from '@/api/apiService';

export default {
    name: 'ForgotPassword',
    props: {

    },
    components: {
        'verify-number': VerifyNumber,
    },
    data() {
        return {
            jwt: '',
            reasonF: "changePasswordVerify",
            usernameF: '',
            passwordF: '',
            passwordCheck: '',
            passwordVisible: false,
            alertF: '',
            showForgot: true,
            showVerifyNumber: false,
            showPasswordReset: false,
            resetSuccess: false,
            countdown: 5,
            titleClose: true,
        };
    },
    mounted() {
        if (this.resetSuccess) {
            this.playAnimation();
        }
    },
    methods: {
        async forgot() {
            // 1. 檢查帳號格式
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(this.usernameF)) {
                this.alertF = "＊ 請輸入有效電子信箱";
                return;
            }

            // 2. 檢查特殊字符
            const disallowedCharsRegex = /['"<>\\;=()\s]/;
            if (disallowedCharsRegex.test(this.usernameF)) {
                this.alertF = "＊ 帳號中禁止包含特殊字元";
                return;
            }
            else {
                // 清空錯誤訊息
                try {
                    const data = {
                        request: 'sendVerifyCode',
                        account: this.usernameF,
                        reason: "changePasswordVerify"
                    }
                    this.loading = true;
                    await sendVerificationCode(data);
                    this.loading = false;
                    this.alertF = "";
                    this.showForgot = false;
                    this.showVerifyNumber = true;
                } catch (error) {
                    this.loading = false;
                    this.alertF = "此電子信箱尚未註冊，請重新確認";
                }
            }
        },
        resetJwtHandler(jwt) {
            this.jwt = jwt;
        },
        async passwordReset() {
            // 1. 檢查密碼是否符合長度8-16位
            const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z]).{8,16}$/;
            if (!passwordRegex.test(this.passwordF)) {
                this.alertF = "＊ 請輸入 8 - 16 位數密碼，且包含大小寫";
                return;
            }

            // 2. 檢查兩次輸入的密碼是否一致
            if (this.passwordF !== this.passwordCheck) {
                this.alertF = "＊ 密碼輸入錯誤，請重新確認";
                return;
            }

            else {

                const data = {
                    request: 'changePassword',
                    jwt: this.jwt,
                    username: this.usernameF,
                    password: this.passwordF
                }
                this.loading = true;
                const response = await changePsw(data);
                this.loading = false;
                if (response.status === 200) {
                    this.titleClose = false;
                    this.showPasswordReset = false;
                    this.resetSuccess = true;
                    this.alertF = ""; // 清空錯誤訊息
                    this.$nextTick(() => {
                        // 使用 document.querySelector 來選擇元素，而不是直接使用 jQuery
                        const checkBox = document.querySelector("#check-box");
                        if (checkBox) {
                            // 檢查元素是否存在，然後播放動畫
                            setInterval(() => {
                                checkBox.play();
                            }, 0);
                        }
                    });
                    const countdownInterval = setInterval(() => {
                        if (this.countdown > 0) {
                            this.countdown--;
                        } else {
                            clearInterval(countdownInterval);
                            // Redirect to login page
                            this.$router.push('/member-login/login');
                        }
                    }, 1000);
                } else {
                    this.alertF = "密碼重設失敗，請重新送出。";
                }

            }
        },
        handleVerificationSuccess(success) {
            if (success) {
                this.alertR = "";
                this.showVerifyNumber = false;
                this.showPasswordReset = true;

            } else {
                this.alertR = "驗證碼錯誤，請重新輸入";
            }
        },
        togglePasswordVisibility() {
            // Toggle the visibility of the password
            this.passwordVisible = !this.passwordVisible;
        },
        playAnimation() {
            // 使用 $nextTick 以確保元素已經渲染到DOM中
            this.$nextTick(() => {
                // 使用 document.querySelector 來選擇元素，而不是直接使用 jQuery
                const checkBox = document.querySelector("#check-box");
                if (checkBox) {
                    // 檢查元素是否存在，然後播放動畫
                    setInterval(() => {
                        checkBox.play();
                    }, 0);
                }
            });
        },

    }
}
</script>
  

<style lang="scss"></style>
  