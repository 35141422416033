<template>
    <section class="reset-password">
        <h1>帳號管理</h1>
        <div class="filter-section">
            <router-link to="/member/member-information"><button class="filter-button ">會員資料</button></router-link>
            <router-link to=""><button class="filter-button active ">重設密碼</button></router-link>
        </div>
        <form class="reset_box" v-if="showReset" @submit.prevent="reset">
            <!-- <dotlottie-player id="test-anim" autoplay mode="bounce" loop
                src="https://storage.googleapis.com/aarsteinmedia/am.lottie" style="width: 320px; margin: auto;"
                subframe="">
            </dotlottie-player> -->


            <label class="">
                <span class="title">目前密碼</span>
                <div class="eye_fixed">
                    <input :type="passwordVisibleCurrent ? 'text' : 'password'" v-model="password" placeholder="請輸入目前密碼"
                        name="password" />
                    <div class="eye_btn" @click="togglePasswordVisibility('current')"><i class="bi"
                            :class="passwordVisibleCurrent ? 'bi-eye-fill' : 'bi-eye'"></i></div>
                </div>
            </label>

            <label class="">
                <span class="title">新密碼</span>
                <div class="eye_fixed">
                    <input :type="passwordVisibleNew ? 'text' : 'password'" v-model="passwordNew" placeholder="請輸入新密碼"
                        name="passwordNew" />
                    <div class="eye_btn" @click="togglePasswordVisibility('new')"><i class="bi"
                            :class="passwordVisibleNew ? 'bi-eye-fill' : 'bi-eye'"></i></div>
                </div>
            </label>

            <label class="">
                <span class="title">確認新密碼</span>
                <div class="eye_fixed">
                    <input :type="passwordVisibleNew ? 'text' : 'password'" v-model="passwordNewCheck"
                        placeholder="請再次輸入新密碼" name="passwordNewCheck" />
                    <div class="eye_btn" @click="togglePasswordVisibility('new')"><i class="bi"
                            :class="passwordVisibleNew ? 'bi-eye-fill' : 'bi-eye'"></i></div>
                </div>
            </label>
            <div class="alert">{{ alert }}</div>
            <button type="submit" class="submit-button">重設密碼</button>

        </form>

        <div v-if="resetSuccess" class="reset-success">
            <div class="flex">
                <h2 class="title">重設密碼成功</h2>
                <dotlottie-player id="check-box"
                    src="https://lottie.host/d525671a-5c4b-4694-a264-2d8554f45e93/mSj3CZexHR.json" background="transparent"
                    speed="1" autoplay=""></dotlottie-player>
            </div>
            <p>將在 {{ countdown }} 秒後自動跳轉至 BEATDAY 首頁</p>
        </div>
    </section>
    <!-- <loading-icon></loading-icon> -->
</template>



<script>
// import LoadingIcon from '@/components/Loading.vue';
export default {
    name: 'ResetPassword',
    data() {
        return {
            password: '',
            passwordNew: '',
            passwordNewCheck: '',
            alert: '',
            passwordVisibleCurrent: false,
            passwordVisibleNew: false,
            showReset: true,
            resetSuccess: false,
            countdown: 5,
        };
    },
    // components: {
    //     'loading-icon': LoadingIcon,
    // },
    mounted() {
        // 確認當 resetSuccess 為 true 時才執行播放
        if (this.resetSuccess) {
            this.playAnimation();
        }
    },
    methods: {
        reset() {
            // $("#check-box")[0].play();
            if (this.password === '') {
                this.alert = "＊ 請輸入密碼，重設新密碼";
                return;
            }
            // 1. 檢查密碼是否符合長度8-16位
            const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z]).{8,16}$/;
            if (!passwordRegex.test(this.passwordNew)) {
                this.alert = "＊ 請輸入 8 - 16 位數新密碼，且包含大小寫";
                return;
            }

            // 2. 檢查兩次輸入的密碼是否一致
            if (this.passwordNew !== this.passwordNewCheck) {
                this.alert = "＊ 新密碼和確認新密碼不一致。請重新輸入";
                return;
            }

            else {
                this.showReset = false;
                this.resetSuccess = true;
                this.alert = ""; // 清空錯誤訊息
                // 在這裡添加提交註冊表單的相關邏輯，例如使用axios發送POST請求到伺服器

                const countdownInterval = setInterval(() => {
                    if (this.countdown > 0) {
                        this.countdown--;
                    } else {
                        clearInterval(countdownInterval);
                        this.$router.push('/');
                    }
                }, 1000);
            }
        },
        playAnimation() {
            // 使用 $nextTick 以確保元素已經渲染到DOM中
            this.$nextTick(() => {
                // 使用 document.querySelector 來選擇元素，而不是直接使用 jQuery
                const checkBox = document.querySelector("#check-box");
                if (checkBox) {
                    // 檢查元素是否存在，然後播放動畫
                    setInterval(() => {
                        checkBox.play();
                    }, 0);
                }
            });
        },
        togglePasswordVisibility(field) {
            if (field === 'current') {
                this.passwordVisibleCurrent = !this.passwordVisibleCurrent;
            } else if (field === 'new') {
                this.passwordVisibleNew = !this.passwordVisibleNew;
            }
        },
    },
    watch: {
        // 監視 resetSuccess 的變化，以便在其值改變時播放動畫
        resetSuccess(newValue) {
            if (newValue) {
                this.playAnimation();
            }
        }
    }
};
</script>


