<template>
    <section class="user_policy">
        <div class="wpb_wrapper">
            <h1><strong>【BEATDAY】使用者服務條款</strong></h1>
            <p>最近更新：2023年11月22日</p>
            <p>感謝您使用【BEATDAY】。請仔細閱讀BEATDAY使用者服務條款（下稱「本服務條款」），以了解您的權利和責任。</p>
            <p>&nbsp;</p>
            <p>透過使用BEATDAY之服務，您同意接受本服務條款的約束。若您不同意本服務條款的任何內容，請勿使用BEATDAY服務。當您使用BEATDAY服務或同意本服務條款時，代表您聲明並且擔保您已達到您居住/所在國家或地區適用法律規定的法定年齡，有能力受到本服務條款的約束。
            </p>
            <p>本服務條款以及您在使用BEATDAY時所提供給您的任何其他條款（下稱「附加條款」，除個別條款另有清楚說明以外，本服務條款的意義均涵蓋附加條款，下同），構成您（個人或實體）與維特人股份有限公司(下稱「維特人公司」)或在您居住/所在地的維特人公司關係企業（下合稱「維特人」）間的法律協議，用於規範您對BEATDAY的使用行為。您了解本服務條款可能不定期修改，您應於每次使用BEATDAY服務或本服務條款有修改時，查看本服務條款，俾以隨時了解任何與您權利及義務有關的條款變更。若本服務條款與附加條款牴觸，悉以附加條款中的條款為主。
            </p>
            <p>&nbsp;</p>
            <p>如果您是企業用戶，您對BEATDAY之使用受您公司與BEATDAY或維特人之間的服務協議的約束。</p>
            <p>&nbsp;</p>
            <p><strong>本服務條款如下：</strong></p>
            <p><strong>第 1 條&nbsp; 本服務</strong></p>
            <p>BEATDAY是由維特人開發和營運，提供使用者於真實世界中擴增實境的行動體驗服務，包括BEATDAY之網站、應用程式、平台、論壇、裝置、產品、相關媒體、印刷或電子文件、內容、工具和功能、所有更新和支援（如有）與服務及整合性服務（下稱「本服務」）。
            </p>
            <p><strong>第 2 條&nbsp; 未成年人</strong></p>
            <p>本服務不直接對未成年人提供。如您的年齡未達您居住/所在國家或地區的法定成年年齡，您必須與您的父母或法定代理人(下合稱「法定代理人」)一同審閱本服務條款，共同同意本服務條款，並在您的法定代理人的許可下使用本服務，例如以帳號購買本服務內的虛擬商品/服務（包括數位內容、虛擬道具或虛擬貨幣等，下合稱「虛擬商品/服務」），以及進行其他一切的交易活動。<br>
                在適用法律允許的範圍內，如維特人事後發現您屬於未成年人且未經法定代理人的許可下使用本服務者，維特人有權隨時拒絕您使用本服務，並且終止本服務條款。</p>
            <p><strong>第 3 條&nbsp; 系統需求</strong></p>
            <p>為了在使用本服務時享有良好體驗，您需要一台符合BEATDAY系統、相容性要求及其他系統使用需求的電腦或行動裝置，以及合適速度的網際網路連線服務(下合稱「系統需求」)。您了解系統需求可能隨時改變，且系統需求因素可能會影響您使用本服務的體驗和本服務的性能，如果因為您使用的系統需求不符合本服務的標準，導致您的損失(包括但不限於本服務電磁紀錄遺失)，您應自行負責。此外，不論何種原因，您應就這些系統需求自行負責相關費用。
            </p>
            <p><strong>第 4 條&nbsp; 帳號</strong></p>
            <p>1.
                為存取及使用本服務之某些功能，您必須註冊一組帳號。您應提供完整的、準確的、現時有效的帳戶資訊，並保護好您的登錄憑據，不要授權或與任何其它第三方分享使用。您同意維特人將認定所有關於您提供的帳號及帳號使用都是您自身所為，您必須就以您的帳號所進行的一切活動負責，包括透過您的帳號購買本服務的虛擬商品/服務，以及進行其他一切的交易活動。<br>
                2.
                您所使用帳號的密碼可依維特人提供的機制進行變更。非因可歸責於您的事由而致本服務條款終止者，您得於前開期間內檢附相關身分證明文件辦理帳號續用。超過期限未辦理續用者，除法令另有規定者外，維特人得刪除該帳號及相關電磁紀錄。但您清楚知悉，本服務條款終止以後(無論是否可歸責於您的事由)，都可能導致相關電磁紀錄遺失即無法恢復，您將自行承擔相關風險。
            </p>
            <p><strong>第 5 條&nbsp; 帳號異常使用的通知與處理</strong></p>
            <p>1.
                如維特人或您發現您的帳號有被第三人未經授權使用、非法使用、違反本服務公平性使用、電磁紀錄數失真或其他異常狀況(下合稱「異常使用」)時，維特人得先暫停該帳號的使用權，如異常使用情形並非您的行為所造成，維特人得更換帳號或密碼給您。<br>
                2.
                如有帳號異常使用情形，維特人得暫時限制帳號使用者使用本服務的權利，並以適當方式通知其提出說明。如異常使用情形是因第三人行為所導致者，維特人將通知該第三人(在維特人有該第三人聯繫方式的情形下)或您進行說明，如該第三人或您未於受通知後7日內提出說明，維特人得於通知日期滿起30日內刪除該帳號及相關電磁紀錄，以維護本服務的營運。<br>
                3. 因第三人的行為導致您使用者帳號或相關電磁紀錄刪除者，您應尋司法途徑向該第三人提起主張以解決爭議，均和維特人無關。<br>
                4. 對於您的帳號遭第三人異常使用，維特人不負任何賠償責任。如因您通知不實而導致維特人或第三人受有損害者，您應承擔相關法律責任。<br>
                5. 您同意維特人可以使用任何合法機制檢測和回應異常使用行為、法令禁止及本服務條款所禁止的其他行為(例如違反第14條管理規範)，包括檢查您的設備是否存在、利用漏洞及/或未經授權的軟體。</p>
            <p><strong>第 6 條&nbsp; 計費標準</strong></p>
            <p>1. 本服務如有相關收費項目，將明示於網站、平台、應用程式或相關媒體與文件。<br>
                2.
                本服務內容可能有提供使用者額外付費購買虛擬商品/服務的內容，虛擬商品/服務價格可能會不定時更新。在此情形下，維特人可能委託第三方（下稱「第三方支付服務商」）處理本服務內虛擬商品/服務的銷售、購買及其他交易活動，您同意在交易時遵守本服務條款與使用第三方支付服務商的要求或服務條款，在必要的範圍內，維特人可能會從向我們提供付款、餘額資料或該等資料的更新的第三方支付服務商獲得相關資料（根據第三方支付服務商與您之間的法律關係）。您清楚知悉，虛擬商品/服務是本服務體驗內容的一部份，您並未取得虛擬商品/服務的所有權或其他權利，虛擬商品/服務在本服務中的任何餘額或價值，均不反應在現實世界的具體價值，換句話說，虛擬商品/服務不構成任何類型的貨幣或財產。除了本服務另有明確的體驗約定外，虛擬商品/服務不得向任何人出售、轉讓或交換「真實」貨幣、「真實」商品或「真實」服務。您同時同意，除非得到維特人的明確授權，否則您僅得由維特人提供的方式獲得虛擬商品/服務，在您獲得虛擬商品/服務的許可後，您不得將其轉讓給第三人或其他帳戶。如您違反本項約定，維特人有權限制、終止或取消您的帳戶或本服務，您將自行承擔所有的損失。<br>
                3.
                維特人可能允許或限制您免費使用本服務內之虛擬商品/服務。若您在購買時發現虛擬商品/服務有免費的試用期限，您必須在試用期限結束前取消訂閱或使用完畢，以避免費用的產生。若您未為取消，則視為您同意維特人或第三方支付服務商收取您繼續使用該商品/服務的費用。<br>
                4.
                您在本服務內付費購買的虛擬商品/服務，可能會有使用許可期限的限制，除維特人或第三方支付服務商的政策或適用法律另有明確規定外，不論許可期限是否到期，一律不接受任何形式的退換及退款。維特人有權在不對您承擔任何責任之狀況下，不經通知，隨時提供、修改、刪除及/或終止虛擬商品/服務的全部或任何部分。若您購買本服務內的虛擬商品/服務而產生任何形式的銷售稅、關稅或其它政府稅收或費用（「稅金」），則維特人或第三方支付服務商將向您收取相關稅金，並於結帳時顯示金額。<br>
                5.
                維特人將盡合理商業努力排除所提供本服務內容有關的描述錯誤。儘管如此，維特人無法且不聲明或保證任何本服務描述或價格資訊的準確性、完整性、可靠性、即時性。若發生錯誤，維特人或第三方支付服務商有權更正該錯誤，並且根據情況修正或修改您的訂單（可能包括價格的更正）、或取消訂單並返還任何所收款項。
            </p>
            <p><strong>第 7 條&nbsp; 智慧財產權</strong></p>
            <p>除本服務條款另有規定者外，維特人、維特人的授權人和供應商，依既有的權利擁有本服務的智慧財產權及其他一切權利，並且保留所有未在本服務條款中明確授予您的任何權利。維特人、BEATDAY、相關標誌、和本服務中其他維特人產品及服務的名稱，均為維特人股份有限公司及其關係企業的商標、標誌、著作或其他權利。此外，任何與本服務相關的其他第三方公司名稱、產品或服務名稱及標誌，可能為其他第三方所擁有的商標、標誌、著作或其他權利，未經事前允許不得使用。
            </p>
            <p><strong>第 8 條&nbsp; 授權</strong></p>
            <p>在您完全遵守本服務條款及附加條款的前提條件下，維特人授予您有限的、個人的、不可轉讓的、不可再授權、非獨佔的與可撤銷的使用權，供您以個人和非商業用途方式使用本服務，以及在本服務內購買虛擬商品或服務。</p>
            <p><strong>第 9 條&nbsp; 授權限制</strong></p>
            <p>如本服務內的虛擬商品/服務需要付費，您使用該虛擬商品/服務之授權是在您付款的情形下得到的授權。若您違反本服務條款，維特人得在不退款的情況下，立即終止您使用本服務和/或維特人相關帳戶的權利。本條中所規定的授權係以您遵守下列限制為前提，您不得：<br>
                (1) 規避本服務中的任何技術限制；<br>
                (2) 還原工程、解編、破解、反向組譯或以其他方式試圖存取本服務相關的原始碼，除非法律明確許可；<br>
                (3) 未經授權將本服務或您對本服務的權利出售、出租、散布、再授權或轉讓給任何第三方；<br>
                (4) 對本服務的全部或部分進行修改或做成衍生著作；<br>
                (5) 移除本服務或其備份的任何所有權聲明或標籤；<br>
                (6) 將本服務用於商業用途；<br>
                (7) 將本服務的全部或部分作為公開演出或展示的一部分，無論是否收費；<br>
                (8) 使用本服務侵害維特人及其關係企業或任何第三方的權利，或以違反任何相關法令的方式使用本服務；或<br>
                (9) 以本服務條款或附加條款未允許的方式使用本服務。</p>
            <p><strong>第 10 條&nbsp; 使用者的授權</strong></p>
            <p>1.
                透過使用本服務而產生的任何內容(包括但不限於圖形、照片、音樂、聲音或影片等著作，下合稱「使用者內容」)，您同意授予維特人一非獨占、可轉讓、可再授權、全球性、免授權金的永久授權（如使用者居所/住所的國家或地區應適用法律不允許永久授權，則為適用法律規定的最長授權期限，包括延展期限與次數），供維特人基於優化體驗內容、宣傳本服務或提供您及其他使用者本服務目的而得任意使用、複製、修改、改作、創作衍生作品、公開展示、公開表演、經銷、推廣和出版、散布您的使用者內容。藉由同意本條規定，您同意維特人單獨享有因利用使用者內容獲得的收益，包括但不限於：<br>
                (1) 以任何方式和形式利用使用者內容的權利。<br>
                (2) 於目前已知或未知的任何地點、以任何方法或過程、以收費或免費提供為目的而公開或私下播送或提供使用者內容的權利。<br>
                (3) 利用使用者內容進行本服務相關的所有演示、推廣和廣告的權利。<br>
                (4) 維特人有權選擇自行或委由第三人依前三款方式利用使用者內容。<br>
                2.
                您對您的使用者內容完全負責。您聲明並保證如您擁有使用者用戶內容的權利，您將擁有依據本條約定授予維特人所需的所有權利，您同時聲明且保證，您的使用者內容皆不會違反、剽竊、盜用或侵害第三方的智慧財產權或其他權利。在適用法律允許的範圍內，您同意您不會行使您的人格權（或適用法律下，行使相對應的權利，例如著作人格權）以對抗維特人或維特人授權的第三方。
            </p>
            <p><strong>第 11 條&nbsp; 支援和更新</strong></p>
            <p>維特人沒有義務、且可能不會就本服務提供任何支援服務。但如有更新服務，您同意維特人可以自動檢查您所下載的BEATDAY版本，並通知您進行更新。</p>
            <p><strong>第 12 條&nbsp; 個人資料保護</strong></p>
            <p>維特人將依適用法律與維特人的隱私權政策(連結：<a
                    href="https://beatday.com/legal/">https://beatday.com/legal/</a>)，處理及保護維特人因本服務而向您蒐集的個人資料。</p>
            <p><strong>第 13 條&nbsp; BEATDAY使用者的管理規範(下稱「管理規範」)</strong></p>
            <p>1. 您不得從事任何非法行為，不得違反或侵害他人權利，亦不得嘗試產生或分享非法或不當內容。<br>
                2. 您同意您不會不當使用、試圖不當使用本服務或其他異常使用行為。<br>
                3. 您不得利用本服務發表或傳遞任何不法或不當的內容或資訊，包括但不限於涉及侮辱、誹謗、猥褻、性暗示、騷擾、威脅、仇恨或歧視的言論或資訊，或錯誤資訊或帶有惡意的不實資訊。<br>
                4. 您不得設定不當的使用者名稱，包括但不限於使其他使用者誤認您是維特人的員工或客服人員，或涉及前項不法或不當的內容或資訊。<br>
                5. 您不得參與詐欺、不實或誤導他人的活動，不得傳送垃圾郵件或參與網路釣魚行為。<br>
                6. 您不得從事任何會利用、威脅、傷害兒童或其福祉的活動。<br>
                7. 除了依據維特人提供的方式進行的虛擬商品/服務交易活動外，您不得在本服務中從事商品/服務的廣告或交易等行為。<br>
                8.
                您不得以任何非維特人提供的方式私下進行BEATDAY帳號、兌換代碼、數位內容、虛擬道具或虛擬貨幣、點數、商品/服務的出售、轉讓或交換等交易行為。如您私下交易而產生之各種糾紛，您應自行負責，並且知悉該等行為將導致本服務的終止或取消，維特人將不負任何責任。<br>
                9. 您使用本服務應遵守您居住/所在國家或地區的相關適用法律，以及網際網路之規範及慣例。</p>
            <p><strong>第 14 條&nbsp; 違反本服務條款及管理規範之處理</strong></p>
            <p>1. 如您違反本服務條款或前條管理規範，維特人將通知您進行改善，並視情形限制您使用本服務的權利（包括但不限於鎖定帳號、禁止發言等），且必要時以適當方式公告之。<br>
                2.
                您同意維特人有權為了本服務的運營、確保本服務條款的遵循、應適用法律的遵守，以及其他正當目的隨時刪除或禁止您訪問任何使用者內容，且不另行通知。維特人有權依其單獨的判斷，刪除認為令人反感或違反本服務條款的任何內容。<br>
                3. 您清楚知悉，任何嘗試破壞或干擾本服務的行為，包括但不限於對任何網站或應用程式的合法運營進行破壞、操縱或干擾，可能涉及刑法和民事法律的違反，維特人將採取必要的法律措施。</p>
            <p><strong>第 15 條&nbsp; 安全及適當使用規範</strong></p>
            <p>1.
                在您使用本服務時，請注意周圍環境，安全使用與進行交流。您同意自行承擔使用本服務的風險，且您不會將本服務以違反任何應適用的法令與本服務條款進行使用，也不會唆使第三人進行相同或類似的使用。您清楚知悉，維特人無意將本服務作為醫療、健康設備，或提供醫療或健康等建議。<br>
                2. 您同意在使用本服務的同時，您不會提供任何非法的、不適當或不正確的、誤導性的內容，維特人有權刪除相關內容。<br>
                3.
                您清楚知悉在使用本服務時，您將不可避免地與現實世界中的個人或其他使用者互動，但您同意將保持安全和適當的聯繫。任何騷擾、威脅或以其他方式侵犯其他個人或使用者法律權利的使用行為，可能導致傷害、死亡，財產損害或精神損失等結果，如因此發生爭議，您將自行負擔相關法律責任，且無權對維特人(包括維特人的董事、經理人、員工或合作廠商及其董事、經理人、員工)主張或要求任何賠償或連帶負責。<br>
                4. 您清楚知悉，使用本服務不代表您可以隨意進入第三人的私人財產、地點或相關領域，您將為您自己未獲得授權而侵犯第三人的私人財產、地點或領域的行為負起責任。</p>
            <p><strong>第 16 條&nbsp; 反饋</strong></p>
            <p>您可能提供口頭或書面的評論、建議、想法、計畫、說明、繪圖或其它關於與本服務相關的資訊（下合稱「反饋資訊」）。維特人有權無償自由使用、揭露、複製、授權或散佈反饋資訊，且對您不承擔任何其他法律上義務。</p>
            <p><strong>第 17 條&nbsp; 服務條款的更新</strong></p>
            <p>1. 本服務條款如有更新，維特人將以適當方式通知或公告。<br>
                2. 在前項通知或公告後15日內，如您無反對的表示或持續使用本服務者，視為您同意接受更新後的服務條款；如您為反對的表示者，視為您終止本服務的使用。</p>
            <p><strong>第 18 條&nbsp; 本服務條款的終止及退款</strong></p>
            <p>1. 您可隨時通知維特人終止本服務條款。<br>
                2. 如您逾1年未登入帳戶使用本服務，維特人得定15日以上期間通知您登入，逾期未登入者，維特人得終止本服務條款。<br>
                3. 如您有下列情形之一者，維特人得通知終止本服務條款：<br>
                (1) 利用任何系統或工具對維特人電腦系統之惡意攻擊或破壞。<br>
                (2) 以利用外掛程式、病毒程式、BEATDAY系統或程式漏洞或其他違反常態設定或公平合理之方式使用本服務。<br>
                (3) 以冒名、詐騙或其他虛偽不正等方式付費購買本服務內之虛擬商品/服務。<br>
                (4) 因同一事由違反管理規範達3次以上，經維特人通知改善而未改善者。<br>
                (5) 經司法機關查獲從事任何不法之行為。<br>
                (6) 其他違反本服務條款之行為，情節重大者。<br>
                4. 本服務條款一經終止，您即不得再使用本服務，且維特人得取消您的帳號。<br>
                5. 您的BETADAY帳號一經刪除，或維特人下架BEATDAY，視為本服務終止，維特人不負任何責任。但因不可歸責於您的原因，導致您的BETADAY帳號遭刪除者，依本服務條款約定辦理。<br>
                6. 本服務條款終止時，維特人於扣除必要成本後，將於終止後60日內退還您付費購買而尚未使用的點數，或依雙方同意之方式處理之。</p>
            <p><strong>第 19 條&nbsp; 本服務停止營運</strong></p>
            <p>維特人如欲停止本服務的營運者，得提前30日以適當方式通知或公告。</p>
            <p><strong>第 20 條&nbsp; 免責聲明</strong></p>
            <p>1. 在適用法律所允許的最大範圍內，本服務係以「現狀」提供，不提供任何擔保或承諾。維特人尤其不擔保本服務： (i) 能夠不間斷、即時、安全或毫無錯誤地進行運作； (ii)
                透過本服務取得的任何資訊或內容均為正確、完整或可靠；與 (iii)
                本服務中的瑕疵或錯誤均能被更正。您使用本服務的風險均由您自行承擔，並由您自行負責因此產生的任何損害或損失。本服務條款不會限制您依您居住/所在國家或地區強制性法律所可能享有的任何不可放棄的擔保或消費者保護權利。<br>
                2.
                本服務可能包含指向第三方網站或資源的連結(下稱「第三方連結」)。維特人提供的第三方連結是為了增加本服務體驗的便利性，並不對第三方連結提供的內容、產品或服務(下合稱「第三方連結服務」)作出任何擔保或保證，在你使用第三方連結服務前，您應該自行詳細了解服務內容，並同意自行承擔使用第三方連結服務產生的風險與責任，您於此放棄和免除對維特人的所有索賠、要求、損害賠償、損失和費用，或其他間接因第三方連結服務產生或與之相關的任何責任。
            </p>
            <p><strong>第 21 條&nbsp; 責任限制</strong></p>
            <p>1. 在適用法律所允許的最大範圍內，維特人對於您因使用本服務所產生的任何特殊、間接、附帶及衍生性損害，均不負責。<br>
                2. 在適用法律所允許的最大範圍內，就您因使用本服務所產生的全部損失及損害，維特人應向您所承擔的賠償責任總額，以您在請求賠償之日前1年內就本服務或本服務內之虛擬商品/服務所支付之費用為上限。</p>
            <p><strong>第 22 條&nbsp; 賠償</strong><br>
                如維特人及其董事、經理人、員工、代理人、合作夥伴、供應商和授權人遭任何第三方提出與下列情形之一有關之索賠或要求，您應為前述對象進行防禦，並賠償其因此所生一切損失及損害，包含合理的律師費：(i)
                您未經授權使用本服務；(ii) 您違反本服務條款或附加條款；(iii) 您侵害他人權利或違反相關法律；(iv) 您所提供的反饋資訊。</p>
            <p><strong>第 23 條&nbsp; 準據法及司法管轄</strong></p>
            <p>本服務條款的解釋與適用，以中華民國法律為準據法。因本服務條款或本服務所生爭議，以臺灣臺北地方法院為第一審管轄法院。</p>
            <p><strong>第 24 條&nbsp; 一般規定</strong></p>
            <p>1. 維特人得轉讓或移轉我們在本服務條款中權利義務的全部或一部，而您同意就此類轉讓或移轉相關事宜與維特人合作。<br>
                2. 如維特人未對您違反本服務條款的行為主張或行使任何權利、權力或補救，不構成維特人放棄該權利、權力或補救。對於本服務條款中任何條款的放棄均應以書面為之，且只在書面所列範圍內有效。<br>
                3. 如本服務條款中任何條款被認定為無效、非法或不可執行，其餘條款仍完全有效而不受影響。<br>
                4. 本服務條款及任何附加條款，構成您與我們之間關於您使用本服務的完整合意。</p>
            <p><strong>第 25 條&nbsp; 聯絡資訊</strong><br>
                如果您對本服務或本服務條款有任何問題，請將所有通知及信件發送至：<br>
                維特人股份有限公司 UOMO VITRUVIANO CORPORATION<br>
                地址：中華民國台灣 231 新北市新店區中興路三段 88 號11樓;或<br>
                客服信箱：<a href="mailto:contact@beatday.com">contact@beatday.com</a><br>
                UOMO VITRUVIANO CORPORATION<br>
                Address: 11F, No. 88, Section 3, Zhongxing Road, Xindian Dist., New Taipei City 231, Taiwan (R.O.C.); or<br>
                Email Address (Customer Service): <a href="mailto:contact@beatday.com">contact@beatday.com</a></p>

        </div>
    </section>
</template>
<script>
import { getSomething } from '@/api/apiService';
export default {
    name: 'LegalPage',
    components: {
    },
    mounted() {

    },
    data() {
        return {

        };
    },
    props: {},
    computed: {},
    methods: {
        async fetchData() {
            try {
                const response = await getSomething();
                this.test = response;
            } catch (error) {
                // Handle error
            }
        },

    },
}
</script>
    
<style lang="scss"></style>
    