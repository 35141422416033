<template>
    <section class="wrapper download_page faq">
        <h1>常見問題</h1>
        <div class="block">
            <h3>下載安裝</h3>
            <div class="faq-item" v-for="(faq, index) in faqs_download" :key="'download_' + index">
                <div class="faq-question" @click="toggleAnswer('download_' + index)"
                    :style="{ color: faq.open ? 'white' : '#A6BED9' }">
                    &blacktriangleright; {{ faq.question }}
                </div>
                <div class="faq-answer" v-if="faq.open" v-html="faq.answer">
                </div>
            </div>
        </div>
        <div class="block">
            <h3>帳號相關</h3>
            <div class="faq-item" v-for="(faq, index) in faqs_account" :key="'account_' + index">
                <div class="faq-question" @click="toggleAnswer('account_' + index)"
                    :style="{ color: faq.open ? 'white' : '#A6BED9' }">
                    &blacktriangleright; {{ faq.question }}
                </div>
                <div class="faq-answer" v-if="faq.open" v-html="faq.answer">
                </div>
            </div>
        </div>
        <div class="block">
            <h3>觀看相關</h3>
            <div class="faq-item" v-for="(faq, index) in faqs_view" :key="'account_' + index"
                :style="{ color: faq.open ? 'white' : '#A6BED9' }">
                <div class="faq-question" @click="toggleAnswer('view_' + index)"
                    :style="{ color: faq.open ? 'white' : '#A6BED9' }">
                    &blacktriangleright; {{ faq.question }}
                </div>
                <div class="faq-answer" v-if="faq.open" v-html="faq.answer">
                </div>
            </div>
        </div>
        <div class="block">
            <h3>操作相關</h3>
            <div class="faq-item" v-for="(faq, index) in faqs_operate" :key="'operate_' + index"
                :style="{ color: faq.open ? 'white' : '#A6BED9' }">
                <div class="faq-question" @click="toggleAnswer('operate_' + index)"
                    :style="{ color: faq.open ? 'white' : '#A6BED9' }">
                    &blacktriangleright; {{ faq.question }}
                </div>
                <div class="faq-answer" v-if="faq.open" v-html="faq.answer">
                </div>
            </div>
        </div>
        <contact-us></contact-us>
    </section>
</template>
<script>
import { getSomething } from '@/api/apiService';
import contactUs from '@/components/contactUs.vue';
export default {
    name: 'FAQ',
    components: {
        'contact-us': contactUs,
    },
    mounted() {
    },
    data() {
        return {
            faqs_download: [
                {
                    question: '電腦規格需求',
                    answer: `
                    <ul>
                        <li>Windows 系統<br>
                        CPU：Intel core i7 以上<br>
                        RAM：8GB 以上<br>
                        顯卡：畫質優先 NVIDIA GeForce GTX1060 以上，效能優先 NVIDIA GeForce GT 1030 以上<br>
                        安裝空間: 硬碟空間需至少20GB以上<br>
                        建議網速: 50 Mbps</li>
                        <li>MAC系統<br>
                        2018年後之任何機種<br>
                        軟體：OS.10.14以上<br>
                        安裝空間: 硬碟空間需至少20GB以上<br>
                        建議網速: 50 Mbps</li>
                        <li>注意事項<br>
                        若電腦規格低於以上規格，則會出現卡頓、延遲，甚至無法觀看表演的情況<br>
                        在體驗 BEATDAY 過程中請勿同時開啟其他影音軟體、遊戲、錄影等，以免影響體驗品質<br>
                        BEATDAY 程式需安裝於英文命名的資料夾下，若安裝於中文命名的資料夾將導致安裝不完整</li>
                    </ul>
                    `,
                    open: false
                },
                {
                    question: '下載時間過長',
                    answer: '請確定目前網速超過50Mbps (<a href="https://fast.com/zh/tw/" target="_blank">點我測試</a>)，如果wifi太慢，可以改接有線網路。下載時可關閉其它串流影音，減少電腦的負擔喔！',
                    open: false
                },
                {
                    question: '下載到一半斷線',
                    answer:
                        `若下載時遇斷線，請確認以下項目：
                    <ul>
                        <li>確認硬碟空間還有20G以上，若容量不夠建議整理硬碟空間或換別槽安裝。</li>
                        <li>確認網路連線正常且電腦沒有進入休眠。</li>
                        <li>重新啟動程式或重新開機。</li>
                    </ul>`,
                    open: false
                },
                {
                    question: '下載出現防火牆警告',
                    answer:
                        `下載檔案若與防火牆衝突，請選擇「其他資訊」→「仍要執行」，即可繼續執行 BEATDAY 了`,
                    open: false
                },
                {
                    question: '下載停留在某個進度',
                    answer:
                        `請嘗試再點擊一次「更新中」的按鍵，或重新開啟下載程式。`,
                    open: false
                },
            ],
            faqs_account: [
                {
                    question: '註冊失敗',
                    answer: `請檢查是否有符合以下規範：
                    <ul>
                        <li>請確認是否有勾選同意隱私權政策與使用者條款。</li>
                        <li>用Email註冊，帳號格式錯誤，需要是正確Email。</li>
                        <li>密碼不符合規範，需要8個字以上，且包含大小寫英文+數字。</li>
                        <li>請檢查兩次密碼輸入是否一致。</li>
                    </ul>`,
                    open: false
                },
                {
                    question: '會員註冊，驗證碼收不到',
                    answer: `請點選「忘記密碼」，稍後會將重設相關資訊寄到您的Email，請按照畫面步驟操作。`,
                    open: false
                },
                {
                    question: '社群帳號登入卡住無法登入',
                    answer:
                        `請您更換一組帳號(或不同的社群)登入，若仍無法登入，請您直接改用Email註冊一組帳號登入。`,
                    open: false
                },
                {
                    question: '先前帳號無法登入',
                    answer:
                        `請用Line聯繫客服，提供您的BEATDAY帳號、登入方式、暱稱或Email，讓我們為您查詢。`,
                    open: false
                },
            ],
            faqs_view: [
                {
                    question: '我的電腦該選哪種畫質設定？',
                    answer: `
                        您可以在介面右上的「設定」中切換畫質選項。<br>
                        「畫質優先」：高畫質體驗，適合能運轉 3A 遊戲的電腦！<br>
                        「效能優先」：觀看順暢，適合文書筆電或Mac！
                    `,
                    open: false
                },
                {
                    question: '平板 / 手機可以看嗎？',
                    answer: `目前提供電腦版程式觀看，尚未支援手機、平板等行動裝置，敬請見諒！`,
                    open: false
                },
                {
                    question: '如何進入活動場景',
                    answer: `進入BEATDAY大廳後，走進電梯口的傳送門即可進入活動場景。`,
                    open: false
                },
                {
                    question: '表演卡頓、當機、閃退',
                    answer: `建議先調整「設定」→「畫質設定」→「效能優先」讓表演更順暢，並關閉其它沒有使用的應用程式。也可嘗試重新執行BEATDAY或重新開機。若仍然無法順利觀賞，請聯繫客服處理。`,
                    open: false
                },
                {
                    question: '遇到 BUG 怎麼辦',
                    answer: `您可以嘗試重新登入繼續探索。若還是有問題，請用Line聯繫客服，並在BEATDAY裡按下「設定」→「幫助中心」→「客服」，按下同意「傳送帳號資料給客服」，我們將盡快幫您查詢並回覆。`,
                    open: false
                },
            ],
            faqs_operate: [
                {
                    question: '如何移動操作',
                    answer: `
                        WASD控制↑←↓→移動。<br>
                        也可以直接用滑鼠點地面移動。<br>
                        按住右鍵拖曳滑鼠可旋轉鏡頭。<br>
                        更多教學請點選「設定」→「幫助中心」→「教學」，會有詳細說明
                    `,
                    open: false
                },
                {
                    question: '如何飛行操作',
                    answer: `
                    空白鍵按一下跳躍，按兩下飛行！<br>
                    更多教學請點選「設定」→「幫助中心」→「教學」，會有詳細說明。
                    `,
                    open: false
                },
                {
                    question: '使用 MAC，鍵盤 WASD 無法移動',
                    answer: `部分Mac系統因為處理器架構不同，所以在執行不同架構的 App 需要經過轉譯後才能使用。若使用MAC版本M2系統， 進入Finder→點選「前往」→ 應用程式 → Beatday → 按右鍵點選「取得資訊」→勾選「使用Rosetta打開」，重啟BEATDAY程式後即可順利操作角色囉！`,
                    open: false
                },
                {
                    question: '鍵盤操作無法移動',
                    answer: `請嘗試將「對話框」或「背包」關閉後，再使用鍵盤上的「WASD」移動，若還是無法移動，請聯繫客服人員協助您排除狀況。`,
                    open: false
                },
                {
                    question: '如何取得服飾 / 道具',
                    answer: `成為BEATDAY會員後，您將擁有基礎服飾與道具，若想獲得更多精美周邊，可以至大廳的商城購買，或透過參與每場活動或小遊戲來獲得喔！`,
                    open: false
                },
                {
                    question: '如何換角色',
                    answer: `點選「人像」→「更換人物」，有各種角色可以切換。`,
                    open: false
                },
                {
                    question: '如何換服裝',
                    answer: `點選「人像」→「更換服裝」。拖曳或連擊想要的服裝即可裝備。`,
                    open: false
                },
                {
                    question: '如何使用道具',
                    answer: `點選「人像」→「更換服裝」，可切換「道具」、「舞步」、「表情符號」類別，將道具往上拖曳到數字格。設定完成後，在操作人物移動時，按下對應的數字即可使用！`,
                    open: false
                },
            ],
        };
    },
    props: {},
    computed: {},
    methods: {
        async fetchData() {
            try {
                const response = await getSomething();
                this.test = response;
            } catch (error) {
                // Handle error
            }
        },
        toggleAnswer: function (key) {
            const targetElement = document.querySelector(`[key="${key}"]`);
            if (targetElement) {
                targetElement.scrollIntoView({ behavior: 'smooth' });
            }
            this.faqs_download.forEach((faq, index) => {
                if ('download_' + index === key) {
                    faq.open = !faq.open;
                } else {
                    faq.open = false;
                }
            });
            this.faqs_account.forEach((faq, index) => {
                if ('account_' + index === key) {
                    faq.open = !faq.open;
                } else {
                    faq.open = false;
                }
            });
            this.faqs_view.forEach((faq, index) => {
                if ('view_' + index === key) {
                    faq.open = !faq.open;
                } else {
                    faq.open = false;
                }
            });
            this.faqs_operate.forEach((faq, index) => {
                if ('operate_' + index === key) {
                    faq.open = !faq.open;
                } else {
                    faq.open = false;
                }
            });
        },
    },
}
</script>

<style lang="scss"></style>
