<template>
    <aside>
        <h1>下載與教學</h1>
        <nav>
            <ul>
                <li><router-link to="/downloads-tutorials/download" active-class="active">程式下載</router-link></li>
                <li><router-link to="/downloads-tutorials/instructions" active-class="active">使用教學</router-link></li>
                <li><router-link to="/downloads-tutorials/faq" active-class="active">常見問題</router-link></li>
            </ul>
        </nav>
    </aside>
</template>



<script>
export default {
    name: 'NavAside',
    data() {
        return {
        };
    },
    methods: {

    }
};
</script>

<style lang="scss"></style>
