<template>
    <section class="download_page instructions">
        <h1>使用教學</h1>
        <div class="block inst" v-for="(section, index) in instructions" :key="index">
            <h3>{{ section.title }}</h3>
            <p class="text_add">{{ section.before }}</p>
            <div class="flex">
                <div class="item" v-for="(step, stepIndex) in section.steps" :key="stepIndex">
                    <p class="step">STEP.{{ stepIndex + 1 }}</p>
                    <p>{{ step.description }}</p>
                    <img :src="step.imageUrl" :alt="'Step ' + (stepIndex + 1)">
                </div>
            </div>
            <p v-html="section.after" class="text_add"></p>
        </div>
        <contact-us></contact-us>
    </section>
</template>
<script>
import { getSomething } from '@/api/apiService';
import contactUs from '@/components/contactUs.vue';
export default {
    name: 'InstructionsPage',
    components: {
        'contact-us': contactUs,
    },
    mounted() {

    },
    data() {
        return {
            instructions: [
                {
                    title: '如何安裝',
                    steps: [
                        {
                            description: '勾選同意使用協議與隱私條款，【下載】BEATDAY程式',
                            imageUrl: require('@/assets/img/faq/STEP1.png')
                        },
                        {
                            description: '執行exe檔，按照畫面指示點選【下一步】安裝 （Windows）',
                            imageUrl: require('@/assets/img/faq/STEP7.webp')
                        },
                        {
                            description: '執行dmg檔，將BEATDAY拖曳到右邊資料夾（Mac）',
                            imageUrl: require('@/assets/img/faq/STEP8.webp')
                        },
                        {
                            description: '自動更新檔案後，完成安裝，開啟首頁',
                            imageUrl: require('@/assets/img/faq/STEP41.webp')
                        },
                    ]
                },
                {
                    title: '如何註冊',
                    steps: [
                        {
                            description: '首次啟動請依配備選擇【畫質優先】或【效能優先】',
                            imageUrl: require('@/assets/img/faq/STEP6.webp')
                        },
                        {
                            description: '使用社群帳號或Email註冊會員，登入BEATDAY】',
                            imageUrl: require('@/assets/img/faq/STEP2.webp')
                        },
                        {
                            description: '選擇Avatar、填寫暱稱與資料(之後皆可隨時修改)',
                            imageUrl: require('@/assets/img/faq/STEP9.webp')
                        },
                        {
                            description: '完成創角進入BEATDAY大廳，展開冒險旅程！',
                            imageUrl: require('@/assets/img/faq/STEP10.webp')
                        },
                    ]
                },
                {
                    title: '已經購票，如何取票',
                    before: '※每個序號僅限一個BEATDAY帳號兌換，兌換後無法轉讓！',
                    after:
                        `
                    KKTIX：購票後，會收到含有【取票序號】的Email<br>
                    拓元：購票後【專屬代碼】將顯示於系統訂單頁<br>
                    實體票券：【兌換碼】會印於卡片背面<br>
                    BEATDAY官網：購票後，會收到含有【取票序號】的Email
                    `,
                    steps: [
                        {
                            description: '登入大廳，點選介面右下的【節目】按鈕',
                            imageUrl: require('@/assets/img/faq/STEP19.webp')
                        },
                        {
                            description: '已購票，要取票請直接點選【取票序號】',
                            imageUrl: require('@/assets/img/faq/STEP26.webp')
                        },
                        {
                            description: '在【取票序號】欄位，輸入購票獲得的6碼序號，按【確定】',
                            imageUrl: require('@/assets/img/faq/STEP27.webp')
                        },
                        {
                            description: '取票後，前往對應的活動入口，點選【進入】觀看',
                            imageUrl: require('@/assets/img/faq/STEP4.webp')
                        },
                    ]
                },
                {
                    title: '直接在程式內購票',
                    before: '※購票後，票券會直接綁定在當下的帳號，無法轉讓！',
                    steps: [
                        {
                            description: '登入大廳，點選介面右下的【節目】按鈕',
                            imageUrl: require('@/assets/img/faq/STEP29.webp')
                        },
                        {
                            description: '按左右切換節目，決定後按下【立即購票】',
                            imageUrl: require('@/assets/img/faq/STEP25.webp')
                        },
                        {
                            description: '選擇票種、日期，決定後按下【付款】',
                            imageUrl: require('@/assets/img/faq/STEP24.webp')
                        },
                        {
                            description: '依照藍星金流的網頁指示，完成付款',
                            imageUrl: require('@/assets/img/faq/STEP15.webp')
                        },
                    ]
                },
                {
                    title: '下載節目資源包',
                    before: '※請在演唱會開演前，提前下載節目資源包，以免錯過演出！',
                    steps: [
                        {
                            description: '購票或取票後，點選介面右下的【節目】按鈕',
                            imageUrl: require('@/assets/img/faq/STEP29.webp')
                        },
                        {
                            description: '到【節目選單】，找到你購買的節目，點選【下載更新】',
                            imageUrl: require('@/assets/img/faq/STEP46.webp')
                        },
                        {
                            description: '下載時間依照個人網速約5~30分鐘不等，請務必提前下載。',
                            imageUrl: require('@/assets/img/faq/STEP36.webp')
                        },
                        {
                            description: '主畫面上方有時間倒數，開演前30分鐘可以點選節目進入',
                            imageUrl: require('@/assets/img/faq/STEP35-1.webp')
                        },
                    ]
                },
                {
                    title: '兌換活動道具',
                    before: '※請追蹤BEATDAY 官方IG、LINE、FB粉絲團，我們會透過社群不定期發送虛擬道具喔！',
                    steps: [
                        {
                            description: '點選【節目】→【取票序號】，並輸入活動道具的【兌換碼】',
                            imageUrl: require('@/assets/img/faq/STEP37.webp')
                        },
                        {
                            description: '輸入【兌換碼】後按下【確定】，就會獲得活動道具，並自動放進背包。',
                            imageUrl: require('@/assets/img/faq/STEP38.webp')
                        },
                        {
                            description: '點選右下角【頭像】→【服裝道具】(背包)，拖曳該道具就可以裝備在身上。',
                            imageUrl: require('@/assets/img/faq/STEP39.webp')
                        },
                        {
                            description: '【服裝】裝備後會改變外觀。【道具/舞步/貼圖】裝備後可以按對應的數字使用。',
                            imageUrl: require('@/assets/img/faq/STEP40.webp')
                        },
                    ]
                },
                {
                    title: '購買角色與道具',
                    before: '※購買後，商品會直接綁定在當下的帳號，無法轉讓！',
                    steps: [
                        {
                            description: '登入大廳，從右側上樓梯前往二樓，找到圖示的商店，點選【SHOP】打開商品列表。',
                            imageUrl: require('@/assets/img/faq/STEP42.webp')
                        },
                        {
                            description: '商城有販售角色/服裝/舞步/道具，選擇想要的商品後(可以複選)，按下【購買】。',
                            imageUrl: require('@/assets/img/faq/STEP43.webp')
                        },
                        {
                            description: '填妥E-mail(用於寄送電子發票)，按下【付款】，透過藍星金流網頁付款完成交易。',
                            imageUrl: require('@/assets/img/faq/STEP44.jpg')
                        },
                        {
                            description: '點選頭像打開背包，在【服裝道具】或【我的化身】→【追加】，找到購入的道具/角色。',
                            imageUrl: require('@/assets/img/faq/STEP45.webp')
                        },
                    ]
                },
            ]
        };
    },
    props: {},
    computed: {},
    methods: {
        async fetchData() {
            try {
                const response = await getSomething();
                this.test = response;
            } catch (error) {
                // Handle error
            }
        },
    },
}
</script>
    
<style lang="scss">
</style>
    