<template>
    <div class="info-popup modal">
        <div class="mask"></div>
        <div class="modal-content" @click.stop>

            <div v-if="ticket.Type === 0" class="text">
                <!-- 文字類型的內容 -->
                <h2>{{ ticket.ShowName }}</h2>
                <h3>{{ ticket.Name }}</h3>
                <p v-html="ticket.Detail"></p>

            </div>

            <div v-else-if="ticket.Type === 1" class="text">
                <!-- QRCode 類型的內容 -->
                <div v-if="!serialSuccess">
                    <div class="QRCode_box">
                        <vue-qr class="qr-code" :text="qrCodeUrl" :size="500" />

                        <!-- <vue-qr class="qr-code" :logoSrc="imageUrl" :text="qrCodeUrl" :size="500" /> -->
                        <p @click="refreshQrCode">{{ formattedTime }} 更新 <img src="../../assets/img/member/reset.svg" alt=""
                                class="resetIcon"></p>
                    </div>
                    <!-- 其他 QRCode 類型的內容... -->
                    <h2>{{ ticket.ShowName }}</h2>
                    <h3>{{ ticket.Name }}</h3>
                    <p v-html="ticket.Detail"></p>
                </div>
                <div v-if="serialSuccess" class="serialSuccess">
                    <dotlottie-player id="check-box"
                        src="https://lottie.host/d525671a-5c4b-4694-a264-2d8554f45e93/mSj3CZexHR.json"
                        background="transparent" speed="1" autoplay=""></dotlottie-player>
                    <h2>兌換成功</h2>
                </div>
            </div>

            <div @click="closeClick" class="close">
                <h2 class="close_icon">關閉 &times;</h2>
            </div>
        </div>
    </div>
</template>

<script>
import Cookies from 'js-cookie';
import vueQr from 'vue-qr/src/packages/vue-qr.vue';
import { getTicketState } from '@/api/apiService';

export default {
    props: {
        ticket: Object,

    },
    components: { vueQr },
    data() {
        return {
            jwt: '',
            uid: '',
            qrCodeUrl: '',
            remainingTime: 5 * 60, // 初始的倒數時間，單位為秒
            intervalId: null, // 保存 setInterval 函數的返回值，以便後面清除定時器
            serialSuccess: false,
            timestamp: '',
            startCompare: true
        };
    },
    mounted() {
        this.refreshQrCode();
        this.setRefreshInterval();
        this.setUpdateTicket();
        this.intervalId = setInterval(this.updateTimer, 1000);
    },
    beforeUnmount() {
        clearInterval(this.refreshInterval);
        clearInterval(this.ticketInterval);
    },
    computed: {
        imageUrl() {
            return this.ticket ? this.ticket.Pic1 : '';
        },
        formattedTime() {
            const minutes = Math.floor(this.remainingTime / 60);
            const seconds = this.remainingTime % 60;
            return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
        }
    },
    methods: {
        refreshQrCode() {
            this.remainingTime = 5 * 60;
            const baseUrl = 'https://beatday.com/';
            this.jwt = Cookies.get('jwt');
            if (!this.jwt) {
                this.$router.push('/member-login/login');
            }
            this.uid = Cookies.get('Id');
            const randomParam = Math.random().toString(36).substring(2, 5);
            const timestamp = Date.now();
            this.timestamp = timestamp;
            if (!this.jwt) {
                this.qrCodeUrl = `${baseUrl}?random=${randomParam}`;
            }
            else {
                this.qrCodeUrl = `${baseUrl}?random=${randomParam}&uid=${this.uid}&tid=${this.ticket.Id}&timestamp=${timestamp}`;
            }
        },
        async updateTicket() {
            if (this.jwt && this.startCompare) {
                const data = {
                    uid: this.uid,
                    timestamp: this.timestamp
                }
                const response = await getTicketState(data);
                const responseData = response.data;
                if (responseData === true || responseData === 'true') {
                    this.serialSuccess = true;
                    this.startCompare = false;
                    this.$nextTick(() => {
                        // 使用 document.querySelector 來選擇元素，而不是直接使用 jQuery
                        const checkBox = document.querySelector("#check-box");
                        if (checkBox) {
                            // 檢查元素是否存在，然後播放動畫
                            setInterval(() => {
                                checkBox.play();
                            }, 0);
                        }
                    });
                }
            }
        },
        setRefreshInterval() {
            // 60s刷新二维码
            this.refreshInterval = setInterval(this.refreshQrCode, 60000);
        },
        async setUpdateTicket() {
            if (this.ticket.Type === 1) {
                this.ticketInterval = setInterval(this.updateTicket, 5000);
            }
        },
        updateTimer() {
            // 更新剩餘時間，如果時間為0，則停止計時器

            if (this.remainingTime > 0) {
                this.remainingTime--;
            } else {
                clearInterval(this.intervalId);
            }

        },
        closeClick() {
            this.$emit('close-info-popup');
            this.serialSuccess = false;
        },
    },
    // beforeDestroy() {
    //     // 在組件被摧毀前，清除定時器，防止內存洩漏
    //     clearInterval(this.intervalId);
    // }
};
</script>
