<template>
    <section class="download_page contact_us">
        <h1>聯繫客服</h1>
        <img src="../assets/img/public/Line_QRcode_Big.svg" alt="">
        <p>ID： <a href="https://line.me/R/ti/p/@485ibcgx?oat__id=29053"><span>@beatday</span></a></p>
        <p>或 E-mail 至 <a href="mailto:contact@beatday.com"><span>contact@beatday.com</span></a> 並留下大名以及您的問題<br>
            我們將在最近的一個工作日，盡快與您聯繫，謝謝！</p>
    </section>
</template>
<script>

export default {
    name: 'contactUs',
    components: {
    },
    mounted() {
    },
    data() {
        return {

        };
    },
    props: {},
    computed: {},
    methods: {

    },
}
</script>
    
<style lang="scss">
@import "../assets/styles/pages/DownloadsTutorials.scss";
</style>
