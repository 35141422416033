<template>
    <div class="register">
        <h1 class="title" v-if="titleClose">註冊新帳號</h1>
        <form @submit.prevent="register" v-if="showRegister">
            <div class="username">
                <i class="bi bi-person-fill"></i>
                <input v-model="usernameR" type="text" id="username" name="username" placeholder="電子信箱">
            </div>

            <div class="password">
                <i class="bi bi-key-fill"></i>
                <input v-model="passwordR" :type="passwordVisible ? 'text' : 'password'" id="password" name="password"
                    placeholder="密碼">
                <div class="eye_btn" @click="togglePasswordVisibility"><i class="bi"
                        :class="passwordVisible ? 'bi-eye-fill' : 'bi-eye'"></i></div>
            </div>

            <div class="password">
                <i class="bi bi-key-fill"></i>
                <input v-model="passwordCheck" :type="passwordVisible ? 'text' : 'password'" id="passwordCheck"
                    name="password" placeholder="再次輸入密碼">
                <div class="eye_btn" @click="togglePasswordVisibility"><i class="bi"
                        :class="passwordVisible ? 'bi-eye-fill' : 'bi-eye'"></i></div>
            </div>

            <div class="privacy">
                <input type="checkbox" id="privacy" v-model="privacyCheckedR" />
                <label for="privacy">我已閱讀並同意<a href="https://beatday.com/app-user-policy/" target="_blank">使用者協議</a> 、<a
                        href="https://beatday.com/app-privacy/" target="_blank">隱私權政策</a></label>
            </div>

            <div class="alert">{{ alertR }}</div>
            <button type="submit" class="login_btn">註冊新帳號</button>

            <div class="link">
                <router-link to="/member-login/login">返回登入</router-link>
            </div>
        </form>

        <verify-number :username="usernameR" :reason="reasonR" v-if="showVerifyNumber"
            @verification-success="handleVerificationSuccess"></verify-number>

        <div v-if="registrationSuccess" class="registration-success">
            <h1 class="title">註冊成功</h1>
            <dotlottie-player id="check-box" src="https://lottie.host/d525671a-5c4b-4694-a264-2d8554f45e93/mSj3CZexHR.json"
                background="transparent" speed="1" autoplay=""></dotlottie-player>
            <p>將在 {{ countdown }} 秒後自動跳轉至會員登入</p>
            <router-link to="/member-login/login">
                <button class="login_btn">返回登入</button>
            </router-link>
        </div>
    </div>
    <Loading v-show="loading"></Loading>
</template>
  
<script>
import VerifyNumber from '@/components/Login/Verify.vue';
import { signUp } from '@/api/apiService';
import Loading from '@/components/Loading.vue';


export default {
    name: 'RegisterRegion',
    props: {

    },
    components: {
        'verify-number': VerifyNumber,
        Loading
    },
    data() {
        return {
            reasonR: "verifyAcc",
            usernameR: "",
            passwordR: "",
            passwordCheck: "",
            passwordVisible: false,
            privacyCheckedR: false,
            alertR: "",
            showRegister: true,
            showVerifyNumber: false,
            registrationSuccess: false,
            countdown: 5,
            titleClose: true,
            loading: false
        };
    },
    mounted() {
        if (this.registrationSuccess) {
            this.playAnimation();
        }
    },
    methods: {
        async signup() {
            try {
                const data = {
                    username: this.usernameR,
                    password: this.passwordR,
                    from: 'basic',
                    language: 'zh-tw'
                }
                this.loading = true;
                const response = await signUp(data);
                if (response.status === 200) {
                    this.loading = false;
                    this.alertR = ""; // 清空錯誤訊息
                    this.showRegister = false;
                    this.showVerifyNumber = true;
                }
                else {
                    this.loading = false;
                    this.alertR = "此帳號已被註冊。";
                }
            }
            catch (error) {
                this.loading = false;
                this.alertR = "此帳號已被註冊。";
            }
        },
        register() {
            // 1. 檢查帳號格式
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(this.usernameR)) {
                this.alertR = "＊ 請輸入有效電子信箱";
                return;
            }

            // 2. 檢查密碼是否符合長度8-16位
            const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z]).{8,16}$/;
            if (!passwordRegex.test(this.passwordR)) {
                this.alertR = "＊ 請輸入 8 - 16 位數密碼，且包含大小寫";
                return;
            }

            // 3. 檢查兩次輸入的密碼是否一致
            if (this.passwordR !== this.passwordCheck) {
                this.alertR = "＊ 密碼和確認密碼不一致。請重新輸入";
                return;
            }

            // 4. 檢查是否勾選隱私條款
            if (!this.privacyCheckedR) {
                this.alertR = "＊ 請勾選同意隱私條款";
                return;
            }

            // 5. 檢查特殊字符
            const disallowedCharsRegex = /['"<>\\;=()\s]/;
            if (disallowedCharsRegex.test(this.usernameR)) {
                this.alertR = "＊ 帳號中禁止包含特殊字元";
                return;
            }

            else {

                this.signup()
            }
        },
        handleVerificationSuccess(success) {
            if (success) {
                this.alertR = "";
                this.showVerifyNumber = false;
                this.registrationSuccess = true;
                this.titleClose = false;

                this.$nextTick(() => {
                    // 使用 document.querySelector 來選擇元素，而不是直接使用 jQuery
                    const checkBox = document.querySelector("#check-box");
                    if (checkBox) {
                        // 檢查元素是否存在，然後播放動畫
                        setInterval(() => {
                            checkBox.play();
                        }, 0);
                    }
                });

                // Start countdown for automatic redirection after 5 seconds
                const countdownInterval = setInterval(() => {
                    if (this.countdown > 0) {
                        this.countdown--;
                    } else {
                        clearInterval(countdownInterval);
                        // Redirect to login page
                        this.$router.push('/member-login/login');
                    }
                }, 1000);
            } else {
                this.alertR = "驗證碼錯誤，請重新輸入";
            }
        },

        togglePasswordVisibility() {
            // Toggle the visibility of the password
            this.passwordVisible = !this.passwordVisible;
        },
        playAnimation() {
            // 使用 $nextTick 以確保元素已經渲染到DOM中
            this.$nextTick(() => {
                // 使用 document.querySelector 來選擇元素，而不是直接使用 jQuery
                const checkBox = document.querySelector("#check-box");
                if (checkBox) {
                    // 檢查元素是否存在，然後播放動畫
                    setInterval(() => {
                        checkBox.play();
                    }, 0);
                }
            });
        },

    }
}
</script>
  

<style lang="scss"></style>
  