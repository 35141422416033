<template>
    <router-view></router-view>
    <section class="my-props">
        <h1>序號兌換</h1>
        <div class="flex_con">
            <div class="left-section">
                <h2>請輸入序號</h2>
                <input type="text" class="serial-number-input" placeholder="請輸入序號">
                <button class="submit-button">送出</button>
            </div>

            <div class="right-section">
                <h2>注意事項</h2>
                <h3>序號</h3>
                <ul>
                    <li>一組序號可兌換一張入場券。</li>
                    <li>兌換後會綁定目前的 BEATDAY 帳號，無法轉移或贈與至其他帳號。</li>
                    <li>請注意不同第三方帳號登入（例如：Facebook、Google…）視為不同帳號，兌換前請務必確認。</li>
                    <li>請於開演前一天安裝 BEATDAY，登入後至「節目選單」下載資源包 (依網速約 10 ~ 50 分鐘) 安裝與下載資源包流程請參考： <a
                            href="https://beatday.com/faq/">https://beatday.com/faq/</a>
                    </li>
                    <li>開演前30分鐘即可入場，若超過開演時間進場，可能會錯過演出。</li>
                </ul>

                <h3>道具序號</h3>
                <ul>
                    <li>使用道具序號可兌換虛擬道具。</li>
                    <li>兌換後會發送至目前 BEATDAY 帳號的背包，無法轉移。</li>
                    <li>欲使用虛擬道具，請登入 BEATDAY 程式後，到個人資料的「背包」裝備使用。</li>
                    <li>想獲得更多的虛擬道具，可以登入 BEATDAY 程式後，在大廳的商店購買。</li>
                </ul>
            </div>
        </div>
    </section>
</template>



<script>
export default {
    name: 'MyProps',
    data() {
        return {
        };
    },
    methods: {

    }
};
</script>

