// 路由配置
import { createRouter, createWebHashHistory } from 'vue-router';
//--------------------- Header ---------------------//
import Index from './pages/IndexPage.vue'; // 首頁
import BuyTickets from './pages/BuyTickets.vue'; // 購票中心


import DownloadsTutorials from './pages/Downloads_Tutorials.vue' //下載與教學
import DownloadPage from './pages/DownloadPage.vue' // 程式下載
import Instructions from './pages/Instructions.vue' // 使用教學
import FAQ from './pages/FAQ.vue' // 常見問題
import ReDownload from './pages/Redirecting/ReDownload.vue' // 程式下載（跳轉）
import ReFAQ from './pages/Redirecting/ReFAQ.vue' // 程式下載（跳轉）
import ReInstructions from './pages/Redirecting/ReInstructions.vue' // 使用教學（跳轉）
import Error from './pages/ErrorPage.vue' // Error

//--------------------- Footer ---------------------//
//*** Legal ***//
import Legal from './pages/Legal.vue'
import Privacy from './pages/Legal/Privacy.vue'
import UserPolicy from './pages/Legal/UserPolicy.vue'
import ReturnPolicyMizukiLin from './pages/Legal/ReturnPolicy_MizukiLin.vue' //林襄退換貨規則
import ReturnPolicy_VDay from './pages/Legal/ReturnPolicy_VDay.vue' //林襄退換貨規則

//--------------------- Login ---------------------//
import MemberLogin from './pages/LoginPage.vue'; // 登入&註冊
import Login from '@/pages/Login/Login.vue'; // 登入
import Register from '@/pages/Login/Register.vue'; // 註冊
import ForgotPassword from '@/pages/Login/ForgotPassword.vue'; // 忘記密碼


//--------------------- MemberCenter ---------------------//
import Member from './pages/MemberPage.vue'; // 會員中心

import TicketSection from '@/pages/MemberCenter/TicketSection.vue'; // 票券專區
import MyProps from '@/pages/MemberCenter/MyProps.vue'; // 我的道具
import SerialNumber from '@/pages/MemberCenter/SerialNumber.vue'; // 序號兌換主頁
import SerialNumberDefault from '@/pages/MemberCenter/SerialNumberDefault.vue'; // 序號兌換
import AccountBinding from '@/pages/AccountSetting/AccountBinding.vue'; //VR 序號票券綁定
import RegistrationBinding from '@/pages/AccountSetting/RegistrationBinding.vue'; //VR 序號票券創建
import MemberInformation from '@/pages/MemberCenter/MemberInformation.vue'; //會員資料
import ResetPassword from '@/pages/MemberCenter/ResetPassword.vue'; //重設密碼
//--------------------- Event ---------------------//
import Event from './pages/Event.vue'
import illusionary from './pages/Event/illusionary.vue' //鏡花水月
import VDay from './pages/Event/VDay.vue' //鏡花水月

// import LengTest from './pages/LengTest.vue'


const routes = [
    {
        path: '/',
        name: 'index',
        component: Index,
        meta: { title: 'BEATDAY' }
    },
    {
        path: '/buy-tickets',
        name: 'buy-tickets',
        component: BuyTickets,
        meta: { title: '購票中心 | BEATDAY' }
    },
    {
        path: '/buy-tickets/en',
        name: 'buy-ticketsEN',
        component: BuyTickets,
        meta: { title: 'Ticket Center | BEATDAY' }
    },
    {
        path: '/download',
        name: 're-download',
        component: ReDownload,
        meta: { title: '程式下載 | BEATDAY' }
    },
    {
        path: '/FAQ',
        name: 're-FAQ',
        component: ReFAQ,
        meta: { title: '常見問題 | BEATDAY' }
    },
    {
        path: '/instructions',
        name: 're-Instructions',
        component: ReInstructions,
        meta: { title: '使用教學 | BEATDAY' }
    },
    {
        path: '/downloads-tutorials',
        name: 'downloads-tutorials',
        component: DownloadsTutorials,
        redirect: '/downloads-tutorials/download',
        children: [
            {
                path: 'download',
                name: 'download',
                component: DownloadPage,
                meta: { title: '程式下載 | BEATDAY' }
            },
            {
                path: 'instructions',
                name: 'instructions',
                component: Instructions,
                meta: { title: '使用教學 | BEATDAY' }
            },
            {
                path: 'faq',
                name: 'faq',
                component: FAQ,
                meta: { title: '常見問題 | BEATDAY' }
            },
        ],
    },
    {
        path: '/legal',
        name: 'legal',
        component: Legal,
        children: [
            {
                path: 'privacy',
                name: 'privacy',
                component: Privacy,
                meta: { title: '隱私權政策 | BEATDAY' }
            },
            {
                path: 'user-policy',
                name: 'user-policy',
                component: UserPolicy,
                meta: { title: '使用條款 | BEATDAY' }
            },
        ],
    },
    {
        path: '/return-policy-MizukiLin',
        name: 'return-policy-MizukiLin',
        component: ReturnPolicyMizukiLin,
        meta: { title: '林襄沉浸式寫真 | BEATDAY' }
    },
    {
        path: '/return-policy-VDay',
        name: 'return-policy-VDay',
        component: ReturnPolicy_VDay,
        meta: { title: '2024漫博 VDay誕生日 | BEATDAY' }
    },
    {
        path: '/member-login',
        name: 'member-login',
        component: MemberLogin,
        redirect: '/member-login/login',
        children: [
            {
                path: 'login',
                name: 'login',
                component: Login,
                meta: { title: '會員登入 | BEATDAY' }
            },
            {
                path: 'register',
                name: 'register',
                component: Register,
                meta: { title: '會員註冊 | BEATDAY' }
            },
            {
                path: 'forgot-password',
                name: 'forgot-password',
                component: ForgotPassword,
                meta: { title: '忘記密碼 | BEATDAY' }
            },
        ],
    },
    {
        path: '/member',
        name: 'member',
        component: Member,
        redirect: '/member/ticket-section',
        children: [
            {
                path: 'ticket-section',
                name: 'ticket-section',
                component: TicketSection,
                meta: { title: '票券專區 | BEATDAY' }
            },
            {
                path: 'my-props',
                name: 'my-props',
                component: MyProps,
                meta: { title: '我的道具 | BEATDAY' }
            },
            {
                path: 'serial-number',
                name: 'serial-number',
                component: SerialNumber,
                children: [
                    {
                        path: '', // 空路径表示默认子路由
                        name: 'serial-number-default',
                        component: SerialNumberDefault,
                        meta: { title: '序號兌換 | BEATDAY' }
                    },
                    {
                        path: 'account-binding',
                        name: 'account-binding',
                        component: AccountBinding,
                        meta: { title: '綁定 | BEATDAY' }
                    },
                    {
                        path: 'registration-binding',
                        name: 'registration-binding',
                        component: RegistrationBinding,
                        meta: { title: '創建 | BEATDAY' }
                    },
                ],
            },
            {
                path: 'member-information',
                name: 'member-information',
                component: MemberInformation,
                meta: { title: '會員資料 | BEATDAY' }
            },
            {
                path: 'reset-password',
                name: 'reset-password',
                component: ResetPassword,
                meta: { title: '重設密碼 | BEATDAY' }
            }
        ],
    },
    {
        path: '/event',
        name: 'event',
        component: Event,
        children: [
            {
                path: 'illusionary',
                name: 'illusionary',
                component: illusionary,
                meta: { title: '《鏡花水月》升級版-最後的幻歌祭' }
            },
            {
                path: 'VDay',
                name: 'VDay',
                component: VDay,
                meta: { title: '2024漫博 VDay誕生日 | BEATDAY' }
            },
        ],
    },
    {
        path: '/error',
        name: 'error-404',
        component: Error,
        meta: { title: '404Error | BEATDAY' }
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

router.beforeEach((to, from, next) => {
    if (to.matched.length === 0) {
        next({ name: 'index' });
    } else {
        const pageTitle = to.meta && to.meta.title;
        if (pageTitle) {
            document.title = pageTitle;
        }
        next();
    }
});


export default router;

