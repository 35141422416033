
<template>
    <div class="">
        <div class="mask"></div>
        <div class="loading_box">
            <div class="loading_icon"></div>
            <div class="loading_text">Loading</div>
        </div>
    </div>
</template>



<script>
export default {
    name: 'LoadingIcon',
    data() {
        return {
        };
    },
    methods: {

    }
};
</script>