<template>
    <section class="serial-number">
        <h1>序號兌換</h1>
        <div class="flex_con" v-if="!serialSuccess">
            <div class="left-section">
                <h2>請輸入序號</h2>
                <input type="text" v-model="serialNumber" class="serial-number-input" placeholder="請輸入序號">
                <button class="submit-button" @click="verify()">送出</button>
                <div class="alert">{{ alert }}</div>
            </div>

            <div class="right-section">
                <h2>注意事項</h2>
                <h2 class="h2_Title">【PC / MAC版】演唱會序號</h2>
                <h3>票券序號</h3>
                <ul>
                    <li>一組序號可兌換一張入場券，兌換後會綁定目前的 BEATDAY 帳號，無法轉移或贈與至其他帳號</li>
                    <li>請注意不同第三方帳號登入 (EX:Facebook, Google…) 視為不同帳號，兌換前請務必確認</li>
                    <li>請於開演前一天安裝BEATDAY程式PC版或MAC版，登入後至「節目選單」下載資源包 (依網速約 10 ~ 50 分鐘)安裝與下載資源包流程請參考<router-link
                            to="/faq">常見問題</router-link></li>
                    <li>開演前30分鐘即可入場，若超過開演時間進場，可能會錯過演出</li>
                </ul>

                <h3>道具序號</h3>
                <ul>
                    <li>使用道具序號可兌換虛擬道具，兌換後會發送至目前 BEATDAY 帳號的背包，無法轉移</li>
                    <li>欲使用虛擬道具，請登入 BEATDAY 程式後，到個人資料的「背包」裝備使用</li>
                    <li>想獲得更多的虛擬道具，可以登入 BEATDAY 程式後，在大廳的商店購買</li>
                </ul>
                <h2 class="h2_Title">【Mobile / VR 版】沉浸式寫真序號</h2>
                <h3>開通序號</h3>
                <ul>
                    <li>取得序號後，請先在官網註冊或登入會員，然後在此頁面輸入序號開通權限</li>
                    <li>一組序號僅限開通一個帳號權限，兌換後會綁定目前的BEATDAY帳號，無法轉移或贈與至其他帳號</li>
                    <li>請注意不同第三方帳號登入 (EX:Facebook, Google…) 視為不同帳號，兌換前請務必確認</li>
                    <li>下載 APP：Mobile 版系列請至 App Store、Google Play 下載；VR 版用戶請至 VIVEPORT、VIVE Business 下載</li>
                    <li>下載後開啟 App，登入已開通之 BEATDAY 帳號即可支援 BEATDAY</li>
                </ul>
            </div>
        </div>
        <div v-if="serialSuccess" class="serial-success">
            <div class="flex">
                <h2 class="title">序號兌換 成功</h2>
                <dotlottie-player id="check-box"
                    src="https://lottie.host/d525671a-5c4b-4694-a264-2d8554f45e93/mSj3CZexHR.json" background="transparent"
                    speed="1" autoplay=""></dotlottie-player>
            </div>
            <div class="tickets-card">
                <div class="text">
                    <h2 class="title">{{ tickets.meta[0].ShowName }}</h2>
                </div>
                <img :src="tickets.meta[0].Pic" alt="">
            </div>
            <p></p>
            <button class="go-to" @click="reload">繼續&nbsp;<span>兌換序號</span></button>
            <router-link to="/member/ticket-section"><button class="go-to">前往&nbsp;<span>票券專區</span></button></router-link>
        </div>
    </section>
    <Loading v-show="loading"></Loading>
    <binding-popup v-if="showBindingPopup" @close-alert="hideAlert"></binding-popup>
</template>



<script>
import BindingPopup from '@/components/MemberCenter/BindingPopup.vue'
import { redeemTicket } from '@/api/apiService';
import Cookies from 'js-cookie';
import Loading from '@/components/Loading.vue';
export default {
    name: 'SerialNumber',
    mounted() {
        this.jwt = Cookies.get('jwt');
        if (!this.jwt) {
            this.$router.push('/member-login/login');
        }
        if (this.serialSuccess) {
            this.playAnimation();
        }
    },
    data() {
        return {
            serialNumber: '',
            alert: '',
            tickets: {},
            serialSuccess: false,
            showBindingPopup: false,
            jwt: '',
            bpAccountLogin: '',
            loading: false
        };
    },
    components: {
        'binding-popup': BindingPopup,
        Loading,
    },
    methods: {
        verify() {
            if (!this.serialNumber) {
                this.alert = '＊請輸入序號';
                return;
            } else {

                this.bpAccountLogin = Cookies.get('bpAccountLogin');
                if (this.bpAccountLogin === 'false' && this.serialNumber.length === 8 && this.serialNumber.startsWith('BO')) {
                    this.showBindingPopup = true;
                }
                else {
                    this.redeem();
                }
            }
        },
        async redeem() {
            const redeemInfo = {
                code: this.serialNumber,
                jwt: this.jwt
            }
            this.loading = true;
            const response = await redeemTicket(redeemInfo);
            this.loading = false;
            const responseData = response.data;
            if (responseData.StatusCode === 3) {
                this.alert = '';
                this.serialSuccess = true;
                this.tickets = responseData;
                this.playAnimation();
            } else {
                this.alert = '＊ 輸入錯誤，請確認序號。';
            }
        },
        showAlert() {
            this.showBindingPopup = true;
        },
        hideAlert() {
            this.showBindingPopup = false;
        },
        playAnimation() {
            // 使用 $nextTick 以確保元素已經渲染到DOM中
            this.$nextTick(() => {
                // 使用 document.querySelector 來選擇元素，而不是直接使用 jQuery
                const checkBox = document.querySelector("#check-box");
                if (checkBox) {
                    // 檢查元素是否存在，然後播放動畫
                    setInterval(() => {
                        checkBox.play();
                    }, 0);
                }
            });
        },
        reload() {
            console.log('aaaaa'); // 不能刪
            location.reload();
        },
    },
};
</script>

