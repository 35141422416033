import axios from './axios';


// Login Page
//https://mqa2c2h7m6.execute-api.ap-northeast-1.amazonaws.com/default/bpTest_account_signup
//9Gyya7GtGi3GJuuQdiDmK65jcPZnmwh7aCVo47Hm
//test server
const basicLogin = (data) => {
    // return axios.post('https://t36b71aof5.execute-api.ap-northeast-1.amazonaws.com/default/bp_test_custom_account', {
    return axios.post('https://so16dj2s89.execute-api.ap-northeast-1.amazonaws.com/default/lp_custom_account', {
        account: data.username,
        password: data.password
    });
};
const signUp = (data) => {
    const requestBody = {
        account: data.username,
        password: data.password,
        from: 'basic',
        language: 'zh-tw'
    };
    return axios.post('https://k6ed92rknf.execute-api.ap-northeast-1.amazonaws.com/default/bp_account_signup', requestBody, {
        headers: {
            'x-api-key': 'lnBsjg4YeUa6xvDA5sG8l5bcWsgMFib56ato2Sda',
            'Content-Type': 'application/json'
        }
    });
};
const changePsw = (data) => {
    const requestBody = {
        request: 'changePassword',
        account: data.username,
        jwt: data.jwt,
        password: data.password
    };
    return axios.put('https://k6ed92rknf.execute-api.ap-northeast-1.amazonaws.com/default/bp_account_signup', requestBody, {
        headers: {
            'x-api-key': 'lnBsjg4YeUa6xvDA5sG8l5bcWsgMFib56ato2Sda',
            'Content-Type': 'application/json'
        }
    });
};
const sendVerificationCode = (data) => {
    const requestBody = {
        request: 'sendVerifyCode',
        account: data.account,
        reason: data.reason
    };
    return axios.put('https://k6ed92rknf.execute-api.ap-northeast-1.amazonaws.com/default/bp_account_signup', requestBody, {
        headers: {
            'x-api-key': 'lnBsjg4YeUa6xvDA5sG8l5bcWsgMFib56ato2Sda',
            'Content-Type': 'application/json'
        }
    });
};
const verifyCode = (data) => {
    const requestBody = {
        request: 'verifyCode',
        account: data.account,
        code: data.code,
        reason: data.reason,
    };
    return axios.put('https://k6ed92rknf.execute-api.ap-northeast-1.amazonaws.com/default/bp_account_signup', requestBody, {
        headers: {
            'x-api-key': 'lnBsjg4YeUa6xvDA5sG8l5bcWsgMFib56ato2Sda',
            'Content-Type': 'application/json'
        }
    });
}

// Serial Number
const redeemTicket = (data) => {
    const baseURL = 'https://cm2cbwrw3f.execute-api.ap-northeast-1.amazonaws.com/default/lp_mzk_redeem';
    // const baseURL = 'https://rv3fng3csi.execute-api.ap-northeast-1.amazonaws.com/default/lpTest_mzk_redeem'
    return axios.get(`${baseURL}?code=${data.code}&jwt=${data.jwt}`);
};

// Ticket Section

const getTicketInfo = (data) => {
    const baseURL = 'https://7va36jlce2.execute-api.ap-northeast-1.amazonaws.com/default/lp_mzk_detailedInfo'
    // const baseURL = 'https://nhz0kq76k1.execute-api.ap-northeast-1.amazonaws.com/default/lp_test_mzk_detailedInfo'
    return axios.get(`${baseURL}?jwt=${data.jwt}`)
}
const getTicketState = (data) => {
    const baseURL = 'https://he1rz8qpu8.execute-api.ap-northeast-1.amazonaws.com/default/lp_mzk_ticket_state';
    // const baseURL = 'https://bp2wensis0.execute-api.ap-northeast-1.amazonaws.com/default/lp_test_mzk_ticket_state'
    return axios.get(`${baseURL}?uid=${data.uid}&timestamp=${data.timestamp}`)
}

// To get account's basic information
const getAccountInfo = (data) => {
    const baseURL = 'https://f1f6epoig2.execute-api.ap-northeast-1.amazonaws.com/default/lp_mzk_basicInfo';
    // const baseURL = 'https://gt947jbqk5.execute-api.ap-northeast-1.amazonaws.com/default/lp_test_mzk_basicInfo';
    return axios.get(`${baseURL}?jwt=${data.jwt}`);
};

const updateUserInfo = (data) => {
    const requestBody = {
        Nickname: data.Nickname,
        Email: data.Email,
        Gender: data.Gender,
        Birthday: data.Birthday,
        Area: data.Area
    };
    const baseURL = 'https://brwldb0ngf.execute-api.ap-northeast-1.amazonaws.com/default/lp_users';
    // const baseURL = 'https://p3j4lumcu0.execute-api.ap-northeast-1.amazonaws.com/default/lpTest_users';
    return axios.post(baseURL, requestBody, {
        headers: {
            'x-api-key': 'PTAGQTjGOw5AwJaSvQ21w3cEihdEW6qH912KMIAE',
            // 'x-api-key': 'VPlExyIUqk7I30AVmP1h18bIKW6FRsBs4NxL415D',
            'Content-Type': 'application/json'
        }
    });
};

const bindAccount = (data) => {
    const baseURL = 'https://1jgz02s2x1.execute-api.ap-northeast-1.amazonaws.com/default/lp_mzk_binding';
    // const baseURL = 'https://89wrqg4288.execute-api.ap-northeast-1.amazonaws.com/default/lpTest_mzk_binding';
    return axios.get(`${baseURL}?jwt=${data.jwt}&email=${data.email}`, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
};

export { getAccountInfo, basicLogin, signUp, changePsw, sendVerificationCode, verifyCode, redeemTicket, getTicketInfo, bindAccount, getTicketState, updateUserInfo };
