<template>
  <div ref="myModal" @click="checkClose($event)" id="myModal" class="modal announce">
    <div class="mask"></div>
    <div class="modal-content" @click.stop>
      <h1>重要通知</h1>
      <h3>Major Announcement</h3>
      <div class="driver"></div>
      <div class="content">
        <p>
          因應凱米颱風停班停課影響，配合台北漫畫博覽會主辦單位調動， BEATDAY 對此進行相應調整，並將資訊同步更新於官方社群，完整訊息請參閱社群平台最新公告。
        </p>
        <div class="FB">
          <a href="https://www.facebook.com/beatday.music">前往社群</a>
        </div>
        <p class="en_text">
          Due to the suspension of work and classes caused by Typhoon , and in
          coordination with the adjustments made by the organizers of the Taipei Comics
          Exhibition, BEATDAY will make corresponding adjustments. The information will be
          updated simultaneously on our official social media channels. For complete
          details, please refer to the latest announcement on our social media platforms.
        </p>
        <div class="FB">
          <a href="https://www.facebook.com/beatday.music">BEATDAY Facebook</a>
        </div>
      </div>
      <div class="close button_style_linear" @click="closeAlert">
        <h2 class="close_icon">關閉 &times;</h2>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AnnounceBox",
  data() {
    return {};
  },
  mounted() {
    document.body.style.overflow = "hidden";
  },
  methods: {
    checkClose(event) {
      // 獲取 modal-content 元素的參考
      const modalContent = this.$refs.myModal.querySelector(".modal-content");

      // 檢查點擊的元素是否是 modal-content
      if (!modalContent.contains(event.target)) {
        this.closeAlert();
      }
    },
    closeAlert() {
      document.body.style.overflow = "";
      this.$emit("close-alert");
    },
  },
};
</script>
