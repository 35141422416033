<template>
  <app-header></app-header>
  <div class="vday">
    <section class="section01">
      <img
        src="../../assets/img/Event/VDay/mian_visual_03.png"
        alt="main_mask"
        class="main_mask"
      />

      <div class="left">
        <img
          src="../../assets/img/Event/VDay/mian_visual_01.png"
          alt="main_character"
          class="main_character"
        />
        <img
          src="../../assets/img/Event/VDay/mian_visual_03.png"
          alt="main_mask"
          class="main_mask mobile"
        />
        <img
          src="../../assets/img/Event/VDay/mian_visual_02.png"
          alt="banner_logo"
          class="banner_logo"
        />
      </div>
      <div class="right"></div>
      <div class="banner_text_box">
        <img
          src="../../assets/img/Event/VDay/banner_text.png"
          alt="banner_text"
          class="banner_text"
        />
        <!-- <router-link to="/buy-tickets" target="_blank" class="Event_VDay_btn">
          <div class="btn_s Event_VDay_btn">立即購票</div>
        </router-link> -->
      </div>
    </section>
    <div class="big_BG">
      <section class="section02 section_box">
        <h1 class="title">鏡花水月單曲 VR 版</h1>
        <div class="video">
          <iframe
            src="https://www.youtube.com/embed/Xp3aICG9CSY?si=FlftGb6ssymlVD0V"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>
      </section>
      <section class="section_box section03">
        <h1 class="title">VDay 誕生日 VR 世界遇見 VTuber</h1>
        <h2 class="title_2ed">
          <span>買</span>
          鏡花水月單曲 VR 版
        </h2>
        <h2 class="title_2ed">
          <span>抽</span>
          露恰露恰 V2V 見面會
        </h2>
        <div class="W_BG">
          <h2 class="title_2ed blue_shadow">鏡花水月單曲 VR 版</h2>
          <div class="time">
            7/25(四) ～ 7/29(一) 上午場 11:00-14:00、下午場 14:00-17:00
            <br />
            ＊販售場次請見售票頁
          </div>
          <div class="place">台北世貿一館 HTC VIVE ORIGINALS</div>
          <div class="plan">
            <div class="plan_item blue_border">
              <div class="plan_title">
                限量單曲 VR 觀影票
                <div class="plan_content">‣《WWW》單曲 VR 版 (約4分鐘)</div>
              </div>

              <!-- <router-link to="/buy-tickets" target="_blank" class="Event_VDay_btn">
                <div class="btn_s Event_VDay_btn">立即購票</div>
              </router-link> -->
            </div>
            <div class="plan_item blue_border plan_item_02">
              <div class="left">
                <div class="plan_title">
                  限量歡慶誕生套票
                  <div class="plan_content">
                    ‣《WWW》單曲 VR 版（約4分鐘）
                    <br />
                    ‣ 獨家周邊「鏡花水月語音閃卡壓克力磚」乙款
                    <span>
                      （ 實際尺寸 70 x 50 mm / 厚度 10mm；商品價值新臺幣300元 ）
                    </span>
                  </div>
                </div>

                <!-- <router-link to="/buy-tickets" target="_blank" class="Event_VDay_btn">
                  <div class="btn_s Event_VDay_btn">立即購票</div>
                </router-link> -->
              </div>
              <div class="right">
                <img src="../../assets/img/Event/VDay/holo_acrylic_block.png" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div class="W_BG">
          <h2 class="title_2ed lutra">露恰露恰 V2V 見面會</h2>
          <div class="time">7/25(四) 上午 12:00-14:00 (實際時間將依獲獎通知為主)</div>
          <div class="place">台北世貿一館 HTC VIVE ORIGINALS</div>
          <div class="V2V_into">
            <div class="V2V_into_content">
              露恰露恰首次 VR 見面會獨家公開，透過 VR 與她最近距離 1 對 1
              即時互動，不僅可以享有私密對談聊天的機會，還能獲得前所未有的擊掌福利！
              <br />
              體驗時長：每人 2 分鐘
            </div>
            <div class="V2V_into_pic">
              <img src="../../assets/img/Event/VDay/lucia.png" alt="" />
            </div>
          </div>
          <div class="plan">
            <div class="plan_item blue_border plan_item_03">
              <div class="plan_title">參加辦法</div>
              <p class="plan_content">
                需於 7/8(一)23:59 以前，完成
                <!-- <router-link to="/buy-tickets" target="_blank" class="Event_VDay_btn"> -->
                「鏡花水月單曲 VR 版」購票
                <!-- </router-link> -->
                (【限量單曲VR觀影票】與【限量歡慶誕生套票】皆可)及成功兌換序號，並於購票頁勾選同意參加本活動抽獎之消費者，即可獲得參加「露恰露恰
                V2V 見面會抽獎資格」，詳細抽獎時間，請鎖定 BEATDAY
                官方社群公告，活動中獎者，需自行購買 2024 漫畫博覽會入場門票。
              </p>
            </div>
          </div>
        </div>
        <!-- <router-link to="/buy-tickets" target="_blank" class="Event_VDay_btn" id="Lutralutra_V2V">
          <div class="btn Event_VDay_btn">立即購票 抽限量 V2V 見面會</div>
        </router-link> -->
      </section>
      <section class="section04 section_box">
        <transition name="fade" appear>
          <div
            class="buyTicketLink"
            :class="{ buyFooterPosition: isFooterVisible }"
            v-show="buyTicketLink"
          >
            <!-- <router-link to="/buy-tickets" target="_blank" class="Event_VDay_btn">
              <img src="../../assets/img/Event/VDay/Btn_Ticket.png" alt="BuyTicket" class="buy_ticket Event_VDay_btn" />
            </router-link> -->
          </div>
        </transition>
        <h1 class="title">注意事項</h1>
        <div class="W_BG accordion-box">
          <div class="accordion-item" :class="{ active: activeAccordion === 0 }">
            <div class="accordion-heading" @click="toggleAccordion(0)">購票須知</div>
            <div class="accordion-content">
              <ul>
                <li>
                  本活動票券不包含「 2024
                  漫畫博覽會」(下稱「漫博會」)門票，漫博會門票請自行至漫博會官方平台進行購買。如非因可歸責於本公司的原因未能購買漫博會門票導致本活動票券因逾期無法使用者，本公司恕不辦理退票。
                </li>
                <li>
                  訂單成立後請於繳款期限內完成付款，逾期未付款完成，訂單將會自動取消，如須購買請再重新購票。
                </li>
                <li>
                  購買本活動票券後，消費者將獲得虛擬序號一組，應登入使用之 BEATDAY
                  帳號進行兌換，且每一個虛擬序號僅限兌換使用一次。虛擬序號兌換後即綁定指定的
                  BEATDAY
                  帳號。不論帳號是否屬於同一人，虛擬序號皆不可重複使用，也無法轉移或贈與至其他帳號。請注意，如以不同第三方帳號登入
                  (EX:Facebook 及 Google 等) BEATDAY
                  平台時，均視為不同帳號，消費者於購買及兌換本活動票券前請務必確認實際使用的帳號。
                </li>
                <li>
                  獨家周邊商品共有三種款式，每一款式數量有限，售完為止；消費者將於活動節目現場完成驗票後再領取購買的獨家周邊商品。獨家周邊商品均為限量商品，恕不接受更換商品服務。
                </li>
                <li>獨家周邊商品圖面為參考示意圖，依實際商品為主。</li>
              </ul>
            </div>
          </div>
          <div class="accordion-item" :class="{ active: activeAccordion === 1 }">
            <div class="accordion-heading" @click="toggleAccordion(1)" id="Check">
              報到辦法
            </div>
            <div class="accordion-content">
              <ul>
                <li>
                  本活動票券為「期間限定票券」，每張票券限單次入場，消費者應於 BEATDAY
                  官網購票時選擇指定場次及時段。活動節目分為上午及下午時段，消費者選擇上午時段者，最晚請於活動票券指定日期當日
                  13:30 以前持電子票券至 HTC 攤位排隊報到；選擇下午時段者，最晚請於 16:30
                  以前持電子票券至 HTC
                  攤位排隊報到。消費者超過報到時間導致未能使用本活動票券者，恕不辦理退款或更改其他場次時間。
                </li>
                <li>
                  本活動現場不接受任何代排行為，一經發現，本公司保留請代排人士離開隊伍的權利，敬請留意。
                </li>
              </ul>
              <div class="plan_item blue_border">
                <div class="checkIn">
                  <img
                    src="../../assets/img/Event/VDay/checkIn_01.png"
                    alt="checkIn_01"
                  />
                  <p>於 BEATDAY 官網購買「鏡花水月單曲 VR 版」票券</p>
                </div>
                <div class="checkIn_arrow">
                  <img
                    src="../../assets/img/Event/VDay/checkIn_arrow.png"
                    alt="checkIn_arrow"
                  />
                </div>
                <div class="checkIn">
                  <img
                    src="../../assets/img/Event/VDay/checkIn_02.png"
                    alt="checkIn_01"
                  />
                  <p>
                    登入 BEATDAY 官網，至會員中心兌換序號，打開票券專區活動票券 QR Code。
                  </p>
                </div>
                <div class="checkIn_arrow">
                  <img
                    src="../../assets/img/Event/VDay/checkIn_arrow.png"
                    alt="checkIn_arrow"
                  />
                </div>
                <div class="checkIn">
                  <img
                    src="../../assets/img/Event/VDay/checkIn_03.png"
                    alt="checkIn_01"
                  />
                  <p>
                    於 HTC 攤位現場排隊，依場次票券 QR
                    Code驗證入場。購買活動票券套票的消費者，領取套票的獨家周邊商品乙份。
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion-item" :class="{ active: activeAccordion === 2 }">
            <div class="accordion-heading" @click="toggleAccordion(2)">
              VR 體驗特別提醒
            </div>
            <div class="accordion-content">
              <ul>
                <li>全程禁止飲食。</li>
                <li>
                  活動節目所使用之 VR 設備並非針對兒童所設計，建議滿 13
                  歲以上民眾參與，如兒童欲參與專案節目活動體驗時，應有家長或法定代理人全程在場監督。
                </li>
                <li>觀賞前將進行 VR 穿戴前置教學與注意事項說明，約需 1-2 分鐘。</li>
                <li>
                  VR
                  設備應依工作人員指示配戴及調整，未經指示操作造成設備毀損，須照價賠償。
                </li>
                <li>
                  若為孕婦、心臟病、高血壓、癲癇、懼高症或重大疾病患者，不建議使用 VR
                  設備。
                </li>
                <li>
                  少數人在使用 VR
                  設備時，可能會產生頭暈、噁心等不適症狀，預約前請先自行評估身體狀況。
                </li>
                <li>觀賞期間若遇身體不適，請閉上眼睛並舉手主動告知服務人員前來協助。</li>
                <li>
                  因 VR
                  設備穿戴後，無法穿透看清外界狀況，遇緊急情況請務必聽從工作人員指示。
                </li>
                <li>貴重物品請隨身攜帶，本公司不負保管之責。</li>
                <li>VR 相關設備將於使用前及使用後皆落實清消，請放心使用。</li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
  <app-footer ref="footerRef"></app-footer>
</template>

<script>
import AppHeader from "@/components/header.vue";
import AppFooter from "@/components/footer.vue";

export default {
  name: "EmptyPage",
  components: {
    "app-header": AppHeader,
    "app-footer": AppFooter,
  },
  props: {},
  data() {
    return {
      buyTicketLink: false,
      activeAccordion: null,
      isFooterVisible: false,
      isMobile: window.innerWidth < 574,
    };
  },
  mounted() {
    this.scrollToHash();
    window.addEventListener("scroll", this.handleScroll);
    window.addEventListener("resize", this.reSize);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    reSize() {
      this.isMobile = window.innerWidth < 574;
    },
    handleScroll() {
      if (!this.isMobile) {
        const scrollPosition = window.scrollY || document.documentElement.scrollTop;
        if (scrollPosition >= 200) {
          this.buyTicketLink = true;
        } else {
          this.buyTicketLink = false;
        }

        const footer = this.$refs.footerRef.$el; // 获取 footer 的 DOM 元素
        const footerRect = footer.getBoundingClientRect(); // 获取 footer 的边界信息
        const viewportHeight = window.innerHeight; // 获取视窗高度

        // 计算视窗底部到 footer 上缘的距离
        const space = footerRect.top - viewportHeight;

        if (space <= 0) {
          this.isFooterVisible = true;
        } else {
          this.isFooterVisible = false;
        }
      } else {
        this.buyTicketLink = false;
      }
    },
    scrollToHash() {
      const hash = this.$route.hash;
      if (hash) {
        const element = document.querySelector(hash);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }
    },
    toggleAccordion(index) {
      if (this.activeAccordion === index) {
        // Slide up the active accordion content
        this.$nextTick(() => {
          $(".accordion-item").eq(index).find(".accordion-content").slideUp();
        });
        this.activeAccordion = null;
      } else {
        // Slide up any previously active accordion content
        this.$nextTick(() => {
          $(".accordion-item").find(".accordion-content").slideUp();
        });
        // Slide down the current accordion content and scroll to it
        this.$nextTick(() => {
          const $currentItem = $(".accordion-item").eq(index).find(".accordion-content");
          $currentItem.slideDown();
          $("html, body").animate(
            {
              scrollTop: $(".accordion-box").offset().top - 200, // 调整 -10 来确保标题露出来
            },
            500
          );
        });
        this.activeAccordion = index;
      }
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/styles/pages/Event/VDay.scss";
</style>
