<template>
  <div class="loginRegion">
    <h1 class="title">會員登入</h1>
    <form @submit.prevent="login">
      <div class="username">
        <i class="bi bi-person-fill"></i>
        <input v-model="username" type="text" id="username" name="username" placeholder="電子信箱">
      </div>

      <div class="password">
        <i class="bi bi-key-fill"></i>
        <input v-model="password" :type="passwordVisible ? 'text' : 'password'" id="password" name="password"
          placeholder="密碼">
        <div class="eye_btn" @click="togglePasswordVisibility"><i class="bi"
            :class="passwordVisible ? 'bi-eye-fill' : 'bi-eye'"></i></div>
      </div>

      <div class="privacy">
        <input type="checkbox" id="privacy" v-model="privacyChecked" />
        <label for="privacy">我已閱讀並同意<router-link to="/legal/user-policy">使用條款</router-link> 、 <router-link
            to="/legal/privacy">隱私權政策</router-link></label>
      </div>
      <div class="alert" ref="message">{{ alert }}</div>
      <button type="submit" class="login_btn">登入</button>

      <div class="link">
        <router-link to="/member-login/forgot-password">忘記密碼?</router-link>
        <router-link to="/member-login/register">註冊新帳號</router-link>
      </div>
    </form>

    <div class="other">
      <div class="box box_01"></div>
      <div class="box box_02"></div>
      <div class="box box_03"></div>
      <div class="box box_04"></div>
      <h3 class="p_Login">或使用其他方式登入</h3>

      <button @click="loginWithProvider('Facebook')" class="btn btn_bg_02">
        使用 Facebook 帳號登入
      </button>
      <button @click="loginWithProvider('Google')" class="btn">
        使用 Google 繼續
      </button>

      <button @click="loginWithProvider('Apple')" class="btn btn_bg_03">
        透過 Apple 繼續
      </button>
      <button @click="loginWithProvider('Discord')" class="btn btn_bg_04">
        使用 Discord 繼續
      </button>
    </div>
  </div>
  <Loading v-show="loading"></Loading>
</template>

<script>
import { basicLogin } from '@/api/apiService';
// import { getAccountInfo } from '@/api/apiService';
import Cookies from 'js-cookie';
import Loading from '@/components/Loading.vue';

export default {
  name: 'loginRegion',
  components: {
    Loading,
  },
  mounted() {
    var allCookies = Cookies.get();
    for (var cookie in allCookies) {
      Cookies.remove(cookie);
    }
    Cookies.set('bpAccountLogin', false, { expires: 7 });
  },
  data() {
    return {
      jwt: "",
      username: "",
      password: "",
      verification: "", // 驗證碼
      passwordVisible: false,
      privacyChecked: false,
      alert: "",
      loading: false,
    };
  },
  methods: {
    async login() {
      // 1. 檢查帳號密碼是否有資料
      if (!this.username || !this.password) {
        this.alert = "＊ 請輸入帳號和密碼";
        this.shakeMessage();
        return;
      }

      // 2. 檢查隱私條款是否勾選
      if (!this.privacyChecked) {
        this.alert = "＊ 請勾選同意使用條款、隱私權政策";
        this.shakeMessage();
        return;
      }

      // 3. 進行資料驗證，這裡可以添加你的資料驗證邏輯
      // 這只是一個示例，你需要根據你的實際需求進行修改
      // if (this.username !== "正確的帳號" || this.password !== "正確的密碼") {
      //     this.alert = "＊ 帳號或密碼輸入錯誤，請重新確認";
      //     return;
      // }

      // 如果一切正確，可以繼續處理登入
      this.alert = ""; // 清空警告
      try {
        const loginInput = {
          username: this.username,
          password: this.password
        }
        this.loading = true;
        const response = await basicLogin(loginInput);

        this.loading = false;
        const responseData = response.data;
        this.jwt = responseData.jwt;
        Cookies.set('bpAccountLogin', true, { expires: 7 });
        const redirectUrl = `https://beatday.com/#/member/ticket-section?JWT=${this.jwt}`;
        window.location.replace(redirectUrl);

      } catch (error) {
        this.loading = false;
        this.alert = "＊ 電子信箱或密碼錯誤，請重新輸入。"
        this.shakeMessage();
      }
      // 在這裡添加提交表單的相關邏輯，例如使用axios發送POST請求到伺服器
    },


    loginWithProvider(provider) {
      if (!this.privacyChecked) {
        this.alert = "＊ 請勾選同意使用條款、隱私權政策";
        this.shakeMessage();
        return;
      } else {
        this.alert = "";
        let routePath = "";
        if (provider === "Google") {
          // routePath = 'https://accounts.google.com/o/oauth2/v2/auth/identifier?response_type=code&scope=profile%20email%20openid&redirect_uri=https://cu10h3ja32.execute-api.ap-northeast-1.amazonaws.com/default/bp_test_google_login&client_id=1019060296509-mfsm3c2julbec3oem22sq78abp92i051.apps.googleusercontent.com&state=1&flowName=GeneralOAuthFlow';
          routePath = 'https://accounts.google.com/o/oauth2/v2/auth/identifier?response_type=code&scope=profile%20email%20openid&redirect_uri=https://api.viveoriginals.com/web/loginGoogle&client_id=1019060296509-mfsm3c2julbec3oem22sq78abp92i051.apps.googleusercontent.com&state=1&flowName=GeneralOAuthFlow';
        }
        else if (provider === 'Facebook') {
          // routePath = 'https://www.facebook.com/v10.0/dialog/oauth?client_id=869549880255728&redirect_uri=https://55cjczrdkl.execute-api.ap-northeast-1.amazonaws.com/default/bp_test_facebook_login&state=1&response_type=code&scope=email';
          routePath = 'https://www.facebook.com/v10.0/dialog/oauth?client_id=869549880255728&redirect_uri=https://api.viveoriginals.com/web/loginfb&state=1&response_type=code&scope=email';
        }
        else if (provider === 'Apple') {
          // routePath = 'https://appleid.apple.com/auth/authorize?client_id=com.viveoriginals.bp.service&redirect_uri=https://7gkzuj21d0.execute-api.ap-northeast-1.amazonaws.com/default/bp_test_apple_login&state=1&response_type=code&response_mode=query';
          routePath = 'https://appleid.apple.com/auth/authorize?client_id=com.viveoriginals.bp.service&redirect_uri=https://api.viveoriginals.com/web/loginApple&state=1&response_type=code&response_mode=query';
        }
        else if (provider === 'Discord') {
          // routePath = 'https://discord.com/api/oauth2/authorize?client_id=973888600699703307&redirect_uri=https://prn5t306l3.execute-api.ap-northeast-1.amazonaws.com/default/bp_test_discord_login&state=1&response_type=code&scope=identify%20email&prompt=consent';
          routePath = 'https://discord.com/api/oauth2/authorize?client_id=973888600699703307&redirect_uri=https://api.viveoriginals.com/web/loginDiscord&state=1&response_type=code&scope=identify%20email&prompt=consent';
        }
        window.location.replace(routePath);
      }
    },
    togglePasswordVisibility() {
      // Toggle the visibility of the password
      this.passwordVisible = !this.passwordVisible;
    },
    shakeMessage() {
      if (this.alert) {
        // 重置动画状态
        const message = this.$refs.message;

        // 重置动画状态
        message.style.animationName = "none";

        // 强制重绘以重新启动动画
        requestAnimationFrame(() => {
          message.style.animationName = "shakeOpacity";
        });
      }
    },
  },

}
</script>


<style lang="scss"></style>

