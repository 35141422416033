<template>
  <app-header></app-header>
  <section class="wrapper member-login">
    <router-view></router-view>
  </section>
  <app-footer></app-footer>
</template>

<script>
import AppHeader from "@/components/header.vue";
import AppFooter from "@/components/footer.vue";

export default {
  name: "MemberLogin",
  components: {
    "app-header": AppHeader,
    "app-footer": AppFooter,
  },
  props: {},
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss">
@import "../assets/styles/pages/Login.scss";
</style>
