
<template>
    <section class="account-binding account-setting">
        <h1>登入 BEATDAY 帳號</h1>
        <div class="filter-section">
            <router-link to=""><button class="filter-button active">綁定 BEATDAY</button></router-link>
            <router-link to="/member/serial-number/registration-binding"><button class="filter-button">創建
                    BEATDAY</button></router-link>
        </div>

        <form class="login_box" v-if="showLogin" @submit.prevent="login">
            <label class="">
                <span class="title">電子信箱</span>
                <input type="text" v-model="username" placeholder="請輸入電子信箱" name="username" />
            </label>

            <label class="">
                <span class="title">密碼</span>
                <div class="eye_fixed">
                    <input :type="passwordVisible ? 'text' : 'password'" v-model="password" placeholder="請輸入密碼"
                        name="passwordNew" />
                    <div class="eye_btn" @click="togglePasswordVisibility('new')"><i class="bi"
                            :class="passwordVisible ? 'bi-eye-fill' : 'bi-eye'"></i></div>
                </div>
            </label>

            <div class="alert">{{ alert }}</div>
            <button type="submit" class="submit-button">登入</button>

        </form>

        <div v-if="loginSuccess" class="loginSuccess">
            <div class="flex">
                <h2 class="title">綁定 BEATDAY 成功</h2>
                <dotlottie-player id="check-box"
                    src="https://lottie.host/d525671a-5c4b-4694-a264-2d8554f45e93/mSj3CZexHR.json" background="transparent"
                    speed="1" autoplay=""></dotlottie-player>
            </div>
            <p>將在 {{ countdownToHome }} 秒後自動跳轉至 <router-link to="/member/serial-number">序號兌換</router-link></p>
        </div>
    </section>
    <Loading v-show="loading"></Loading>
</template>



<script>
import { basicLogin, bindAccount } from '@/api/apiService';
import Loading from '@/components/Loading.vue';
import Cookies from 'js-cookie';

export default {
    name: 'AccountBinding',
    components: {
        Loading
    },
    data() {
        return {
            username: '',
            password: '',
            alert: '',
            passwordVisible: false,
            //
            showLogin: true,
            loginSuccess: false,
            countdownToHome: 5,
            jwt: '',
            loading: false

        };
    },
    methods: {
        async login() {
            this.jwt = Cookies.get('jwt');
            // 1. 檢查帳號密碼是否有資料
            if (!this.username || !this.password) {
                this.alert = "＊ 請輸入帳號和密碼";
                return;
            }
            else {
                try {
                    const loginInput = {
                        username: this.username,
                        password: this.password
                    }
                    const bindInput = {
                        jwt: this.jwt,
                        email: this.username
                    }
                    this.loading = true;
                    await basicLogin(loginInput);
                    await bindAccount(bindInput);
                    this.loading = false;
                    this.loginSuccess = true;
                    this.showLogin = false;
                    this.playAnimation();
                    Cookies.set('bpAccountLogin', true, { expires: 7 });

                    const countdownInterval = setInterval(() => {
                        if (this.countdownToHome > 0) {
                            this.countdownToHome--;
                        } else {
                            clearInterval(countdownInterval);
                            // Redirect to login page
                            this.$router.push('/member/serial-number');
                        }
                    }, 1000);

                } catch (error) {
                    this.loading = false;
                    this.alert = "電子信箱或密碼錯誤，請重新輸入。"
                }

            }


            // 3. 進行資料驗證，這裡可以添加你的資料驗證邏輯
            // 這只是一個示例，你需要根據你的實際需求進行修改
            // if (this.username !== "正確的帳號" || this.password !== "正確的密碼") {
            //     this.alert = "＊ 帳號或密碼輸入錯誤，請重新確認";
            //     return;
            // }

            // 如果一切正確，可以繼續處理登入
            // this.alert = ""; // 清空警告
            // try {
            //     const loginInput = {
            //         username: this.username,
            //         password: this.password
            //     }
            //     console.log("loginInput:", loginInput);
            //     const response = await basicLogin(loginInput);
            //     this.jwt = response.data.jwt;
            //     console.log("jwt:", this.jwt);
            //     const redirectUrl = `https://beefmandev.beatday.com/dist/index.html#/member/ticket-section?JWT=${this.jwt}`;
            //     window.location.replace(redirectUrl);
            // } catch (error) {
            //     this.alert = "電子信箱或密碼錯誤，請重新輸入。"
            // }
            // 在這裡添加提交表單的相關邏輯，例如使用axios發送POST請求到伺服器
        },
        togglePasswordVisibility() {
            // Toggle the visibility of the password
            this.passwordVisible = !this.passwordVisible;
        },
        playAnimation() {
            // 使用 $nextTick 以確保元素已經渲染到DOM中
            this.$nextTick(() => {
                // 使用 document.querySelector 來選擇元素，而不是直接使用 jQuery
                const checkBox = document.querySelector("#check-box");
                if (checkBox) {
                    // 檢查元素是否存在，然後播放動畫
                    setInterval(() => {
                        checkBox.play();
                    }, 0);
                }
            });
        },
    }
};
</script>

<style lang="scss"></style>
