<template>
  <div id="app">
    <!-- <app-header title="My App"></app-header> -->
    <router-view></router-view>
    <!-- <app-footer></app-footer> -->
    <announce-box v-show="displayAlert" @close-alert="hideAlert"></announce-box>
  </div>
</template>

<script>
import AnnounceBox from "@/components/Announce.vue";
// import AppHeader from '@/components/header.vue';
// import AppFooter from '@/components/footer.vue';

export default {
  name: "App",
  components: {
    //   'app-header': AppHeader,
    //   'app-footer': AppFooter,
    "announce-box": AnnounceBox,
  },
  data() {
    return {
      displayAlert: false,
    };
  },
  mounted() {
    if (!this.displayAlert) {
      document.body.style.overflow = "";
    }
  },
  methods: {
    hideAlert() {
      document.body.style.overflow = "";
      this.displayAlert = false;
    },
  },
};
</script>

<style>
/* 可以在這裡添加全局樣式 */
</style>
